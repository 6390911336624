import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Company } from '../../../types'

import Select from '../../../components/Select'

import { useAppState } from '../../../hooks/useAppState.hook'
import { useCompanies } from '../../../hooks/useCompanies.hook'
import { useDebounce } from '../../../utils/hooks/useDebounce.hook'

const SelectCompany = (): JSX.Element => {
  const [filter, setFilter] = useState('')
  const { t } = useTranslation()
  const debouncedFilter = useDebounce(filter, 400)
  const { data, loading } = useCompanies(debouncedFilter)
  const { company, setCompany } = useAppState()

  const companies = data?.companies?.edges.map((egde) => egde.node) || []

  const onSearch = (value: string) => {
    setFilter(value)
  }

  return (
    <Select
      title={t('shared_components_SelectCompany_title')}
      options={companies.map((company) => ({
        id: company.id,
        label: company.name,
        data: company,
      }))}
      selected={
        company && {
          id: company.id,
          label: company.name,
          data: company,
        }
      }
      filter={filter}
      onSearch={onSearch}
      onClick={(option) => {
        setCompany(option.data as Company)
      }}
      loading={loading}
    />
  )
}

export default SelectCompany
