import { gql } from '@apollo/client'

import { GroupStitchingPointFragment } from './group_stitching_point.fragment'

export const ZoneSecondaryNetworkFragment = gql`
  fragment ZoneSecondaryNetworkFragment on SecondaryNetwork {
    id
    name
    stitchingPoints {
      ...GroupStitchingPointFragment
    }
  }
  ${GroupStitchingPointFragment}
`
