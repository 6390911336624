import { gql } from '@apollo/client'
import { DeviceInstallationInputFragment } from '../fragments'

export const MUTATION_UNINSTALL_DEVICE = gql`
  mutation uninstallDevice($input: DeviceUninstallInput!) {
    uninstallDevice(input: $input) {
      ...DeviceInstallationInputFragment
    }
  }
  ${DeviceInstallationInputFragment}
`
