import { gql } from '@apollo/client'

import { EquipmentTensiometerFragment } from '../fragments'

export const QUERY_TENSIOMETER = gql`
  query tensiometer($id: ID!) {
    tensiometer(id: $id) {
      ...EquipmentTensiometerFragment
    }
  }
  ${EquipmentTensiometerFragment}
`
