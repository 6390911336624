import { useSelector } from 'react-redux'

import { AppState } from '../redux'
import { User } from '../types'

export const useUserState = (): {
  user?: User
  loading: 0 | 1 | -1
  updating: 0 | 1 | -1
} => {
  const user = useSelector((state: AppState) => state.user.user)
  const loading = useSelector((state: AppState) => state.user.loading)
  const updating = useSelector((state: AppState) => state.user.updating)

  return {
    user,
    loading,
    updating,
  }
}
