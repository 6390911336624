import { gql } from '@apollo/client'

export const EquipmentFilterMeasureFragment = gql`
  fragment EquipmentFilterMeasureFragment on FilterMeasure {
    timestamp
    pressureIn
    pressureOut
    pressureDrop
  }
`
