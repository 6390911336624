import { gql } from '@apollo/client'

import { AddressFragment } from './address.fragment'
import { UserFragment } from './user.fragment'

export const CompanyFragment = gql`
  fragment CompanyFragment on Company {
    id
    name
    description
    allowInstantIrrigation
    allowExperimentalPlanning
    isDemo
    contact {
      ...UserFragment
    }
    address {
      ...AddressFragment
    }
  }
  ${UserFragment}
  ${AddressFragment}
`
