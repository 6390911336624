import { gql } from '@apollo/client'
import { ZoneSecondaryNetworkFragment } from '../fragments'

export const MUTATION_CREATE_SECONDARY_NETWORK = gql`
  mutation createSecondaryNetwork($input: SecondaryNetworkCreateInput!) {
    createSecondaryNetwork(input: $input) {
      ...ZoneSecondaryNetworkFragment
    }
  }
  ${ZoneSecondaryNetworkFragment}
`

export const MUTATION_UPDATE_SECONDARY_NETWORK = gql`
  mutation updateSecondaryNetwork($input: SecondaryNetworkUpdateInput!) {
    updateSecondaryNetwork(input: $input) {
      ...ZoneSecondaryNetworkFragment
    }
  }
  ${ZoneSecondaryNetworkFragment}
`

export const MUTATION_DELETE_SECONDARY_NETWORK = gql`
  mutation deleteSecondaryNetwork($id: ID!) {
    deleteSecondaryNetwork(id: $id)
  }
`
