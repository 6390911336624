import React from 'react'

import { Container, IconContainer, TitleContainer } from './Item.styled'

import { SvgIconProps } from '@material-ui/core/SvgIcon'

interface Props {
  Icon?: React.ComponentType<SvgIconProps>
  title: string
  selected: boolean
  onClick: () => void
  hideOnMobile?: boolean
  subItem?: boolean
}

class Item extends React.Component<Props> {
  public state = {
    hover: false,
  }

  public toggleHover = (): void => {
    const { hover } = this.state
    this.setState({ hover: !hover })
  }

  public render(): React.ReactNode {
    const { Icon, onClick, selected, hideOnMobile, title, subItem } = this.props
    const { hover } = this.state

    return (
      <Container
        selected={selected}
        hover={hover}
        onClick={onClick}
        onMouseEnter={this.toggleHover}
        onMouseLeave={this.toggleHover}
        hideOnMobile={hideOnMobile}
        subItem={subItem}
      >
        <IconContainer>{Icon && <Icon style={{ fontSize: 30 }} />}</IconContainer>
        <TitleContainer>{title}</TitleContainer>
      </Container>
    )
  }
}

export default Item
