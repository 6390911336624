import { gql } from '@apollo/client'

import { EquipmentManometerMeasureFragment } from './equipment_manometer_measure.fragment'
import { PageInfoFragment } from './page_info.fragment'
import { EquipmentManometerIrrigationFragment } from './equipment_manometer_irrigation.fragment'
import { EquipmentManometerAlertFragment } from './equipment_manometer_alert.fragment'

export const EquipmentManometerFragment = gql`
  fragment EquipmentManometerFragment on Manometer {
    id
    name
    unit
    thresholdMin
    thresholdMax
    thresholdBased
    physicalManometer {
      id
      installDate
      brand
      model
    }
    lastMeasure {
      ...EquipmentManometerMeasureFragment
    }
    valve {
      name
      sector {
        name
        color
      }
    }
    irrigations(pagination: { first: 20 }) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...EquipmentManometerIrrigationFragment
        }
      }
    }
    alerts(pagination: { first: 20 }) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...EquipmentManometerAlertFragment
        }
      }
    }
  }
  ${EquipmentManometerMeasureFragment}
  ${PageInfoFragment}
  ${EquipmentManometerIrrigationFragment}
  ${EquipmentManometerAlertFragment}
`

export const EquipmentManometerWithExtensiveIrrigationHistoryFragment = gql`
  fragment EquipmentManometerFragment on Manometer {
    id
    name
    unit
    thresholdMin
    thresholdMax
    thresholdBased
    physicalManometer {
      id
      installDate
      brand
      model
    }
    lastMeasure {
      ...EquipmentManometerMeasureFragment
    }
    valve {
      name
      sector {
        name
        color
      }
    }
    irrigations(pagination: { first: 200 }) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...EquipmentManometerIrrigationFragment
        }
      }
    }
    alerts(pagination: { first: 200 }) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...EquipmentManometerAlertFragment
        }
      }
    }
  }
  ${EquipmentManometerMeasureFragment}
  ${PageInfoFragment}
  ${EquipmentManometerIrrigationFragment}
  ${EquipmentManometerAlertFragment}
`
