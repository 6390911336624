import { gql } from '@apollo/client'
import { GroupValveFragment } from '../fragments'

export const MUTATION_CREATE_VALVE = gql`
  mutation createValve($input: ValveCreateInput!) {
    createValve(input: $input) {
      ...GroupValveFragment
    }
  }
  ${GroupValveFragment}
`

export const MUTATION_UPDATE_VALVE = gql`
  mutation updateValve($input: ValveUpdateInput!) {
    updateValve(input: $input) {
      ...GroupValveFragment
    }
  }
  ${GroupValveFragment}
`

export const MUTATION_DELETE_VALVE = gql`
  mutation deleteValve($id: ID!) {
    deleteValve(id: $id)
  }
`
