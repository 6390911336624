import { gql } from '@apollo/client'

import { DeviceInstallationInputFragment } from '../fragments'

export const DEVICE_INPUT_INSTALLATIONS = gql`
  query deviceInputInstallations($devEui: ID!) {
    deviceInputInstallations(devEui: $devEui) {
      ...DeviceInstallationInputFragment
    }
  }
  ${DeviceInstallationInputFragment}
`
