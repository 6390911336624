import styled from 'styled-components'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'

export const Container = styled.div<{ disabled?: boolean; big?: boolean; bordered?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  position: relative;
  border-radius: 4px;
  padding: 0px 10px;
  min-width: 300px;
  box-sizing: border-box;
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ theme }) => theme.colors.main};
  background-color: white;
  min-height: 30px;

  box-shadow: 0px 1px 3px #00000029;
  border: 1px solid ${({ theme, bordered }) => (bordered ? theme.colors.main : 'none')};
`

export const TitleSelect = styled.div<{ big?: boolean }>`
  font-size: ${({ big }) => (big ? 26 : 18)}px;
  padding: 2px 0;
  padding-right: 5px;
  user-select: none;
  flex: 1;
`

export const InputSelect = styled.input<{ big?: boolean }>`
  font-size: ${({ big }) => (big ? 26 : 18)}px;
  padding: 2px 0;
  padding-right: 5px;
  user-select: none;
  flex: 1;
  outline: none;
  border: none;
  color: ${({ theme }) => theme.colors.main};
`

export const ContainerSelect = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  min-width: 300px;
  z-index: 1;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  border-radius: 4px;
  margin-top: 5px;

  @media (max-width: ${({ theme }) => theme.responsive.mobile.valueString}) {
    top: 30px;
  }
`

export const Item = styled.div<{ disabled?: boolean }>`
  margin: 10px 0;
  padding: 5px 10px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  &:hover {
    background-color: ${({ disabled }) => (disabled ? 'default' : 'lightgray')};
  }
`

export const ArrowDown = styled(KeyboardArrowDown)`
  color: ${({ theme }) => theme.colors.main};
`

export const LoaderContainer = styled.div`
  width: 20px;
  height: 20px;
`

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const ColorBox = styled.div<{ color?: string }>`
  height: 20px;
  width: 20px;
  margin: 10px;
  border-radius: 3px;
  background-color: ${({ color }) => color};
`
