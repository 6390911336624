import { gql } from '@apollo/client'

export const LabelFragment = gql`
  fragment LabelFragment on Label {
    id
    name
    createdDate
    updatedDate
    deletedDate
    companyId
    company {
      id
      name
      allowExperimentalPlanning
    }
    type
    parentLabelId
  }
`
