import { SectorColor } from '../types/sector_color'

/*
  /!\ WARNING /!\ : Color id's are stored in backend. You can change colors, but not change or remove ids.
 */

export const sectorColors: SectorColor[] = [
  { id: '1', color: '#FF6200' },
  { id: '2', color: '#FFA700' },
  { id: '3', color: '#FFD700' },
  { id: '4', color: '#CCFF00' },
  { id: '5', color: '#00FFBB' },
  { id: '6', color: '#00E0FF' },
  { id: '7', color: '#000AFF' },
  { id: '8', color: '#8F00FF' },
  { id: '9', color: '#FA00FF' },
  { id: '10', color: '#FF007A' },
]

export const getSectorColorById = (id: string | number): string => {
  const myColor = sectorColors.find((color) => color.id == id)
  if (!!myColor) return myColor.color

  return sectorColors[0].color
}
