import { gql } from '@apollo/client'

import { IrrigationTemplateFragment } from '../fragments/irrigation_template.fragment'

export const MUTATION_CREATE_IRRIGATION_TEMPLATE = gql`
  mutation createIrrigationTemplate($input: IrrigationTemplateCreateInput!) {
    createIrrigationTemplate(input: $input) {
      ...IrrigationTemplateFragment
    }
  }
  ${IrrigationTemplateFragment}
`
export const MUTATION_UPDATE_IRRIGATION_TEMPLATE = gql`
  mutation updateIrrigationTemplate($input: IrrigationTemplateUpdateInput!) {
    updateIrrigationTemplate(input: $input) {
      ...IrrigationTemplateFragment
    }
  }
  ${IrrigationTemplateFragment}
`

export const MUTATION_START_IRRIGATION_TEMPLATE = gql`
  mutation startIrrigationTemplate($input: IrrigationTemplateStartInput!) {
    startIrrigationTemplate(input: $input) {
      ...IrrigationTemplateFragment
    }
  }
  ${IrrigationTemplateFragment}
`

export const MUTATION_STOP_IRRIGATION_TEMPLATE = gql`
  mutation stopIrrigationTemplate($input: IrrigationTemplateStopInput!) {
    stopIrrigationTemplate(input: $input) {
      ...IrrigationTemplateFragment
    }
  }
  ${IrrigationTemplateFragment}
`

export const MUTATION_DELETE_IRRIGATION_TEMPLATE = gql`
  mutation deleteIrrigationTemplate($id: ID!) {
    deleteIrrigationTemplate(id: $id)
  }
`
