import React, { ErrorInfo, ReactNode } from 'react'
import { Button } from '../Button'

interface Props {
  children?: ReactNode
}

interface State {
  error?: Error
  errorInfo?: ErrorInfo
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({
      error,
      errorInfo,
    })

    // TODO send error to error reporting service
  }

  refreshPage(): void {
    location.reload()
  }

  render(): React.ReactNode {
    const { children } = this.props
    const { error } = this.state

    return error ? (
      <>
        <div>{error.toString()}</div>
        <br />
        <Button label="Refresh page" onClick={this.refreshPage}></Button>
      </>
    ) : (
      children
    )
  }
}

export default ErrorBoundary
