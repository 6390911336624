import { gql } from '@apollo/client'

import { CountryFragment } from './country.fragment'

export const AddressFragment = gql`
  fragment AddressFragment on Address {
    id
    street
    city
    zipCode
    country {
      ...CountryFragment
    }
  }
  ${CountryFragment}
`
