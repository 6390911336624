import { gql } from '@apollo/client'

import { DeviceInputFragment } from './device_input.fragment'

export const DeviceInstallationInputFragment = gql`
  fragment DeviceInstallationInputFragment on DeviceInstallationInput {
    deviceInput {
      ...DeviceInputFragment
    }
    equipment {
      manometer {
        object {
          id
          name
        }
        installation {
          installDate
          maxPressure
        }
      }
      tensiometer {
        object {
          id
          name
        }
        installation {
          installDate
          maxPressure
        }
      }
      filter {
        object {
          id
          name
        }
        installation {
          installDate
          filterEntry
          maxPressure
        }
      }
      waterMeter {
        object {
          id
          name
        }
        installation {
          installDate
          pulsePerLiter
          defaultTotalLiter
        }
      }
      capacitiveProbe {
        object {
          id
          name
        }
        installation {
          installDate
        }
      }
    }
    group {
      pump {
        object {
          id
          name
        }
        installation {
          installDate
        }
      }
      valve {
        object {
          id
          name
        }
        installation {
          installDate
        }
      }
      waterInlet {
        object {
          id
          name
        }
        installation {
          installDate
        }
      }
    }
  }
  ${DeviceInputFragment}
`
