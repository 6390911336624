import {
  USER_LOGIN_LOADED_ERROR,
  USER_LOGIN_LOADING,
  USER_SIGN_OUT,
  USER_DETAIL_LOADED_SUCCESS,
  USER_DETAIL_LOADED_ERROR,
  USER_DETAIL_UPDATING,
  USER_DETAIL_UPDATED_SUCCESS,
  USER_DETAIL_UPDATED_ERROR,
  UserActionTypes,
  USER_SIGNUP_LOADING,
  USER_SIGNUP_LOADED_ERROR,
} from './actionTypes'

import { User } from '../../types'

export interface UserState {
  loading: 0 | 1 | -1
  updating: 0 | 1 | -1
  user?: User
}

const defaultState: UserState = {
  loading: 1,
  updating: 0,
  user: undefined,
}

const user = (state: UserState = defaultState, action: UserActionTypes): UserState => {
  switch (action.type) {
    case USER_LOGIN_LOADING:
      return { ...state, loading: 1 }
    case USER_LOGIN_LOADED_ERROR:
      return { ...state, loading: -1 }
    case USER_SIGNUP_LOADING:
      return { ...state, loading: 1 }
    case USER_SIGNUP_LOADED_ERROR:
      return { ...state, loading: -1 }
    case USER_SIGN_OUT:
      return { updating: 0, loading: 0, user: undefined }
    case USER_DETAIL_LOADED_SUCCESS:
      return { ...state, loading: 0, user: action.user }
    case USER_DETAIL_LOADED_ERROR:
      return { ...state, loading: -1 }
    case USER_DETAIL_UPDATING:
      return { ...state, updating: 1 }
    case USER_DETAIL_UPDATED_SUCCESS:
      return { ...state, updating: 0, user: action.user }
    case USER_DETAIL_UPDATED_ERROR:
      return { ...state, updating: -1 }
    default:
      return state
  }
}

export default user
