import { gql } from '@apollo/client'

export const IrrigationSystemFragment = gql`
  fragment IrrigationSystemFragment on IrrigationSystem {
    id
    name
    land {
      id
      name
    }
    company {
      id
      name
      allowExperimentalPlanning
    }
  }
`
