import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'

import Cancel from '@material-ui/icons/Cancel'

import { Container, ContainerActions, ContainerCancel, Message } from './Toast.styled'

import { withAppState, WithAppStateProps } from './Toast.container'

export type ToastType = 'info' | 'success' | 'warning'

export interface ToastProps {
  identifier?: string
  type?: ToastType
  fixed?: boolean
  message: string
  description?: string
  Component?: React.ComponentType<any>
}

interface State {
  events: ToastProps[]
  currentEvent?: ToastProps
  top: string
}

type Props = WithAppStateProps & WithTranslation

class Toast extends React.Component<Props, State> {
  public state: State = {
    events: [] as ToastProps[],
    currentEvent: undefined,
    top: '-82px',
  }

  public hideToastTimeout: any
  public clearEventTimeout: any

  public componentDidMount() {
    document.addEventListener('toast', this.addToast)
  }

  public componentWillUnmount() {
    document.removeEventListener('toast', this.addToast)
  }

  public addToast = (e: Event) => {
    const event = e as CustomEvent
    const { detail }: { detail: ToastProps } = event
    const { events, currentEvent } = this.state

    if (detail.identifier && events.find((e) => e.identifier === detail.identifier)) {
      this.setState({ events: events.map((e) => (e.identifier === detail.identifier ? detail : e)) }, this.displayToast)
    } else if (detail.identifier && currentEvent && currentEvent.identifier === detail.identifier) {
      this.setState({ currentEvent: detail }, () => {
        if (!detail.fixed) this.hideToast(5000)
      })
    } else {
      this.setState({ events: [...events, detail] }, this.displayToast)
    }
  }

  public displayToast = () => {
    const { currentEvent, events } = this.state
    if (!currentEvent && events.length !== 0) {
      const currentEvent = events[0]
      this.setState({ currentEvent, top: '10px', events: events.slice(1) }, () => {
        if (!currentEvent.fixed) this.hideToast(5000)
      })
    }
  }

  public hideToast = (timeout: number) => {
    clearTimeout(this.hideToastTimeout)
    this.hideToastTimeout = setTimeout(() => {
      this.setState({ top: '-82px' }, () => {
        this.clearEvent()
      })
    }, timeout)
  }

  public clearEvent = () => {
    clearTimeout(this.clearEventTimeout)
    this.clearEventTimeout = setTimeout(() => {
      this.setState({ currentEvent: undefined }, this.displayToast)
    }, 300)
  }

  public render(): React.ReactNode {
    const { menuOpen, t } = this.props
    const { top, currentEvent } = this.state
    const event = currentEvent ? currentEvent : undefined

    return (
      <Container open={menuOpen} style={{ top }} type={event && event.type}>
        {event && (
          <Message type={event.type}>
            {t(event.message)}
            {event.description && <div>{event.description}</div>}
          </Message>
        )}
        <ContainerActions>
          {event && event.Component && <event.Component />}
          <ContainerCancel>
            <Cancel onClick={() => this.hideToast(0)} />
          </ContainerCancel>
        </ContainerActions>
      </Container>
    )
  }
}

export default withTranslation()(withAppState(Toast))
