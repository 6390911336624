import { gql } from '@apollo/client'

import { ZoneSecondaryNetworkFragment, ZoneSectorFragment, ZoneWaterSupplyFragment } from '../fragments'

export const DASHBOARD = gql`
  query dashboard($id: ID!) {
    irrigationSystem(id: $id) {
      id
      name
      secondaryNetworks {
        ...ZoneSecondaryNetworkFragment
      }
      sectors {
        ...ZoneSectorFragment
      }
      waterSupplies {
        ...ZoneWaterSupplyFragment
      }
    }
  }
  ${ZoneSecondaryNetworkFragment}
  ${ZoneSectorFragment}
  ${ZoneWaterSupplyFragment}
`
