export const CALENDAR_SET_DATE = '@@calendar/SET_DATE'

export interface SetDate {
  type: typeof CALENDAR_SET_DATE
  date: Date
}

export const CALENDAR_SET_VIEW_MODE = '@@calendar/SET_VIEW_MODE'

export type ViewMode = 'month' | 'day' | 'week'

export interface SetViewMode {
  type: typeof CALENDAR_SET_VIEW_MODE
  mode: ViewMode
}

export type CalendarActionTypes = SetDate | SetViewMode
