import { gql } from '@apollo/client'

import { EquipmentWaterMeterMeasureFragment } from './equipment_water_meter_measure.fragment'

export const EquipmentWaterMeterFragment = gql`
  fragment EquipmentWaterMeterFragment on WaterMeter {
    id
    name
    unit
    physicalWaterMeter {
      id
      installDate
      brand
      model
      pulsePerLiter
      defaultTotalLiter
      pulseOutput
      connected
    }
    lastMeasure {
      ...EquipmentWaterMeterMeasureFragment
    }
  }
  ${EquipmentWaterMeterMeasureFragment}
`
