import { gql } from '@apollo/client'

export const MEASUREMENT = gql`
  query measurement($irrigationSystemId: ID!) {
    irrigationSystem(id: $irrigationSystemId) {
      secondaryNetworks {
        id
        name
      }
      sectors {
        id
        name
      }
      waterSupplies {
        id
        name
      }
    }
  }
`

export const MEASUREMENTS_FOR_WATER_SUPPLY = gql`
  query measurementsForWaterSupply($waterSupplyId: ID!, $startDate: DateTime!, $endDate: DateTime!) {
    waterSupply(id: $waterSupplyId) {
      name
      pumps {
        id
        name
        manometers {
          id
          name
          measures(startDate: $startDate, endDate: $endDate) {
            timestamp
            value
          }
        }
        tensiometers {
          id
          name
          measures(startDate: $startDate, endDate: $endDate) {
            timestamp
            value
          }
        }
        filters {
          id
          name
          measures(startDate: $startDate, endDate: $endDate) {
            timestamp
            pressureIn
            pressureOut
            pressureDrop
          }
        }
        waterMeters {
          id
          name
          volumeMeasures(startDate: $startDate, endDate: $endDate) {
            timestamp
            totalLiter
          }
          flowMeasures(startDate: $startDate, endDate: $endDate) {
            timestamp
            flow
          }
        }
        capacitiveProbes {
          id
          name
          rZone
          humidityUnit
          thresholds {
            depth
            thresholdMin
            thresholdMax
          }
          physicalCapacitiveProbe {
            id
            depths
          }
          measures(startDate: $startDate, endDate: $endDate) {
            timestamp
            values {
              depth
              humidity
              temperature
            }
          }
        }
      }
      waterInlets {
        id
        name
        manometers {
          id
          name
          measures(startDate: $startDate, endDate: $endDate) {
            timestamp
            value
          }
        }
        tensiometers {
          id
          name
          measures(startDate: $startDate, endDate: $endDate) {
            timestamp
            value
          }
        }
        filters {
          id
          name
          measures(startDate: $startDate, endDate: $endDate) {
            timestamp
            pressureIn
            pressureOut
            pressureDrop
          }
        }
        waterMeters {
          id
          name
          volumeMeasures(startDate: $startDate, endDate: $endDate) {
            timestamp
            totalLiter
          }
          flowMeasures(startDate: $startDate, endDate: $endDate) {
            timestamp
            flow
          }
        }
        capacitiveProbes {
          id
          name
          rZone
          humidityUnit
          thresholds {
            depth
            thresholdMin
            thresholdMax
          }
          physicalCapacitiveProbe {
            id
            depths
          }
          measures(startDate: $startDate, endDate: $endDate) {
            timestamp
            values {
              depth
              humidity
              temperature
            }
          }
        }
      }
    }
  }
`

export const MEASUREMENTS_FOR_SECTOR = gql`
  query measurementsForSector($sectorId: ID!, $startDate: DateTime!, $endDate: DateTime!) {
    sector(id: $sectorId) {
      name
      valves {
        id
        name
        manometers {
          id
          name
          measures(startDate: $startDate, endDate: $endDate) {
            timestamp
            value
          }
        }
        tensiometers {
          id
          name
          measures(startDate: $startDate, endDate: $endDate) {
            timestamp
            value
          }
        }
        filters {
          id
          name
          measures(startDate: $startDate, endDate: $endDate) {
            timestamp
            pressureIn
            pressureOut
            pressureDrop
          }
        }
        waterMeters {
          id
          name
          volumeMeasures(startDate: $startDate, endDate: $endDate) {
            timestamp
            totalLiter
          }
          flowMeasures(startDate: $startDate, endDate: $endDate) {
            timestamp
            flow
          }
        }
        capacitiveProbes {
          id
          name
          rZone
          humidityUnit
          thresholds {
            depth
            thresholdMin
            thresholdMax
          }
          physicalCapacitiveProbe {
            id
            depths
          }
          measures(startDate: $startDate, endDate: $endDate) {
            timestamp
            values {
              depth
              humidity
              temperature
            }
          }
        }
      }
    }
  }
`

export const MEASUREMENTS_FOR_SECTORS = gql`
  query measurementsForSectors($startDate: DateTime!, $endDate: DateTime!, $groupByTimeForRange: Boolean!) {
    irrigationSystems {
      id
      sectors {
        name
        valves {
          id
          name
          manometers {
            id
            name
            measures(startDate: $startDate, endDate: $endDate, groupByTimeForRange: $groupByTimeForRange) {
              timestamp
              value
            }
          }
        }
      }
    }
  }
`
