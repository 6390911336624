import {
  APP_TOGGLE_MENU,
  APP_SET_COMPANY,
  AppActionTypes,
  APP_SET_LAND,
  APP_SET_IRRIGATION_SYSTEM,
  APP_SET_LAND_AND_IRRIGATION_SYSTEM,
  APP_TOGGLE_USER_PROFILE,
} from './actionTypes'
import { USER_SIGN_OUT } from '../user/actionTypes'
import { Company, Land, IrrigationSystem } from '../../types'

import { getCompanyFromLocalStorage, getLandFromLocalStorage, getIrrigationSystemFromLocalStorage } from './helpers'

export interface AppState {
  openMenu: boolean
  openUserProfile: boolean
  company?: Company
  land?: Land
  irrigationSystem?: IrrigationSystem
}

const defaultState: AppState = {
  openMenu: window.innerWidth <= 1100 ? false : true,
  openUserProfile: false,
  company: getCompanyFromLocalStorage(),
  land: getLandFromLocalStorage(),
  irrigationSystem: getIrrigationSystemFromLocalStorage(),
}

const app = (state: AppState = defaultState, action: AppActionTypes): AppState => {
  switch (action.type) {
    case APP_TOGGLE_MENU:
      return { ...state, openMenu: !state.openMenu }
    case APP_TOGGLE_USER_PROFILE:
      return { ...state, openUserProfile: !state.openUserProfile }
    case APP_SET_COMPANY:
      return { ...state, company: action.company, land: undefined, irrigationSystem: undefined }
    case APP_SET_LAND:
      return { ...state, land: action.land, irrigationSystem: undefined }
    case APP_SET_IRRIGATION_SYSTEM:
      return { ...state, irrigationSystem: action.irrigationSystem }
    case APP_SET_LAND_AND_IRRIGATION_SYSTEM:
      return { ...state, land: action.land, irrigationSystem: action.irrigationSystem }
    case USER_SIGN_OUT:
      return defaultState
    default:
      return state
  }
}

export default app
