import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 10px;
  margin: 15px 0;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;
  height: 58px;
  border-radius: 24px;
  box-sizing: border-box;
`

export const Input = styled.input<{ icon?: boolean; disabled?: boolean }>`
  font-family: inherit;
  font-size: 17px;
  background-color: #f7f7f7;
  text-decoration: none;
  border: none;
  outline: none;
  color: black;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};

  width: ${({ icon }) => (icon ? 'calc(100% - 42px)' : '100%')};

  ::placeholder {
    color: black;
    opacity: 0.3;
    font-weight: 100;
  }
`

export const IconContainer = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  background-color: white;
`

export const Icon = styled.img`
  width: 18px;
  height: 18px;
  object-fit: contain;
`
