import moment from 'moment'

export const toUserLocaleDate = (date: moment.MomentInput): moment.Moment => moment.utc(date).local()

export const toUTCDate = (date: moment.MomentInput): moment.Moment => moment(date).utc()

export const formatTimeScale = (x: moment.Moment | Date): string => {
  const date = moment(x).toDate()
  let format = 'HH:mm'
  if (date.getHours() == 0 && date.getMinutes() == 0) {
    if (date.getFullYear() != new Date().getFullYear()) {
      format = 'ddd D MMM YY'
    } else {
      format = 'ddd D MMM'
    }
  }
  return toUserLocaleDate(date).format(format)
}
