import { ApolloClient, createHttpLink, InMemoryCache, NormalizedCacheObject, split } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'

import { AuthCookieService } from '../utils/auth'

export type Client = ApolloClient<NormalizedCacheObject>

const cache = new InMemoryCache()

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_GRAPHQL_ENDPOINT,
})

if (!process.env.REACT_APP_WS_GRAPHQL_ENDPOINT) {
  throw new Error("'REACT_APP_WS_GRAPHQL_ENDPOINT' has not been set")
}

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_WS_GRAPHQL_ENDPOINT,
  webSocketImpl: WebSocket,
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: async () => {
      const authInfos = AuthCookieService.getAuthInfo()

      return {
        Authorization: authInfos?.token ? `Bearer ${authInfos?.token}` : '',
      }
    },
  },
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  wsLink,
  httpLink,
)

const authLink = setContext(async (_, { headers }) => {
  const token = AuthCookieService.getAuthInfo()?.token

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  }
})

export const client = new ApolloClient({
  cache,
  link: authLink.concat(splitLink),
})
