import { gql } from '@apollo/client'
import { EquipmentManometerFragment } from '../fragments'

export const MUTATION_CREATE_MANOMETER = gql`
  mutation createManometer($input: ManometerCreateInput!) {
    createManometer(input: $input) {
      ...EquipmentManometerFragment
    }
  }
  ${EquipmentManometerFragment}
`

export const MUTATION_UPDATE_MANOMETER = gql`
  mutation updateManometer($input: ManometerUpdateInput!) {
    updateManometer(input: $input) {
      ...EquipmentManometerFragment
    }
  }
  ${EquipmentManometerFragment}
`

export const MUTATION_DELETE_MANOMETER = gql`
  mutation deleteManometer($id: ID!) {
    deleteManometer(id: $id)
  }
`
