const PSI_BAR_FACTOR = 14.503773773

export const roundNumber = (value: number, round = 2): string => value.toFixed(round).replace(/[.,]0+$/, '')

export const barToPsi = (bar: number): number => bar * PSI_BAR_FACTOR

export const psiToBar = (psi: number): number => psi / PSI_BAR_FACTOR

export const celsiusToFahrenheit = (celsius: number): number => (celsius * 9) / 5 + 32

export const fahrenheitToCelsius = (fahrenheit: number): number => {
  const celsius = ((fahrenheit - 32) * 5) / 9
  return celsius
}
