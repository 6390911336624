import { gql } from '@apollo/client'
import { EquipmentTensiometerFragment } from '../fragments'

export const MUTATION_CREATE_TENSIO_METER = gql`
  mutation createTensiometer($input: TensiometerCreateInput!) {
    createTensiometer(input: $input) {
      ...EquipmentTensiometerFragment
    }
  }
  ${EquipmentTensiometerFragment}
`

export const MUTATION_UPDATE_TENSIO_METER = gql`
  mutation updateTensiometer($input: TensiometerUpdateInput!) {
    updateTensiometer(input: $input) {
      ...EquipmentTensiometerFragment
    }
  }
  ${EquipmentTensiometerFragment}
`

export const MUTATION_DELETE_TENSIO_METER = gql`
  mutation deleteTensiometer($id: ID!) {
    deleteTensiometer(id: $id)
  }
`
