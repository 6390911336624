import { TimeRange } from '../../modules/Measurement/utils'

export const MEASUREMENT_SET_TIME_RANGE = '@@measurement/SET_TIME_RANGE'

export interface SetTimeRange {
  type: typeof MEASUREMENT_SET_TIME_RANGE
  timeRange: TimeRange
}

export type MeasurementActionTypes = SetTimeRange
