import { dispatchToast, ToastArgs } from '../../components/Toast'

export const dispatchToastAddIrrigation = (
  type: 'update' | 'success' | 'error',
  toastIdentifier: string,
  description?: string,
): void => {
  const detail: ToastArgs = {
    message:
      type === 'update'
        ? 'modules_Planning_Toast_adding'
        : type === 'success'
        ? 'modules_Planning_Toast_added'
        : 'modules_Planning_Toast_failed_to_add',
    description,
    fixed: type === 'update' ? true : false,
    type: type === 'update' ? 'info' : type === 'success' ? 'success' : 'warning',
    identifier: toastIdentifier,
  }
  return dispatchToast(detail)
}

export const dispatchToastDeleteIrrigation = (
  type: 'update' | 'success' | 'error' | 'past',
  toastIdentifier: string,
  description?: string,
): void => {
  const detail: ToastArgs = {
    message:
      type === 'update'
        ? 'modules_Planning_Toast_deleting'
        : type === 'success'
        ? 'modules_Planning_Toast_deleted'
        : type === 'past'
        ? 'modules_Planning_Toast_past'
        : 'modules_Planning_Toast_failed_to_delete',
    description,
    fixed: type === 'update' ? true : false,
    type: type === 'update' || type === 'past' ? 'info' : type === 'success' ? 'success' : 'warning',
    identifier: toastIdentifier,
  }
  return dispatchToast(detail)
}
