import { gql } from '@apollo/client'

export const EquipmentFilterAlertFragment = gql`
  fragment EquipmentFilterAlertFragment on FilterAlert {
    id
    startAt
    valueAtStart
    averageAtStart
    endAt
    valueAtEnd
    averageAtEnd
    status
    type
  }
`
