import { gql } from '@apollo/client'

export const IrrigationTemplateFragment = gql`
  fragment IrrigationTemplateFragment on IrrigationTemplate {
    id
    name
    description
    companyId
    folderId
    folder {
      id
      name
      type
    }
    updatedDate
    createdDate
    irrigationCycles {
      id
      startAt
      startHour
      numberOfHours
      numberOfMinutes
      recurrence {
        type
        endAt
        frequency
        days
      }
      sector {
        id
        name
        color
      }
      assignedId
      isIrrigationTemplateOrigin
      irrigationTemplateId
      irrigationEvents {
        id
        startDate
        endDate
        sector {
          id
        }
      }
      recurrence {
        days
      }
      simulatedIrrigationEvents {
        id
        createdDate
        startDate
        endDate
        irrigationCycle {
          id
        }
        sector {
          id
          name
          color
        }
      }
    }
  }
`
