import { DefaultTheme, createGlobalStyle } from 'styled-components'

export const theme: DefaultTheme = {
  fontFamily: 'Quicksand, sans-serif',
  colors: {
    main: '#002E5A',
    secondary: 'white',
    darkBlue: '#002E5A',
    mediumBlue: '#05DBC3',
    lightBlue: '#9BF0E6',
    neonBlue: '#14D7E6',
    white: '#ffffff',
    black: '#000000',
  },
  responsive: {
    tablet: {
      valueString: '768px',
      valueNumber: 768,
    },
    mobile: {
      valueString: '480px',
      valueNumber: 480,
    },
  },
}

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${({ theme }) => theme.fontFamily};
  }
`
