import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { getSectorColorById } from '../../utils/sectorColor'
import Loader from '../Loader'

import {
  Container,
  TitleSelect,
  ContainerSelect,
  Item,
  ArrowDown,
  InputSelect,
  LoaderContainer,
  LabelContainer,
  ColorBox,
} from './Select.styled'

export interface Option {
  id: string
  label: string
  data: any
}

interface Props extends WithTranslation {
  title: string
  options: Option[]
  selected?: Option
  onClick: (option: Option) => void
  disabled?: boolean
  big?: boolean
  filter?: string
  onSearch?: (value: string) => void
  bordered?: boolean
  loading?: boolean
  style?: React.CSSProperties
  colorBox?: boolean
  // searchable?: boolean
}

interface State {
  displaySelect: boolean
}

class Select extends React.Component<Props, State> {
  public state = {
    displaySelect: false,
  }

  inputRef: React.RefObject<HTMLInputElement>

  constructor(props: Props) {
    super(props)
    this.inputRef = React.createRef()
  }

  public componentWillUnmount(): void {
    document.removeEventListener('click', this.hideSelect)
  }

  public render(): React.ReactNode {
    const {
      t,
      title,
      selected,
      options,
      onClick,
      disabled,
      big,
      filter,
      onSearch,
      bordered,
      loading,
      style,
      colorBox,
    } = this.props

    const { displaySelect } = this.state

    return (
      <Container
        style={style}
        className="select-container"
        bordered={bordered}
        big={big}
        disabled={disabled}
        onClick={disabled ? undefined : this.toggleSelect}
      >
        {!!onSearch && displaySelect ? (
          <InputSelect ref={this.inputRef} value={filter} onChange={(e) => onSearch(e.target.value)} />
        ) : (
          <TitleSelect big={big}>
            {selected ? (
              <strong>
                <LabelContainer>
                  {colorBox && <ColorBox color={getSectorColorById(selected.data.color)} />}
                  {selected.label}
                </LabelContainer>
              </strong>
            ) : (
              title
            )}
          </TitleSelect>
        )}
        {!disabled && !loading && <ArrowDown style={{ fontSize: big ? 35 : 25 }} />}
        {loading && (
          <LoaderContainer>
            <Loader size={20} />
          </LoaderContainer>
        )}
        {displaySelect && (
          <ContainerSelect>
            {options.map((option) => (
              <Item key={option.id} onClick={() => onClick(option)}>
                <LabelContainer>
                  {colorBox && <ColorBox color={getSectorColorById(option.data.color)} />}
                  {option.label}
                </LabelContainer>
              </Item>
            ))}
            {options.length == 0 && (
              <Item disabled key="no_data">
                {t('components_Select_empty')}
              </Item>
            )}
          </ContainerSelect>
        )}
      </Container>
    )
  }

  private toggleSelect = () => {
    const { displaySelect } = this.state
    if (!displaySelect) {
      document.addEventListener('click', this.hideSelect)
    }
    this.setState({ displaySelect: !displaySelect }, () => {
      const { onSearch } = this.props
      if (!!onSearch) {
        this.inputRef.current?.focus()
      }
    })
  }

  private hideSelect = () => {
    document.removeEventListener('click', this.hideSelect)
    this.setState({ displaySelect: false })
  }
}

export default withTranslation()(Select)
