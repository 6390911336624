import React from 'react'
import { withRouter, Route, RouteComponentProps } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'

import { Client, client } from '../../apollo/api-client'

import Header from './Header'
import SideBar from './SideBar'
import UserProfile from './UserProfile'

import Routing from './Main.routing'

import Toast from '../../components/Toast'

import { AppContainer, Container, MainContainer } from './Main.styled'

interface State {
  client: Client
}

class Main extends React.Component<RouteComponentProps, State> {
  state = {
    client,
  }

  render(): React.ReactNode {
    const { client } = this.state
    const { match } = this.props

    return (
      <ApolloProvider client={client}>
        <Container>
          <Header />
          <AppContainer>
            <SideBar />
            <UserProfile />
            <MainContainer id="main-container">
              <Route path={match.path} component={Routing} />
              <Toast />
            </MainContainer>
          </AppContainer>
        </Container>
      </ApolloProvider>
    )
  }
}

export default withRouter(Main)
