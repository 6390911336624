import styled from 'styled-components'

export const Form = styled.form``

export const ContainerPatternInfos = styled.div`
  color: ${({ theme }) => theme.colors.mediumBlue};
  font-size: 14px;
  display: flex;
`
export const ContainerError = styled.div`
  margin-top: 10px;
  color: red;
  font-size: 14px;
  display: flex;
`

export const PatternInfos = styled.div`
  padding-left: 10px;
  font-style: italic;
`

export const ButtonSend = styled.button<{ disabled?: boolean }>`
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 18px;
  width: 100%;
  height: 58px;
  color: ${({ theme }) => theme.colors.darkBlue};
  background-color: ${({ theme }) => theme.colors.lightBlue};
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 29px;
  margin: 40px 0;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  outline: none;
  border: none;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
`

export const ButtonBack = styled.button`
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.main};
  background: transparent;
  font-weight: 600;
  font-size: 13px;
  width: 100%;
  cursor: pointer;
  outline: none;
  border: none;
`
export const Label = styled.div`
  max-width: 772px;
  text-align: center;
  font-weight: 600;
  font-size: 22px;
  color: ${({ theme }) => theme.colors.mediumBlue};
  margin: 20px 0;
`
