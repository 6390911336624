import { gql } from '@apollo/client'
import { ZoneWaterSupplyFragment } from '../fragments'

export const MUTATION_CREATE_WATER_SUPPLY = gql`
  mutation createWaterSupply($input: WaterSupplyCreateInput!) {
    createWaterSupply(input: $input) {
      ...ZoneWaterSupplyFragment
    }
  }
  ${ZoneWaterSupplyFragment}
`

export const MUTATION_UPDATE_WATER_SUPPLY = gql`
  mutation updateWaterSupply($input: WaterSupplyUpdateInput!) {
    updateWaterSupply(input: $input) {
      ...ZoneWaterSupplyFragment
    }
  }
  ${ZoneWaterSupplyFragment}
`

export const MUTATION_DELETE_WATER_SUPPLY = gql`
  mutation deleteWaterSupply($id: ID!) {
    deleteWaterSupply(id: $id)
  }
`
