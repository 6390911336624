import { gql } from '@apollo/client'

import { IrrigationSystemFragment } from '../fragments'

export const IRRIGATION_SYSTEMS = gql`
  query irrigationSystems($filters: IrrigationSystemFindInput) {
    irrigationSystems(filters: $filters) {
      ...IrrigationSystemFragment
    }
  }
  ${IrrigationSystemFragment}
`
