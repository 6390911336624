import styled from 'styled-components'

export const Container = styled.div<{ open: boolean }>`
  overflow: hidden;
  height: 100%;
  transition: width 0.3s, min-width 0.3s, right 0.3s;
  width: 445px;
  min-width: 445px;
  position: absolute;
  z-index: 3;
  right: ${({ open }) => (open ? 0 : -445)}px;
  background-color: #e7eef5;
  padding: 50px;
  box-sizing: border-box;

  @media (max-width: ${({ theme }) => theme.responsive.mobile.valueString}) {
    min-width: 100%;
    width: 100%;
    padding: 25px;
  }

  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`

export const HideContainer = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
`

export const Button = styled.button<{ disabled?: boolean }>`
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 18px;
  width: 100%;
  height: 58px;
  color: ${({ theme }) => theme.colors.darkBlue};
  background-color: ${({ theme }) => theme.colors.lightBlue};
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 29px;
  margin: 20px 0;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  outline: none;
  border: none;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
`

export const BadgeContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const PhoneInputContainer = styled.div`
  .PhoneInput {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 10px;
    margin: 15px 0;
    align-items: center;
    justify-content: center;
    background-color: #f7f7f7;
    height: 58px;
    border-radius: 24px;
    box-sizing: border-box;
  }

  input {
    font-family: inherit;
    font-size: 17px;
    background-color: #f7f7f7;
    text-decoration: none;
    border: none;
    outline: none;
    color: black;
    width: 100%;

    ::placeholder {
      color: black;
      opacity: 0.3;
      font-weight: 100;
    }
  }
`
export const ButtonResetPassword = styled.button`
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.main};
  background: transparent;
  font-weight: 600;
  font-size: 13px;
  margin-top: 20px;
  width: 100%;
  cursor: pointer;
  outline: none;
  border: none;
`
