import styled from 'styled-components'

export const Container = styled.div`
  display: flex;

  @media (max-width: ${({ theme }) => theme.responsive.mobile.valueString}) {
    flex-wrap: wrap;
    width: 100%;

    div {
      width: 100%;
    }
  }
`
