import { gql } from '@apollo/client'
import { EquipmentCapacitiveProbeFragment } from './equipment_capacitive_probe.fragment'
import { EquipmentFilterFragment } from './equipment_filter.fragment'
import { EquipmentManometerFragment } from './equipment_manometer.fragment'
import { EquipmentTensiometerFragment } from './equipment_tensiometer.fragment'
import { EquipmentWaterMeterFragment } from './equipment_water_meter.fragment'

export const GroupWaterInletFragment = gql`
  fragment GroupWaterInletFragment on WaterInlet {
    id
    name
    physicalWaterInlet {
      id
      installDate
      brand
      model
      waterSupplier
    }
    capacitiveProbes {
      ...EquipmentCapacitiveProbeFragment
    }
    manometers {
      ...EquipmentManometerFragment
    }
    waterMeters {
      ...EquipmentWaterMeterFragment
    }
    filters {
      ...EquipmentFilterFragment
    }
    tensiometers {
      ...EquipmentTensiometerFragment
    }
  }
  ${EquipmentCapacitiveProbeFragment}
  ${EquipmentFilterFragment}
  ${EquipmentManometerFragment}
  ${EquipmentTensiometerFragment}
  ${EquipmentWaterMeterFragment}
`
