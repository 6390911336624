import { gql } from '@apollo/client'
import { GroupPumpFragment } from '../fragments'

export const MUTATION_CREATE_PUMP = gql`
  mutation createPump($input: PumpCreateInput!) {
    createPump(input: $input) {
      ...GroupPumpFragment
    }
  }
  ${GroupPumpFragment}
`

export const MUTATION_UPDATE_PUMP = gql`
  mutation updatePump($input: PumpUpdateInput!) {
    updatePump(input: $input) {
      ...GroupPumpFragment
    }
  }
  ${GroupPumpFragment}
`

export const MUTATION_DELETE_PUMP = gql`
  mutation deletePump($id: ID!) {
    deletePump(id: $id)
  }
`
