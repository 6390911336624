import { gql } from '@apollo/client'

import { EquipmentCapacitiveProbeFragment } from '../fragments'

export const MUTATION_CREATE_CAPACITIVE_PROBE = gql`
  mutation createCapacitiveProbe($input: CapacitiveProbeCreateInput!) {
    createCapacitiveProbe(input: $input) {
      ...EquipmentCapacitiveProbeFragment
    }
  }
  ${EquipmentCapacitiveProbeFragment}
`

export const MUTATION_UPDATE_CAPACITIVE_PROBE = gql`
  mutation updateCapacitiveProbe($input: CapacitiveProbeUpdateInput!) {
    updateCapacitiveProbe(input: $input) {
      ...EquipmentCapacitiveProbeFragment
    }
  }
  ${EquipmentCapacitiveProbeFragment}
`

export const MUTATION_DELETE_CAPACITIVE_PROBE = gql`
  mutation deleteCapacitiveProbe($id: ID!) {
    deleteCapacitiveProbe(id: $id)
  }
`
