import { gql } from '@apollo/client'

import { UserFragment } from './user.fragment'

export const IrrigationFragment = gql`
  fragment IrrigationFragment on Irrigation {
    id
    startDate
    endDate
    safetyEndDate
    sector {
      id
      name
      color
      valves {
        id
        name
      }
    }
    irrigationCycle {
      id
      isIrrigationTemplateOrigin
      irrigationTemplateId
      irrigationEvents {
        id
        startDate
        endDate
        sector {
          id
          name
        }
      }
      irrigationTemplate {
        id
        name
      }
      recurrence {
        type
        endAt
        frequency
        days
      }
    }
    assigned {
      ...UserFragment
    }
  }
  ${UserFragment}
`

export const IrrigationCalendarFragment = gql`
  fragment IrrigationFragment on Irrigation {
    id
    startDate
    endDate
    safetyEndDate
    sector {
      id
      name
      color
      valves {
        id
        name
      }
    }
    irrigationCycle {
      id
      isIrrigationTemplateOrigin
      irrigationTemplateId
      irrigationTemplate {
        id
        name
      }
      recurrence {
        type
        endAt
        frequency
        days
      }
    }
    assigned {
      ...UserFragment
    }
  }
  ${UserFragment}
`
