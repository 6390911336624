import { gql } from '@apollo/client'

import { EquipmentFilterFragment } from '../fragments'

export const QUERY_FILTER = gql`
  query filter($id: ID!) {
    filter(id: $id) {
      ...EquipmentFilterFragment
    }
  }
  ${EquipmentFilterFragment}
`
