import { gql } from '@apollo/client'
import { EquipmentWaterMeterFragment } from '../fragments'

export const MUTATION_CREATE_WATER_METER = gql`
  mutation createWaterMeter($input: WaterMeterCreateInput!) {
    createWaterMeter(input: $input) {
      ...EquipmentWaterMeterFragment
    }
  }
  ${EquipmentWaterMeterFragment}
`

export const MUTATION_UPDATE_WATER_METER = gql`
  mutation updateWaterMeter($input: WaterMeterUpdateInput!) {
    updateWaterMeter(input: $input) {
      ...EquipmentWaterMeterFragment
    }
  }
  ${EquipmentWaterMeterFragment}
`

export const MUTATION_DELETE_WATER_METER = gql`
  mutation deleteWaterMeter($id: ID!) {
    deleteWaterMeter(id: $id)
  }
`
