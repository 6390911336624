import { gql } from '@apollo/client'

import { GroupValveFragment } from './group_valve.fragment'

export const ZoneSectorFragment = gql`
  fragment ZoneSectorFragment on Sector {
    id
    name
    color
    currentIrrigation {
      id
    }
    numberOfHectares
    valves {
      ...GroupValveFragment
    }
  }
  ${GroupValveFragment}
`
