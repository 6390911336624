import { Company, Land, IrrigationSystem } from '../../types'

const SHARED_COMPANY = 'SHARED_COMPANY'
const SHARED_LAND = 'SHARED_LAND'
const SHARED_IRRIGATION_SYSTEM = 'SHARED_IRRIGATION_SYSTEM'

export const saveCompanyInLocalStorage = (company: Company): void => {
  try {
    const sharedCompany = JSON.stringify(company)
    localStorage.setItem(SHARED_COMPANY, sharedCompany)
  } catch (error) {}
}

export const removeCompanyFromLocalStorage = (): void => {
  try {
    localStorage.removeItem(SHARED_COMPANY)
  } catch (error) {}
}

export const getCompanyFromLocalStorage = (): Company | undefined => {
  const jsonSharedCompany = localStorage.getItem(SHARED_COMPANY)
  if (jsonSharedCompany) {
    try {
      const sharedCompany = JSON.parse(jsonSharedCompany)
      return sharedCompany ? (sharedCompany as Company) : undefined
    } catch (error) {}
  }

  return undefined
}

export const saveLandToLocalStorage = (land: Land): void => {
  try {
    const sharedLand = JSON.stringify(land)
    localStorage.setItem(SHARED_LAND, sharedLand)
  } catch (error) {}
}

export const removeLandFromLocalStorage = (): void => {
  try {
    localStorage.removeItem(SHARED_LAND)
  } catch (error) {}
}

export const getLandFromLocalStorage = (): Land | undefined => {
  const jsonSharedLand = localStorage.getItem(SHARED_LAND)
  if (jsonSharedLand) {
    try {
      const sharedLand = JSON.parse(jsonSharedLand)
      return sharedLand ? (sharedLand as Land) : undefined
    } catch (error) {}
  }

  return undefined
}

export const saveIrrigationSystemToLocalStorage = (irrigationSystem?: IrrigationSystem): void => {
  try {
    const sharedIrrigationSystem = JSON.stringify(irrigationSystem)
    localStorage.setItem(SHARED_IRRIGATION_SYSTEM, sharedIrrigationSystem)
  } catch (error) {}
}

export const removeIrrigationSystemFromLocalStorage = (): void => {
  try {
    localStorage.removeItem(SHARED_IRRIGATION_SYSTEM)
  } catch (error) {}
}

export const getIrrigationSystemFromLocalStorage = (): IrrigationSystem | undefined => {
  const jsonSharedIrrigationSystem = localStorage.getItem(SHARED_IRRIGATION_SYSTEM)
  if (jsonSharedIrrigationSystem) {
    try {
      const sharedIrrigationSystem = JSON.parse(jsonSharedIrrigationSystem)
      return sharedIrrigationSystem ? (sharedIrrigationSystem as IrrigationSystem) : undefined
    } catch (error) {}
  }

  return undefined
}
