import ReactGA from 'react-ga'
import * as Sentry from '@sentry/browser'
import mixpanel from 'mixpanel-browser'

const {
  REACT_APP_SENTRY_DSN,
  REACT_APP_SENTRY_ENVIRONMENT,
  REACT_APP_GOOGLE_ANALYTICS_UA,
  REACT_APP_MIXPANEL_TOKEN_DEV,
  REACT_APP_MIXPANEL_TOKEN,
} = process.env

// Sentry

if (REACT_APP_SENTRY_DSN && REACT_APP_SENTRY_ENVIRONMENT) {
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    environment: REACT_APP_SENTRY_ENVIRONMENT,
  })
}

// Google analytics

const initializeAnalytics = () => {
  if (REACT_APP_GOOGLE_ANALYTICS_UA) {
    ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_UA)
  }
}

initializeAnalytics()

export * from './withTracker.component'

// MIXPANEL

const initializeMixpanel = () => {
  if (REACT_APP_MIXPANEL_TOKEN_DEV && REACT_APP_MIXPANEL_TOKEN) {
    if (process.env.NODE_ENV == 'production') {
      mixpanel.init(REACT_APP_MIXPANEL_TOKEN, { debug: true })
    } else {
      // development
      mixpanel.init(REACT_APP_MIXPANEL_TOKEN_DEV, { debug: true })
    }
  }
}

initializeMixpanel()

export * from './mixpanel.component'
