import { gql } from '@apollo/client'
import { DeviceInstallationInputFragment } from '../fragments'

export const MUTATION_CONFIGURE_DEVICE = gql`
  mutation configureDevice($input: DeviceConfigureInput!) {
    configureDevice(input: $input) {
      ...DeviceInstallationInputFragment
    }
  }
  ${DeviceInstallationInputFragment}
`
