import {
  APP_TOGGLE_MENU,
  ToggleMenu,
  APP_SET_COMPANY,
  SetCompany,
  APP_SET_LAND,
  SetLand,
  APP_SET_IRRIGATION_SYSTEM,
  SetIrrigationSystem,
  APP_SET_LAND_AND_IRRIGATION_SYSTEM,
  SetLandAndIrrigationSystem,
  APP_TOGGLE_USER_PROFILE,
  ToggleUserProfile,
} from './actionTypes'
import { Company, Land, IrrigationSystem } from '../../types'

import {
  saveCompanyInLocalStorage,
  saveLandToLocalStorage,
  removeLandFromLocalStorage,
  saveIrrigationSystemToLocalStorage,
  removeIrrigationSystemFromLocalStorage,
} from './helpers'

export const toggleMenu = (): ToggleMenu => ({
  type: APP_TOGGLE_MENU,
})

export const toggleUserProfile = (): ToggleUserProfile => ({
  type: APP_TOGGLE_USER_PROFILE,
})

export const setCompany = (company: Company): SetCompany => {
  saveCompanyInLocalStorage(company)
  removeLandFromLocalStorage()
  removeIrrigationSystemFromLocalStorage()

  return {
    type: APP_SET_COMPANY,
    company,
  }
}

export const setLand = (land: Land): SetLand => {
  saveLandToLocalStorage(land)
  removeIrrigationSystemFromLocalStorage()

  return {
    type: APP_SET_LAND,
    land,
  }
}

export const setIrrigationSystem = (irrigationSystem: IrrigationSystem): SetIrrigationSystem => {
  saveIrrigationSystemToLocalStorage(irrigationSystem)

  return {
    type: APP_SET_IRRIGATION_SYSTEM,
    irrigationSystem,
  }
}

export const setLandAndIrrigationSystem = (
  land: Land,
  irrigationSystem: IrrigationSystem,
): SetLandAndIrrigationSystem => {
  saveLandToLocalStorage(land)
  saveIrrigationSystemToLocalStorage(irrigationSystem)

  return {
    type: APP_SET_LAND_AND_IRRIGATION_SYSTEM,
    land,
    irrigationSystem,
  }
}
