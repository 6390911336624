import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 15px 0;
  font-size: 30px;
  background: ${({ theme }) => theme.colors.white};
`
