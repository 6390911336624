import React from 'react'
import { useTranslation } from 'react-i18next'
import IconButton from '@material-ui/core/IconButton'
import SignOutIcon from '@material-ui/icons/PowerSettingsNew'

import SelectCompany from '../../../shared/components/SelectCompany'
import SelectIrrigationSystem from '../../../shared/components/SelectIrrigationSystem'

import { withHeader, WithHeaderProps } from './Header.container'

import {
  Container,
  LogoContainer,
  ToolbarContainer,
  SelectContainer,
  Title,
  UserContainer,
  Username,
} from './Header.styled'
import { UserRole } from '../../../types'

export const Header = (props: WithHeaderProps): JSX.Element => {
  const {
    userState: { user },
    signOut,
    toggleMenu,
    toggleUserProfile,
  } = props

  const { i18n } = useTranslation()

  return (
    <Container>
      <LogoContainer onClick={toggleMenu} />
      <ToolbarContainer>
        <SelectContainer>
          {user && user.roles.indexOf(UserRole.RoleTelaquaAdmin) !== -1 && <SelectCompany />}
          <SelectIrrigationSystem />
        </SelectContainer>
        {user && user.roles.indexOf(UserRole.RoleTelaquaAdmin) === -1 && (
          <Title>
            {user.company
              ? `${i18n.t('layout_Main_Header_company')}: ${user.company.name}${user.company.isDemo ? ' (DEMO)' : ''}`
              : i18n.t('layout_Main_Header_help')}
          </Title>
        )}
        <UserContainer>
          {user && <Username onClick={toggleUserProfile}>{`${user.firstName} ${user.lastName}`}</Username>}
          <IconButton style={{ marginLeft: 10 }} color="inherit" onClick={signOut}>
            <SignOutIcon />
          </IconButton>
        </UserContainer>
      </ToolbarContainer>
    </Container>
  )
}

export default withHeader(Header)
