import styled from 'styled-components'
import { ToastType } from './Toast.component'

export const Container = styled.div<{ open: boolean; type?: ToastType }>`
  position: absolute;
  height: 78px;
  left: ${({ open }) => (open ? 24 + 240 : 24)}px;
  width: ${({ open }) => (open ? 'calc(100% - 48px - 240px)' : 'calc(100% - 48px)')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  border: solid 1px
    ${({ type }) => {
      if (type === 'success') return '#2abd9f'
      if (type === 'warning') return '#d63031'
      return '#00cec9'
    }};
  background-color: ${({ type }) => {
    if (type === 'success') return '#defbf5'
    if (type === 'warning') return '#ff7675'
    return '#81ecec'
  }};
  padding: 0 30px;
  box-sizing: border-box;
  transition: top 0.3s ease-out, width 0.3s, left 0.3s;
  z-index: 10;
`

export const Message = styled.div<{ type?: ToastType }>`
  font-size: 20px;
  font-weight: 500;
  color: ${({ type }) => {
    if (type === 'success') return '#2abd9f'
    if (type === 'warning') return '#d63031'
    return '#00cec9'
  }};
`

export const ContainerActions = styled.div`
  display: flex;
  align-items: center;
`

export const ContainerCancel = styled.div`
  color: rgba(22, 38, 45, 0.2);
  cursor: pointer;
  margin-left: 10px;
  padding-top: 5px;
`
