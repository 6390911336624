import { gql } from '@apollo/client'

export const EquipmentManometerIrrigationFragment = gql`
  fragment EquipmentManometerIrrigationFragment on ManometerIrrigation {
    id
    startAt
    valueAtStart
    endAt
    valueAtEnd
    status
  }
`
