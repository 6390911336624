import { gql } from '@apollo/client'

export const EquipmentTensiometerAlertFragment = gql`
  fragment EquipmentTensiometerAlertFragment on TensiometerAlert {
    id
    startAt
    valueAtStart
    averageAtStart
    endAt
    valueAtEnd
    averageAtEnd
    status
    type
  }
`
