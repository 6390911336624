import { useQuery, ApolloError } from '@apollo/client'

import { COMPANIES } from '../apollo/queries'

import { PaginatedCompany, QueryCompaniesArgs } from '../types'

interface Data {
  companies?: PaginatedCompany
}

export const useCompanies = (
  query?: string,
): {
  loading: boolean
  error: ApolloError | undefined
  refetch: (query?: string) => void
  data?: Data
} => {
  const { loading, error, data, refetch } = useQuery<Data, QueryCompaniesArgs>(COMPANIES, {
    variables: {
      input: {
        query_string: query,
      },
      pagination: { first: 100, after: undefined },
    },
    notifyOnNetworkStatusChange: true,
  })

  const refetchData = (query?: string) => {
    const variables: QueryCompaniesArgs = {
      input: {
        query_string: query,
      },
      pagination: { first: 100, after: undefined },
    }
    refetch(variables)
  }

  return { loading, error, data, refetch: refetchData }
}
