import { gql } from '@apollo/client'

import { CountryFragment } from '../fragments'

export const QUERY_FIND_COUNTRIES = gql`
  query countries($input: CountryFindInput) {
    countries(input: $input) {
      ...CountryFragment
    }
  }
  ${CountryFragment}
`
