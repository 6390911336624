import { gql } from '@apollo/client'

import { LandFragment } from '../fragments'

export const LANDS = gql`
  query lands($filters: LandFindInput) {
    lands(filters: $filters) {
      ...LandFragment
    }
  }
  ${LandFragment}
`
