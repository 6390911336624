import { gql } from '@apollo/client'

import { IrrigationFragment } from '../fragments/irrigation.fragment'

export const MUTATION_UPDATE_IRRIGATION_EVENT = gql`
  mutation updateIrrigationEvent($input: IrrigationEventUpdateInput!) {
    updateIrrigationEvent(input: $input) {
      ...IrrigationFragment
    }
  }
  ${IrrigationFragment}
`

export const MUTATION_DELETE_IRRIGATION_EVENT = gql`
  mutation deleteIrrigationEvent($id: ID!) {
    deleteIrrigationEvent(id: $id)
  }
`
