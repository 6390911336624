import mixpanel from 'mixpanel-browser'
import React, { useEffect } from 'react'
import { useBeforeunload } from 'react-beforeunload'

import { useLocation } from 'react-router-dom'
import { useUserState } from '../../hooks/useUserState.hook'

const MixPanelTracking: React.FC = () => {
  const user = useUserState().user
  const routeName = useLocation().pathname

  // User changed (sign in, user update, ...)
  useEffect(() => {
    if (user) {
      mixpanel.identify(user.id)

      mixpanel.people.set({
        $first_name: user.firstName,
        $last_name: user.lastName,
        $email: user.email,
        $created: user.createdDate,
      })
    }
  }, [user])

  // Route change tracking
  useEffect(() => {
    let mixPanelRouteTaxonomy = null
    if (routeName?.includes(`/${user?.settings?.locale}/home`)) {
      mixPanelRouteTaxonomy = 'Home'
    } else if (routeName?.includes('dashboard/all')) {
      mixPanelRouteTaxonomy = 'Dashboard/all'
    } else if (routeName?.includes('dashboard/water-supply')) {
      mixPanelRouteTaxonomy = 'Dashboard/water-supply'
    } else if (routeName?.includes('dashboard/sector')) {
      mixPanelRouteTaxonomy = 'Dashboard/sector'
    } else if (routeName?.includes('dashboard/secondary-network')) {
      mixPanelRouteTaxonomy = 'Dashboard/secondary-network'
    } else if (routeName?.includes('measurement')) {
      mixPanelRouteTaxonomy = 'Measurement'
    } else if (routeName?.includes('planning')) {
      mixPanelRouteTaxonomy = 'Planning'
    } else if (routeName?.includes('admin/users')) {
      mixPanelRouteTaxonomy = 'Admin/users'
    } else if (routeName?.includes('admin/lands')) {
      mixPanelRouteTaxonomy = 'Admin/lands'
    } else if (routeName?.includes('admin/irrigation-systems')) {
      mixPanelRouteTaxonomy = 'Admin/irrigation-systems'
    } else if (routeName?.includes('installation/agromote')) {
      mixPanelRouteTaxonomy = 'Installation/agromote'
    } else if (routeName?.includes('installation/mano-v1') || routeName?.includes('ManoV1sStep')) {
      mixPanelRouteTaxonomy = 'Installation/mano-v1'
    } else if (routeName?.includes('installation/mano-v2')) {
      mixPanelRouteTaxonomy = 'Installation/mano-v2'
    } else if (routeName?.includes('/sign-in')) {
      mixPanelRouteTaxonomy = 'Logout'
    } else if (routeName === '/fr') {
      mixPanelRouteTaxonomy = 'Login'
    }

    if (mixPanelRouteTaxonomy) {
      if (mixPanelRouteTaxonomy === 'Login' || mixPanelRouteTaxonomy === 'Logout') {
        mixpanel.track(mixPanelRouteTaxonomy, { ...user, routeName })
        // https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpaneltime_event
        mixpanel.time_event('Logout')
      } else {
        mixpanel.track(mixPanelRouteTaxonomy, user)
      }
    }
  }, [routeName, user])

  // Logout tracking
  useBeforeunload((event) => {
    event.preventDefault()
    mixpanel.track('Logout', user)
  })

  return null
}

export default MixPanelTracking
