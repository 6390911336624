import React from 'react'

import { Container, LeftContainer, Label, Color, Box, LeftBox } from './CheckBox.styled'

interface Props {
  label: string
  onClick: (checked: boolean) => void
  checked?: boolean
  filled?: boolean
  textColor?: Color
  checkBoxColor?: Color
  image?: boolean
}

export const CheckBox = (props: Props): JSX.Element => {
  const { label, checked, onClick, filled, textColor = 'black', checkBoxColor = 'black', image } = props

  return (
    <Container filled={filled} onClick={() => onClick(checked || false)}>
      <Box checked={checked} boxColor={checkBoxColor} image={image} />
      <Label textColor={textColor}>{label}</Label>
    </Container>
  )
}

export const LeftCheckBox = (props: Props): JSX.Element => {
  const { label, checked, onClick, filled, textColor = 'black', checkBoxColor = 'black' } = props

  return (
    <LeftContainer filled={filled} onClick={() => onClick(checked || false)}>
      <Label textColor={textColor}>{label}</Label>
      <LeftBox checked={checked} boxColor={checkBoxColor} />
    </LeftContainer>
  )
}
