import { gql } from '@apollo/client'

import { LabelFragment } from '../fragments/label.fragment'

export const MUTATION_CREATE_LABEL = gql`
  mutation createLabel($input: LabelCreateInput!) {
    createLabel(input: $input) {
      ...LabelFragment
    }
  }
  ${LabelFragment}
`
export const MUTATION_UPDATE_LABEL = gql`
  mutation updateLabel($input: LabelUpdateInput!) {
    updateLabel(input: $input) {
      ...LabelFragment
    }
  }
  ${LabelFragment}
`
