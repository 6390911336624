import { gql } from '@apollo/client'

import { EquipmentFilterMeasureFragment } from './equipment_filter_measure.fragment'
import { PageInfoFragment } from './page_info.fragment'
import { EquipmentFilterAlertFragment } from './equipment_filter_alert.fragment'

export const EquipmentFilterFragment = gql`
  fragment EquipmentFilterFragment on Filter {
    id
    name
    unit
    thresholdPressureDrop
    physicalFilter {
      id
      installDate
      brand
      model
      retroAutomaticWashing
    }
    lastMeasure {
      ...EquipmentFilterMeasureFragment
    }
    alerts(pagination: { first: 20 }) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...EquipmentFilterAlertFragment
        }
      }
    }
  }
  ${EquipmentFilterMeasureFragment}
  ${PageInfoFragment}
  ${EquipmentFilterAlertFragment}
`
