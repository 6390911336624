import { CalendarActionTypes, CALENDAR_SET_DATE, CALENDAR_SET_VIEW_MODE, ViewMode } from './actionTypes'

export interface CalendarState {
  date: Date
  viewMode: ViewMode
}

const defaultState: CalendarState = {
  date: new Date(),
  viewMode: 'week',
}

const calendar = (state: CalendarState = defaultState, action: CalendarActionTypes): CalendarState => {
  switch (action.type) {
    case CALENDAR_SET_DATE:
      return { ...state, date: action.date }
    case CALENDAR_SET_VIEW_MODE:
      return { ...state, viewMode: action.mode }
    default:
      return state
  }
}

export default calendar
