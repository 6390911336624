import styled from 'styled-components'

export const ButtonContainer = styled.button<{ disabled?: boolean; selected?: boolean }>`
  display: flex;
  align-items: center;
  border-radius: 10px;
  height: 36px;
  padding: 0 20px;
  font-weight: bold;
  background-color: ${({ theme, selected }) => (selected ? theme.colors.lightBlue : theme.colors.darkBlue)};
  color: ${({ theme, selected }) => (selected ? theme.colors.darkBlue : 'white')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  user-select: none;
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
  border: none;
  font-size: 16px;
  font-family: ${({ theme }) => theme.fontFamily};
`

export const CircleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.darkBlue};
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
`

export const SmallContainer = styled.div<{ selected?: boolean; disabled?: boolean }>`
  display: flex;
  align-items: center;
  border-radius: 3px;
  border: 2px solid ${({ theme, selected }) => (selected ? theme.colors.lightBlue : theme.colors.darkBlue)};
  height: 26px;
  padding: 0 20px;
  background-color: ${({ theme, selected }) => (selected ? theme.colors.lightBlue : theme.colors.darkBlue)};
  color: ${({ theme, selected }) => (selected ? theme.colors.darkBlue : 'white')};
  font-weight: bold;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  user-select: none;
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
`

export const RoundedSmallContainer = styled.div<{ selected?: boolean; disabled?: boolean }>`
  cursor: pointer;
  display: block;
  margin: 0 5px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  color: ${({ selected }) => (selected ? 'white' : '#a7a7a7')};
  text-align: center;
  text-decoration: none;
  background: ${({ selected }) => (selected ? '#4694FF' : '#e7e7e7')};
  font-size: 20px;
  font-weight: bold;
`
