import { gql } from '@apollo/client'

import { EquipmentCapacitiveProbeMeasureFragment } from './equipment_capacitive_probe_measure.fragment'

export const EquipmentCapacitiveProbeFragment = gql`
  fragment EquipmentCapacitiveProbeFragment on CapacitiveProbe {
    id
    name
    rZone
    humidityUnit
    physicalCapacitiveProbe {
      id
      installDate
      brand
      model
      depths
    }
    lastMeasure {
      ...EquipmentCapacitiveProbeMeasureFragment
    }
    thresholds {
      depth
      thresholdMin
      thresholdMax
    }
  }
  ${EquipmentCapacitiveProbeMeasureFragment}
`
