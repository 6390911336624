import { connect, ConnectedProps } from 'react-redux'

import { AppState } from '../../redux'

const mapState = (state: AppState) => ({
  menuOpen: state.app.openMenu,
})

export const withAppState = connect(mapState)

export type WithAppStateProps = ConnectedProps<typeof withAppState>
