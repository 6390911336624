import { gql } from '@apollo/client'

import { IrrigationSystemFragment } from '../fragments'

export const MUTATION_CREATE_IRRIGATION_SYSTEM = gql`
  mutation createIrrigationSystem($input: IrrigationSystemCreateInput!) {
    createIrrigationSystem(input: $input) {
      ...IrrigationSystemFragment
    }
  }
  ${IrrigationSystemFragment}
`

export const MUTATION_UPDATE_IRRIGATION_SYSTEM = gql`
  mutation updateIrrigationSystem($input: IrrigationSystemUpdateInput!) {
    updateIrrigationSystem(input: $input) {
      ...IrrigationSystemFragment
    }
  }
  ${IrrigationSystemFragment}
`

export const MUTATION_DELETE_IRRIGATION_SYSTEM = gql`
  mutation deleteIrrigationSystem($id: ID!) {
    deleteIrrigationSystem(id: $id)
  }
`
