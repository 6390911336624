import { gql } from '@apollo/client'

import { EquipmentFilterFragment } from '../fragments'

export const MUTATION_CREATE_FILTER = gql`
  mutation createFilter($input: FilterCreateInput!) {
    createFilter(input: $input) {
      ...EquipmentFilterFragment
    }
  }
  ${EquipmentFilterFragment}
`

export const MUTATION_UPDATE_FILTER = gql`
  mutation updateFilter($input: FilterUpdateInput!) {
    updateFilter(input: $input) {
      ...EquipmentFilterFragment
    }
  }
  ${EquipmentFilterFragment}
`

export const MUTATION_DELETE_FILTER = gql`
  mutation deleteFilter($id: ID!) {
    deleteFilter(id: $id)
  }
`
