import { gql } from '@apollo/client'

export const IRRIGATION_TEMPLATES = gql`
  query irrigationTemplates($companyId: ID!) {
    irrigationTemplates(companyId: $companyId) {
      id
      name
    }
  }
`
