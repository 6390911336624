import { gql } from '@apollo/client'

import { PageInfoFragment, CompanyFragment } from '../fragments'

export const COMPANIES = gql`
  query companies($input: CompanyFindInput, $pagination: PaginationInput!) {
    companies(input: $input, pagination: $pagination) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...CompanyFragment
        }
      }
    }
  }
  ${PageInfoFragment}
  ${CompanyFragment}
`
