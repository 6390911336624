import { User } from '../../types'

// Login

export const USER_LOGIN_LOADING = '@@user/LOGIN_LOADING'
export const USER_LOGIN_LOADED_ERROR = '@@user/LOGIN_LOADED_ERROR'

export interface LoginLoadingAction {
  type: typeof USER_LOGIN_LOADING
}

export interface LoginLoadedErrorAction {
  type: typeof USER_LOGIN_LOADED_ERROR
}

// SignUp
export const USER_SIGNUP_LOADING = '@@user/SIGNUP_LOADING'
export const USER_SIGNUP_LOADED_ERROR = '@@user/SIGNUP_LOADED_ERROR'

export interface SignUpLoadingAction {
  type: typeof USER_SIGNUP_LOADING
}
export interface SignUpLoadedErrorAction {
  type: typeof USER_SIGNUP_LOADED_ERROR
}

// Signout

export const USER_SIGN_OUT = '@@user/SIGN_OUT'

export interface SignOutAction {
  type: typeof USER_SIGN_OUT
}

// User details

export const USER_DETAIL_LOADED_SUCCESS = '@@user/DETAIL_LOADED_SUCCESS'
export const USER_DETAIL_LOADED_ERROR = '@@user/DETAIL_LOADED_ERROR'

export interface DetailLoadedSuccessAction {
  type: typeof USER_DETAIL_LOADED_SUCCESS
  user: User
}

export interface DetailLoadedErrorAction {
  type: typeof USER_DETAIL_LOADED_ERROR
}

export const USER_DETAIL_UPDATING = '@@user/DETAIL_UPDATING'
export const USER_DETAIL_UPDATED_SUCCESS = '@@user/DETAIL_UPDATED_SUCCESS'
export const USER_DETAIL_UPDATED_ERROR = '@@user/DETAIL_UPDATED_ERROR'

export interface DetailUpdatingAction {
  type: typeof USER_DETAIL_UPDATING
}

export interface DetailUpdatedSuccessAction {
  type: typeof USER_DETAIL_UPDATED_SUCCESS
  user: User
}

export interface DetailUpdatedErrorAction {
  type: typeof USER_DETAIL_UPDATED_ERROR
}

export type UserActionTypes =
  | LoginLoadingAction
  | LoginLoadedErrorAction
  | SignUpLoadingAction
  | SignUpLoadedErrorAction
  | SignOutAction
  | DetailLoadedSuccessAction
  | DetailLoadedErrorAction
  | DetailUpdatingAction
  | DetailUpdatedSuccessAction
  | DetailUpdatedErrorAction
