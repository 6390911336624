import {
  SEARCH_SET_LANDS_SEARCH_QUERY,
  SetLandsSearchQuery,
  SEARCH_SET_USERS_SEARCH_QUERY,
  SetUsersSearchQuery,
  SEARCH_SET_IRRIGATION_SYTEMS_SEARCH_QUERY,
  SetIrrigationSystemsSearchQuery,
} from './actionTypes'

export const setUsersSearchQuery = (query: string): SetUsersSearchQuery => ({
  type: SEARCH_SET_USERS_SEARCH_QUERY,
  query,
})

export const setLandsSearchQuery = (query: string): SetLandsSearchQuery => ({
  type: SEARCH_SET_LANDS_SEARCH_QUERY,
  query,
})

export const setIrrigationSystemsSearchQuery = (query: string): SetIrrigationSystemsSearchQuery => ({
  type: SEARCH_SET_IRRIGATION_SYTEMS_SEARCH_QUERY,
  query,
})
