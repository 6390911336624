import { gql } from '@apollo/client'
import { IrrigationCycleFragment } from '../fragments'

import { IrrigationFragment } from '../fragments/irrigation.fragment'

export const MUTATION_CREATE_IRRIGATION_CYCLE = gql`
  mutation createIrrigationCycle($input: IrrigationCycleCreateInput!) {
    createIrrigationCycle(input: $input) {
      ...IrrigationFragment
    }
  }
  ${IrrigationFragment}
`

export const MUTATION_UPDATE_IRRIGATION_CYCLE = gql`
  mutation updateIrrigationCycle($input: IrrigationCycleUpdateInput!) {
    updateIrrigationCycle(input: $input) {
      ...IrrigationCycleFragment
    }
  }
  ${IrrigationCycleFragment}
`

export const MUTATION_VALIDATE_IRRIGATION_CYCLE = gql`
  mutation validateIrrigationCycle($input: IrrigationCycleSimulateInput!) {
    validateIrrigationCycle(input: $input) {
      ...IrrigationFragment
    }
  }
  ${IrrigationFragment}
`

export const MUTATION_DELETE_IRRIGATION_CYCLE = gql`
  mutation deleteIrrigationCycle($id: ID!) {
    deleteIrrigationCycle(id: $id)
  }
`
