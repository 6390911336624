import { TimeRange, mockTimeRangeItems } from '../../modules/Measurement/utils'
import { MeasurementActionTypes, MEASUREMENT_SET_TIME_RANGE } from './actionTypes'

export interface MeasurementState {
  timeRange: TimeRange
}

const defaultState: MeasurementState = {
  timeRange: mockTimeRangeItems()[0],
}

const measurement = (state: MeasurementState = defaultState, action: MeasurementActionTypes): MeasurementState => {
  switch (action.type) {
    case MEASUREMENT_SET_TIME_RANGE:
      return { ...state, timeRange: action.timeRange }
    default:
      return state
  }
}

export default measurement
