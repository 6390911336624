import { gql } from '@apollo/client'

import { UserFragment } from '../fragments'

export const USERS = gql`
  query users($filters: UserFindInput) {
    users(filters: $filters) {
      ...UserFragment
    }
  }
  ${UserFragment}
`
