import { gql } from '@apollo/client'
import { GroupWaterInletFragment } from '../fragments'

export const MUTATION_CREATE_WATER_INLET = gql`
  mutation createWaterInlet($input: WaterInletCreateInput!) {
    createWaterInlet(input: $input) {
      ...GroupWaterInletFragment
    }
  }
  ${GroupWaterInletFragment}
`

export const MUTATION_UPDATE_WATER_INLET = gql`
  mutation updateWaterInlet($input: WaterInletUpdateInput!) {
    updateWaterInlet(input: $input) {
      ...GroupWaterInletFragment
    }
  }
  ${GroupWaterInletFragment}
`

export const MUTATION_DELETE_WATER_INLET = gql`
  mutation deleteWaterInlet($id: ID!) {
    deleteWaterInlet(id: $id)
  }
`
