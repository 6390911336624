import React, { useMemo } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { withTranslation, WithTranslation } from 'react-i18next'
import HomeIcon from '../../../assets/home.svg'
import DashboardIcon from '../../../assets/dashboard.svg'
import GraphicIcon from '../../../assets/graphic.svg'
import PlanningIcon from '../../../assets/planning.svg'
// import WarningIcon from '@material-ui/icons/WarningRounded'
import InstallIcon from '../../../assets/install.svg'
import SettingIcon from '../../../assets/setting.svg'
import IconButton from '@material-ui/core/IconButton'
import SignOutIcon from '@material-ui/icons/PowerSettingsNew'

import selectLanguages from '../../../assets/selectLanguages.png'

import { Container, ListContainer, HideContainer, UserContainer, SelectContainer, Select } from './SideBar.styled'

import Item from './Item'

import { withSideBar, WithSideBarProps } from './SideBar.container'
import { Locale } from '../../../types'
import { useAppState } from '../../../hooks/useAppState.hook'

interface Props extends RouteComponentProps, WithTranslation, WithSideBarProps {}

export const SideBar = (props: Props): JSX.Element => {
  const {
    userState: { user },
    menuOpen,
    location: { pathname },
    history: { push },
    toggleMenu,
    signOut,
    t,
    i18n,
    updateMe,
  } = props

  const { language } = i18n
  const { company } = useAppState()

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (user && user.settings) {
      const {
        phoneNumber,
        firstName,
        lastName,
        settings: { beNotifiedByEmail, beNotifiedBySMS, beNotifiedByPush, notificationSubscriptions },
      } = user
      const input = {
        firstName,
        lastName,
        phoneNumber: phoneNumber ?? '',
        beNotifiedByEmail,
        beNotifiedBySMS,
        beNotifiedByPush,
        notificationSubscriptions,
        locale: event.target.value as Locale,
      }
      updateMe(input)
    }

    const newPath = location.pathname.replace(`/${i18n.language}/`, `/${event.target.value}/`)
    push(newPath)
  }

  const allowExperimentalPlanning = useMemo(() => {
    if (company) {
      return company?.allowExperimentalPlanning
    } else if (user) {
      return user?.company?.allowExperimentalPlanning
    } else {
      return false
    }
  }, [user, company])

  const items = [
    {
      title: t('layout_Main_SideBar_home'),
      path: `/${i18n.language}/home`,
      Icon: () => <img src={HomeIcon} alt="homeIcon" />,
    },
    {
      title: t('layout_Main_SideBar_dashboard'),
      path: `/${i18n.language}/dashboard`,
      Icon: () => <img src={DashboardIcon} alt="dashboardIcon" />,
    },
    {
      title: t('layout_Main_SideBar_measurement'),
      path: `/${i18n.language}/measurement`,
      Icon: () => <img src={GraphicIcon} alt="graphicIcon" />,
    },
    allowExperimentalPlanning
      ? {
          title: t('layout_Main_SideBar_planningV2'),
          path: `/${i18n.language}/beta/planning`,
          Icon: () => <img src={PlanningIcon} alt="planningIcon" />,
          subItems: [
            {
              title: t('layout_Main_SideBar_template'),
              path: `/${i18n.language}/beta/planning/template`,
              hideOnMobile: true,
            },
            {
              title: t('layout_Main_SideBar_irrigation'),
              path: `/${i18n.language}/beta/planning/irrigation`,
              hideOnMobile: true,
            },
          ],
        }
      : {
          title: t('layout_Main_SideBar_planning'),
          path: `/${i18n.language}/planning`,
          Icon: () => <img src={PlanningIcon} alt="planningIcon" />,
        },
    // {
    //   title: t('layout_Main_SideBar_alert'),
    //   path: `/${i18n.language}/alert`,
    //   Icon: WarningIcon,
    // },
    {
      title: t('layout_Main_SideBar_admin'),
      path: `/${i18n.language}/admin`,
      Icon: () => <img src={SettingIcon} alt="settingIcon" />,
      hideOnMobile: true,
      subItems: [
        {
          title: t('layout_Main_SideBar_admin_users'),
          path: `/${i18n.language}/admin/users`,
          hideOnMobile: true,
        },
        {
          title: t('layout_Main_SideBar_admin_lands'),
          path: `/${i18n.language}/admin/lands`,
          hideOnMobile: true,
        },
        {
          title: t('layout_Main_SideBar_admin_irrigation-system'),
          path: `/${i18n.language}/admin/irrigation-systems`,
          hideOnMobile: true,
        },
      ],
    },
    {
      title: t('layout_Main_SideBar_installation'),
      path: `/${i18n.language}/installation`,
      Icon: () => <img src={InstallIcon} alt="installIcon" />,
      subItems: [
        {
          title: t('layout_Main_SideBar_installation_agromote'),
          path: `/${i18n.language}/installation/agromote`,
          hideOnMobile: true,
        },
        {
          title: t('layout_Main_SideBar_installation_ManoV1'),
          path: `/${i18n.language}/installation/mano-v1`,
          hideOnMobile: true,
        },
        {
          title: t('layout_Main_SideBar_installation_ManoV2'),
          path: `/${i18n.language}/installation/mano-v2`,
          hideOnMobile: true,
        },
        {
          title: t('layout_Main_SideBar_installation_AdeunisPulse'),
          path: `/${i18n.language}/installation/adeunis-pulse`,
          hideOnMobile: true,
        },
        {
          title: t('layout_Main_SideBar_installation_AtimPulse'),
          path: `/${i18n.language}/installation/atim-pulse`,
          hideOnMobile: true,
        },
        // {
        //   title: t('layout_Main_SideBar_installation_sensor-data'),
        //   path: `/${i18n.language}/installation/sensor-data`,
        //   hideOnMobile: true,
        // },
      ],
    },
  ]

  const onClick = (path: string) => {
    push(path)
    if (window.innerWidth <= 1100) {
      toggleMenu()
    }
  }

  return (
    <React.Fragment>
      {menuOpen && window.innerWidth <= 1100 && <HideContainer onClick={toggleMenu} />}
      <Container open={menuOpen}>
        <ListContainer>
          {items.map(({ title, path, Icon, subItems, hideOnMobile }) => (
            <React.Fragment key={path}>
              <Item
                selected={pathname.startsWith(path)}
                onClick={() => onClick(path)}
                title={title}
                Icon={Icon}
                hideOnMobile={hideOnMobile}
              />
              {subItems &&
                subItems.map((subItem) => (
                  <Item
                    key={subItem.path}
                    selected={pathname.startsWith(subItem.path)}
                    onClick={() => onClick(subItem.path)}
                    title={subItem.title}
                    hideOnMobile={hideOnMobile}
                    subItem
                  />
                ))}
            </React.Fragment>
          ))}
        </ListContainer>
        <SelectContainer>
          <img src={selectLanguages} />
          <Select value={language} onChange={handleChange}>
            <option value="fr">FR</option>
            <option value="en">EN</option>
            <option value="es">ES</option>
            <option value="pt">PT</option>
            <option value="it">IT</option>
          </Select>
        </SelectContainer>
        <UserContainer>
          <IconButton style={{ marginLeft: 10 }} color="inherit" onClick={signOut}>
            <SignOutIcon />
          </IconButton>
          {user && `${user.firstName} ${user.lastName}`}
        </UserContainer>
      </Container>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(withSideBar(SideBar)))
