import { ThunkAction } from 'redux-thunk'
import moment from 'moment'

import { AppState } from '../'

import {
  CalendarActionTypes,
  SetDate,
  CALENDAR_SET_DATE,
  SetViewMode,
  CALENDAR_SET_VIEW_MODE,
  ViewMode,
} from './actionTypes'

type Thunk<R> = ThunkAction<R, AppState, null, CalendarActionTypes>

export const setDate = (date: Date): SetDate => ({
  type: CALENDAR_SET_DATE,
  date,
})

export const setViewMode = (mode: ViewMode): SetViewMode => ({
  type: CALENDAR_SET_VIEW_MODE,
  mode,
})

export const setNextDate = (): Thunk<void> => async (dispatch, getState) => {
  const state = getState()
  const {
    calendar: { date, viewMode },
  } = state

  let newDate = date

  if (viewMode === 'month') {
    newDate = moment(date).add(1, 'month').toDate()
  } else if (viewMode === 'week') {
    newDate = moment(date).add(1, 'week').toDate()
  } else if (viewMode === 'day') {
    newDate = moment(date).add(1, 'day').toDate()
  }

  dispatch(setDate(newDate))
}

export const setPreviousDate = (): Thunk<void> => async (dispatch, getState) => {
  const state = getState()
  const {
    calendar: { date, viewMode },
  } = state

  let newDate = date

  if (viewMode === 'month') {
    newDate = moment(date).add(-1, 'month').toDate()
  } else if (viewMode === 'week') {
    newDate = moment(date).add(-1, 'week').toDate()
  } else if (viewMode === 'day') {
    newDate = moment(date).add(-1, 'day').toDate()
  }

  dispatch(setDate(newDate))
}
