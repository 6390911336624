import { connect, ConnectedProps } from 'react-redux'

import { AppState, Actions } from '../../../redux'

const mapState = (state: AppState) => ({
  menuOpen: state.app.openMenu,
  userState: state.user,
})

const mapDispatch = {
  signOut: Actions?.user.signOut,
  toggleMenu: Actions?.app.toggleMenu,
  updateMe: Actions?.user.updateMe,
}

export const withSideBar = connect(mapState, mapDispatch)

export type WithSideBarProps = ConnectedProps<typeof withSideBar>
