import { gql } from '@apollo/client'

export const LIST_ROLES_TO_CREATE_USER = gql`
  query listRolesToCreateUser {
    listRolesToCreateUser
  }
`

export const LIST_ROLES_TO_UPDATE_USER = gql`
  query listRolesToUpdateUser($userId: ID!) {
    listRolesToUpdateUser(input: { userId: $userId })
  }
`
