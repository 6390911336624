import { gql } from '@apollo/client'

import { GroupPumpFragment } from './group_pump.fragment'
import { GroupWaterInletFragment } from './group_water_inlet.fragment'

export const ZoneWaterSupplyFragment = gql`
  fragment ZoneWaterSupplyFragment on WaterSupply {
    id
    name
    pumps {
      ...GroupPumpFragment
    }
    waterInlets {
      ...GroupWaterInletFragment
    }
  }
  ${GroupWaterInletFragment}
  ${GroupPumpFragment}
`
