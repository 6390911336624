import styled from 'styled-components'

export const Container = styled.div<{ selected: boolean; hover: boolean; hideOnMobile?: boolean; subItem?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${({ subItem }) => (subItem ? 45 : 60)}px;
  background-color: ${({ selected, hover }) => {
    if (selected) return 'lightgray'
    if (hover) return 'white'
  }};
  color: white;
  font-size: ${({ subItem }) => (subItem ? 14 : 16)}px;
  color: ${({ theme }) => theme.colors.darkBlue};
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.responsive.mobile.valueString}) {
    display: ${({ hideOnMobile }) => (hideOnMobile ? 'none' : 'flex')};
  }
`

export const IconContainer = styled.div`
  width: 80px;
  min-width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
