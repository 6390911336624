import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import en from './en.json'
import fr from './fr.json'
import es from './es.json'
import pt from './pt.json'
import it from './it.json'

export enum Locale {
  En = 'en',
  Fr = 'fr',
  Es = 'es',
  Pt = 'pt',
  It = 'it',
}

export const languages: Locale[] = [Locale.En, Locale.Fr, Locale.Es, Locale.Pt, Locale.It]

export const configurei18n = (lng: Locale): void => {
  i18next.use(initReactI18next).init({
    lng,
    // debug: true,
    resources: {
      en: {
        translation: en,
      },
      fr: {
        translation: fr,
      },
      es: {
        translation: es,
      },
      pt: {
        translation: pt,
      },
      it: {
        translation: it,
      },
    },
  })
}
