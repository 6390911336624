import { gql } from '@apollo/client'

import { IrrigationCycleFragment } from '../fragments'

export const IRRIGATION_CYCLES = gql`
  query irrigationCycles($irrigationTemplateId: ID) {
    irrigationCycles(irrigationTemplateId: $irrigationTemplateId) {
      ...IrrigationCycleFragment
    }
  }
  ${IrrigationCycleFragment}
`
