import React from 'react'

import { User } from '../../types'

import { Container } from './UserBadge.styled'

interface Props {
  user: User
}

const UserBadge = (props: Props): JSX.Element => {
  const { user } = props
  return (
    <Container>
      {user.firstName[0]}
      {user.lastName[0]}
    </Container>
  )
}

export default UserBadge
