import { gql } from '@apollo/client'

export const PageInfoFragment = gql`
  fragment PageInfoFragment on PageInfo {
    startCursor
    endCursor
    hasPreviousPage
    hasNextPage
  }
`
