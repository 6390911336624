import { gql } from '@apollo/client'

export const MEASUREMENTS_FOR_SECONDARY_NETWORK = gql`
  query measurementsForSecondaryNetwork($secondaryNetworkId: ID!, $startDate: DateTime!, $endDate: DateTime!) {
    secondaryNetwork(id: $secondaryNetworkId) {
      name
      stitchingPoints {
        id
        name
        manometers {
          id
          name
          measures(startDate: $startDate, endDate: $endDate) {
            timestamp
            value
          }
        }
        tensiometers {
          id
          name
          measures(startDate: $startDate, endDate: $endDate) {
            timestamp
            value
          }
        }
        filters {
          id
          name
          measures(startDate: $startDate, endDate: $endDate) {
            timestamp
            pressureIn
            pressureOut
            pressureDrop
          }
        }
        waterMeters {
          id
          name
          volumeMeasures(startDate: $startDate, endDate: $endDate) {
            timestamp
            totalLiter
          }
          flowMeasures(startDate: $startDate, endDate: $endDate) {
            timestamp
            flow
          }
        }
        capacitiveProbes {
          id
          name
          rZone
          humidityUnit
          thresholds {
            depth
            thresholdMin
            thresholdMax
          }
          physicalCapacitiveProbe {
            id
            depths
          }
          measures(startDate: $startDate, endDate: $endDate) {
            timestamp
            values {
              depth
              humidity
              temperature
            }
          }
        }
      }
    }
  }
`
