import React from 'react'
import EditIcon from '@material-ui/icons/EditRounded'
import DeleteIcon from '@material-ui/icons/DeleteRounded'
import AddIcon from '@material-ui/icons/AddRounded'
import CheckIcon from '@material-ui/icons/CheckRounded'

import { ButtonContainer, CircleContainer, SmallContainer, RoundedSmallContainer } from './Button.styled'
import Loader from '../Loader'

interface ButtonProps {
  label: string
  selected?: boolean
  onClick: () => void
  disabled?: boolean
  loading?: boolean
  style?: React.CSSProperties
}

export const Button = (props: ButtonProps): JSX.Element => {
  const { onClick, label, disabled, selected, loading, style } = props

  return (
    <ButtonContainer
      style={style}
      selected={selected}
      disabled={disabled}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        if (!disabled) {
          onClick()
        }
      }}
    >
      {loading ? <Loader size={20} /> : label}
    </ButtonContainer>
  )
}

interface CircleButtonProps {
  type: 'edit' | 'delete' | 'add' | 'save'
  onClick: () => void
  loading?: boolean
}

export const CircleButton = (props: CircleButtonProps): JSX.Element => {
  const { onClick, type, loading } = props

  let Icon = AddIcon
  if (type === 'edit') Icon = EditIcon
  else if (type == 'delete') Icon = DeleteIcon
  else if (type == 'save') Icon = CheckIcon

  return (
    <CircleContainer
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        onClick()
      }}
    >
      {loading ? <Loader size={20} /> : <Icon style={{ fontSize: 20 }} />}
    </CircleContainer>
  )
}

interface SmallButtonProps {
  label: string
  onClick: () => void
  selected?: boolean
  disabled?: boolean
  loading?: boolean
}

export const SmallButton = (props: SmallButtonProps): JSX.Element => {
  const { onClick, label, selected, disabled, loading } = props

  return (
    <SmallContainer
      selected={selected}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        if (!disabled) {
          onClick()
        }
      }}
    >
      {loading ? <Loader size={20} /> : label}
    </SmallContainer>
  )
}

interface RoundedSmallButtonProps {
  label: string
  onClick: () => void
  selected: boolean
  disabled?: boolean
  loading?: boolean
  style?: React.CSSProperties
}

export const RoundedSmallButton = (props: RoundedSmallButtonProps): JSX.Element => {
  const { onClick, label, selected, disabled, loading, style } = props

  return (
    <RoundedSmallContainer
      selected={selected}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        if (!disabled) {
          onClick()
        }
      }}
      style={style}
    >
      {loading ? <Loader size={20} /> : label}
    </RoundedSmallContainer>
  )
}
