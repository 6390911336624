import { gql } from '@apollo/client'

export const EquipmentCapacitiveProbeMeasureFragment = gql`
  fragment EquipmentCapacitiveProbeMeasureFragment on CapacitiveProbeMeasure {
    timestamp
    averageHumidity
    averageTemperature
    values {
      depth
      humidity
      temperature
    }
  }
`
