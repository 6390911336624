import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Land, IrrigationSystem } from '../../../types'

import Select, { Option as SelectOption } from '../../../components/Select'

import { useLands } from '../../../hooks/useLands.hook'
import { useAppState } from '../../../hooks/useAppState.hook'

import { Container } from './SelectIrrigationSystem.styled'
import { useIrrigationSystemsLazy } from '../../../hooks/useIrrigationSystemsLazy.hook'

const SelectIrrigationSystem = (): JSX.Element => {
  const { t } = useTranslation()

  const { company, land, irrigationSystem, setLand, setIrrigationSystem, setLandAndIrrigationSystem } = useAppState()

  const { data, loading } = useLands(company?.id)
  const {
    getIrrigationSystems,
    data: dataIrrigationSystems,
    loading: loadingIrrigationSystems,
  } = useIrrigationSystemsLazy()

  const lands = useMemo(() => data?.lands || [], [data?.lands])
  const irrigationSystems = useMemo(() => dataIrrigationSystems?.irrigationSystems || [], [
    dataIrrigationSystems?.irrigationSystems,
  ])

  const landOptions: SelectOption[] = useMemo(
    () => lands.map((land) => ({ id: land.id, label: land.name, data: land })),
    [lands],
  )

  const selectedLand = useMemo(() => land && { id: land.id, label: land.name, data: land }, [land])

  const irrigationSystemOptions: SelectOption[] = useMemo(
    () =>
      irrigationSystems.map((is) => ({
        id: is.id,
        label: is.name,
        data: is,
      })),
    [irrigationSystems],
  )

  const selectedIrrigationSystem = useMemo(
    () =>
      irrigationSystem && {
        id: irrigationSystem.id,
        label: irrigationSystem.name,
        data: irrigationSystem,
      },
    [irrigationSystem],
  )

  useEffect(() => {
    if (selectedLand && company) {
      getIrrigationSystems({ variables: { filters: { companyId: company?.id, landId: selectedLand?.id } } })
    }
  }, [company, getIrrigationSystems, selectedLand])

  useEffect(() => {
    if (!land && !irrigationSystem) {
      if (lands.length > 0) {
        const landToSelect = lands[0]
        if (landToSelect.irrigationSystems && landToSelect.irrigationSystems.length > 0) {
          setLandAndIrrigationSystem(landToSelect, landToSelect.irrigationSystems[0])
        }
      }
    }
  }, [land, lands, irrigationSystem, setLandAndIrrigationSystem])

  return (
    <Container>
      <Select
        style={{ margin: '0 10px' }}
        title={loading ? t('loading') : t('shared_components_SelectIrrigationSystem_land')}
        options={landOptions}
        selected={selectedLand}
        onClick={(option) => {
          setLand(option.data as Land)
        }}
        loading={loading}
      />
      <Select
        title={t('shared_components_SelectIrrigationSystem_irrigation_system')}
        options={irrigationSystemOptions}
        selected={selectedIrrigationSystem}
        onClick={(option) => {
          setIrrigationSystem(option.data as IrrigationSystem)
        }}
        loading={loading || loadingIrrigationSystems}
      />
    </Container>
  )
}

export default SelectIrrigationSystem
