import { gql } from '@apollo/client'
import { AddressFragment } from './address.fragment'
import { CountryFragment } from './country.fragment'

export const LandFragment = gql`
  fragment LandFragment on Land {
    id
    name
    numberOfHectares
    networkProvider
    timeZone
    dailyCSVEmail
    address {
      ...AddressFragment
    }
    country {
      ...CountryFragment
    }
    irrigationSystems {
      id
      name
    }
  }
  ${AddressFragment}
  ${CountryFragment}
`
