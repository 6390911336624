import { gql } from '@apollo/client'

export const UserFragment = gql`
  fragment UserFragment on User {
    id
    createdDate
    updatedDate
    firstName
    lastName
    email
    phoneNumber
    roles
    settings {
      id
      beNotifiedByEmail
      beNotifiedBySMS
      beNotifiedByPush
      locale
      notificationSubscriptions
    }
    company {
      id
      isDemo
      allowExperimentalPlanning
    }
  }
`
