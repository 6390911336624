import { gql } from '@apollo/client'

import { UserFragment } from '../fragments'

export const MUTATION_CREATE_USER = gql`
  mutation createUser($input: UserCreateInput!) {
    createUser(input: $input) {
      ...UserFragment
    }
  }
  ${UserFragment}
`

export const MUTATION_UPDATE_USER = gql`
  mutation updateUser($input: UserUpdateInput!) {
    updateUser(input: $input) {
      ...UserFragment
    }
  }
  ${UserFragment}
`
