import { gql } from '@apollo/client'

import { EquipmentManometerWithExtensiveIrrigationHistoryFragment } from '../fragments'

export const QUERY_MANOMETER_WITH_EXTENSIVE_IRRIGATION_HISTORY = gql`
  query manometer($id: ID!) {
    manometer(id: $id) {
      ...EquipmentManometerFragment
    }
  }
  ${EquipmentManometerWithExtensiveIrrigationHistoryFragment}
`
