import {
  SEARCH_SET_USERS_SEARCH_QUERY,
  SEARCH_SET_LANDS_SEARCH_QUERY,
  SEARCH_SET_IRRIGATION_SYTEMS_SEARCH_QUERY,
  AppActionTypes,
} from './actionTypes'
import { USER_SIGN_OUT } from '../user/actionTypes'

export interface SearchState {
  user: {
    query: string
  }
  land: {
    query: string
  }
  irrigationSystem: {
    query: string
  }
}

const defaultState: SearchState = {
  user: {
    query: '',
  },
  land: {
    query: '',
  },
  irrigationSystem: {
    query: '',
  },
}

const search = (state: SearchState = defaultState, action: AppActionTypes): SearchState => {
  switch (action.type) {
    case SEARCH_SET_USERS_SEARCH_QUERY:
      return {
        ...state,
        user: {
          query: action.query,
        },
      }

    case SEARCH_SET_LANDS_SEARCH_QUERY:
      return {
        ...state,
        land: {
          query: action.query,
        },
      }

    case SEARCH_SET_IRRIGATION_SYTEMS_SEARCH_QUERY:
      return {
        ...state,
        irrigationSystem: {
          query: action.query,
        },
      }

    case USER_SIGN_OUT:
      return defaultState
    default:
      return state
  }
}

export default search
