import { useSelector, useDispatch } from 'react-redux'

import { AppState, Actions } from '../redux'
import { Company, IrrigationSystem, Land } from '../types'

export const useAppState = (): {
  company?: Company
  land?: Land
  irrigationSystem?: IrrigationSystem
  getSelectedCompany: () => Company
  getSelectedLand: () => Land
  getSelectedIrrigationSystem: () => IrrigationSystem
  setCompany: (company: Company) => void
  setLand: (land: Land) => void
  setIrrigationSystem: (irrigationSystem: IrrigationSystem) => void
  setLandAndIrrigationSystem: (land: Land, irrigationSystem: IrrigationSystem) => void
} => {
  const company = useSelector((state: AppState) => state.app.company)
  const land = useSelector((state: AppState) => state.app.land)
  const irrigationSystem = useSelector((state: AppState) => state.app.irrigationSystem)

  const dispatch = useDispatch()

  function setCompany(company: Company) {
    dispatch(Actions.app.setCompany(company))
  }

  function setLand(land: Land) {
    dispatch(Actions.app.setLand(land))
  }

  function setIrrigationSystem(irrigationSystem: IrrigationSystem) {
    dispatch(Actions.app.setIrrigationSystem(irrigationSystem))
  }

  function setLandAndIrrigationSystem(land: Land, irrigationSystem: IrrigationSystem) {
    dispatch(Actions.app.setLandAndIrrigationSystem(land, irrigationSystem))
  }

  const getSelectedCompany = (): Company => {
    if (!company) {
      throw new Error('Company has not been selected')
    }
    return company
  }

  const getSelectedLand = (): Land => {
    if (!land) {
      throw new Error('Land has not been selected')
    }
    return land
  }

  const getSelectedIrrigationSystem = (): IrrigationSystem => {
    if (!irrigationSystem) {
      if (!!land) return land.irrigationSystems[0]
      throw new Error('IrrigationSystem has not been selected')
    }
    return irrigationSystem
  }

  return {
    company,
    land,
    irrigationSystem,
    getSelectedCompany,
    getSelectedLand,
    getSelectedIrrigationSystem,
    setCompany,
    setLand,
    setIrrigationSystem,
    setLandAndIrrigationSystem,
  }
}
