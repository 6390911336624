import { Pump, WaterInlet, Valve, StitchingPoint, GroupType } from './generated/graphql'

export type Groups = Pump | WaterInlet | Valve | StitchingPoint

export const getGroupType = (group: Groups): GroupType => {
  if (group.__typename == 'Pump') {
    return GroupType.Pump
  }
  if (group.__typename == 'WaterInlet') {
    return GroupType.WaterInlet
  }
  if (group.__typename == 'Valve') {
    return GroupType.Valve
  }
  if (group.__typename == 'StitchingPoint') {
    return GroupType.StitchingPoint
  }

  throw new Error('Invalid group')
}
