import { gql } from '@apollo/client'

import { DeviceInputFragment } from './device_input.fragment'
import { ManoPropertiesFragment } from './device-mano-properties.fragment'

export const DeviceFragment = gql`
  fragment DeviceFragment on Device {
    id
    name
    appEui
    devEui
    type
    hardwareVersion
    firmwareVersion
    properties {
      ... on ManoProperties {
        ...ManoPropertiesFragment
      }
    }
    inputs {
      ...DeviceInputFragment
    }
  }
  ${DeviceInputFragment}
  ${ManoPropertiesFragment}
`
