import { connect, ConnectedProps } from 'react-redux'

import { AppState, Actions } from '../../../redux'

const mapState = (state: AppState) => ({
  userProfileOpen: state.app.openUserProfile,
  userState: state.user,
})

const mapDispatch = {
  toggleUserProfile: Actions?.app.toggleUserProfile,
  updateMe: Actions?.user.updateMe,
}

export const withUserProfile = connect(mapState, mapDispatch)

export type WithUserProfile = ConnectedProps<typeof withUserProfile>
