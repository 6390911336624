import React from 'react'

import { Container, Input, IconContainer, Icon } from './InputRounded.styled'

interface Props {
  value: string
  disabled?: boolean
  required?: boolean
  placeholder?: string
  type?: string
  min?: string
  max?: string
  src?: string
  pattern?: string
  onChange?: (value: string) => void
}

const InputRounded = (props: Props): JSX.Element => {
  const { value, placeholder, required, type, onChange, disabled, src, pattern, min, max } = props

  return (
    <Container>
      {src && (
        <IconContainer>
          <Icon src={src} />
        </IconContainer>
      )}
      <Input
        pattern={pattern}
        disabled={disabled}
        icon={!!src}
        required={required}
        type={type}
        min={min}
        max={max}
        placeholder={placeholder}
        value={value}
        onChange={!disabled && onChange ? (e) => onChange(e.target.value) : undefined}
      />
    </Container>
  )
}

export default InputRounded
