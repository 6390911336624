import React from 'react'
import { useTranslation } from 'react-i18next'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import mailImg from '../../../assets/mail.png'
import userImg from '../../../assets/user.png'

import UserBadge from '../../../components/UserBadge'
import InputRounded from '../../../components/InputRounded'
import { CheckBox } from '../../../components/CheckBox'
import Select from '../../../components/Select'

import {
  Container,
  HideContainer,
  BadgeContainer,
  Button,
  PhoneInputContainer,
  ButtonResetPassword,
} from './UserProfile.styled'

import { withUserProfile, WithUserProfile } from './UserProfile.container'
import { Locale } from '../../../types'
import EditPassword from '../../../modules/EditPassword/EditPassword.component'
import useMeasureSystemPreference from '../../../utils/hooks/useMeasureSystemPreference'

type Props = WithUserProfile

interface State {
  phoneNumber: string
  beNotifiedByEmail: boolean
  beNotifiedBySMS: boolean
  beNotifiedByPush: boolean
  notificationSubscriptions: Array<string>
  locale: Locale
  resetPassword: boolean
  useImperial: boolean
}

const locales = [
  {
    id: Locale.En,
    label: 'English',
    data: Locale.En,
  },
  {
    id: Locale.Fr,
    label: 'Français',
    data: Locale.Fr,
  },
  {
    id: Locale.Es,
    label: 'Español',
    data: Locale.Es,
  },
  {
    id: Locale.Pt,
    label: 'Portuguese',
    data: Locale.Pt,
  },
  {
    id: Locale.It,
    label: 'Italiano',
    data: Locale.It,
  },
]

const UserProfile = (props: Props): JSX.Element => {
  const {
    userState: { user },
  } = props
  const { t } = useTranslation()
  const { isImperial, toggleImperial } = useMeasureSystemPreference()
  const [state, setWholeState] = React.useState<State>({
    phoneNumber: user?.phoneNumber || '',
    beNotifiedByEmail: user?.settings?.beNotifiedByEmail || false,
    beNotifiedBySMS: user?.settings?.beNotifiedBySMS || false,
    beNotifiedByPush: user?.settings?.beNotifiedByPush || false,
    notificationSubscriptions: user?.settings?.notificationSubscriptions || [],
    locale: user?.settings?.locale || Locale.En,
    resetPassword: false,
    useImperial: isImperial,
  })
  const [isDirty, setAsDirty] = React.useState(false)

  const setState = (updateFn: (state: State) => State) => {
    setWholeState(updateFn)
    setAsDirty(true)
  }

  const handleEditPasswordScreen = (resetPassword: boolean) => {
    setState((state) => ({ ...state, resetPassword }))
  }
  const updatePhoneNumber = (phoneNumber: string) => {
    setState((state) => ({ ...state, phoneNumber }))
  }

  const onSelectLocale = (locale: Locale) => {
    setState((state) => ({ ...state, locale }))
  }

  const onClickSave = () => {
    const {
      updateMe,
      userState: { user },
    } = props
    const {
      phoneNumber,
      beNotifiedByEmail,
      beNotifiedBySMS,
      beNotifiedByPush,
      notificationSubscriptions,
      locale,
      useImperial,
    } = state

    if (user) {
      const input = {
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber,
        beNotifiedByEmail,
        beNotifiedBySMS,
        beNotifiedByPush,
        notificationSubscriptions,
        locale,
      }
      updateMe(input)
    }
    toggleImperial(useImperial)
    setAsDirty(false)
  }

  const {
    userState: { updating },
    userProfileOpen,
    toggleUserProfile,
  } = props

  const { phoneNumber, beNotifiedByEmail, locale, resetPassword, useImperial } = state

  return (
    <React.Fragment>
      {userProfileOpen && window.innerWidth <= 1100 && <HideContainer onClick={toggleUserProfile} />}
      <Container open={userProfileOpen}>
        {resetPassword && (
          <EditPassword user={user} handleEditPasswordScreen={handleEditPasswordScreen} resetPassword={resetPassword} />
        )}
        {!resetPassword && user && (
          <React.Fragment>
            <BadgeContainer>
              <UserBadge user={user} />
            </BadgeContainer>
            <Select
              title={t('layout_Main_UserProfile_locale')}
              options={locales}
              selected={locale && locales.find((l) => l.id == locale)}
              onClick={(option) => {
                onSelectLocale(option.data as Locale)
              }}
              bordered
            />
            <CheckBox
              checked={useImperial}
              onClick={() => setState((state) => ({ ...state, useImperial: !useImperial }))}
              textColor="main"
              checkBoxColor="main"
              label={t('layout_Main_UserProfile_measure_system_use_imperial')}
            />
            <InputRounded disabled src={userImg} value={user.firstName} />
            <InputRounded disabled src={userImg} value={user.lastName} />
            <InputRounded disabled src={mailImg} type="email" value={user.email} />
            <PhoneInputContainer>
              <PhoneInput
                placeholder={t('layout_Main_UserProfile_phone_place_holder')}
                value={phoneNumber}
                onChange={updatePhoneNumber}
              />
            </PhoneInputContainer>
            {/* <CheckBox
                checked={beNotifiedBySMS}
                onClick={() => {
                  this.setState({ beNotifiedBySMS: !beNotifiedBySMS })
                }}
                textColor="main"
                checkBoxColor="main"
                label={t('layout_Main_UserProfile_sms')}
              /> */}
            <CheckBox
              checked={beNotifiedByEmail}
              onClick={() => {
                setState((state) => ({ ...state, beNotifiedByEmail: !beNotifiedByEmail }))
              }}
              textColor="main"
              checkBoxColor="main"
              label={t('layout_Main_UserProfile_email')}
            />
            <ButtonResetPassword
              onClick={() => setState((state) => ({ ...state, resetPassword: !state.resetPassword }))}
            >
              {t('modules_EditPassword_reset')}
            </ButtonResetPassword>
            <Button disabled={!isDirty || updating === 1} onClick={onClickSave}>
              {t('layout_Main_UserProfile_save')}
            </Button>
          </React.Fragment>
        )}
      </Container>
    </React.Fragment>
  )
}

export default withUserProfile(UserProfile)
