import { gql } from '@apollo/client'

export const IrrigationCycleFragment = gql`
  fragment IrrigationCycleFragment on IrrigationCycle {
    id
    startAt
    startHour
    numberOfHours
    numberOfMinutes
    recurrence {
      type
      endAt
      frequency
      days
    }
    sector {
      id
      name
      color
    }
    assignedId
    isIrrigationTemplateOrigin
    irrigationTemplateId
    irrigationEvents {
      id
      startDate
      endDate
      sector {
        id
      }
      irrigationCycle {
        id
        isIrrigationTemplateOrigin
        recurrence {
          type
          endAt
          frequency
          days
        }
      }
    }
    simulatedIrrigationEvents {
      id
      createdDate
      startDate
      endDate
      sector {
        id
        name
        color
      }
      irrigationCycle {
        id
        isIrrigationTemplateOrigin
      }
    }
  }
`
