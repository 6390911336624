export enum AgromoteHardwareVersion {
  'AG01.00.00.00' = 'AG01.00.00.00',
  'AG01.00.01.00' = 'AG01.00.01.00',
  'AG01.01.00.00' = 'AG01.01.00.00',
  'AG01.01.01.00' = 'AG01.01.01.00',
  'AG02.00.00.00' = 'AG02.00.00.00',
  'AG02.01.00.00' = 'AG02.01.00.00',
}

export type AgromoteHardwareVersionKeys = keyof typeof AgromoteHardwareVersion

export const AgromoteHardwareVersionArrayValues: AgromoteHardwareVersion[] = Object.values(AgromoteHardwareVersion)

export enum ManoHardwareVersion {
  'MA01.00.00.00' = 'MA01.00.00.00',
  'MA01.03.00.00' = 'MA01.03.00.00',
  'MA02.00.00.00' = 'MA02.00.00.00',
}

export type ManoHardwareVersionKeys = keyof typeof ManoHardwareVersion

export const ManoHardwareVersionArrayValues: ManoHardwareVersion[] = Object.values(ManoHardwareVersion)

export enum AdeunisPulseHardwareVersion {
  'AP01.00.00.00' = 'AP01.00.00.00',
}

export type AdeunisPulseHardwareVersionKeys = keyof typeof AdeunisPulseHardwareVersion

export const AdeunisPulseHardwareVersionArrayValues: AdeunisPulseHardwareVersion[] = Object.values(
  AdeunisPulseHardwareVersion,
)

export enum AtimPulseHardwareVersion {
  'DIND21.1.5.4' = 'DIND21.1.5.4',
}

export type AtimPulseHardwareVersionKeys = keyof typeof AtimPulseHardwareVersion

export const AtimPulseHardwareVersionArrayValues: AtimPulseHardwareVersion[] = Object.values(AtimPulseHardwareVersion)
