import { gql } from '@apollo/client'
import { ZoneSectorFragment } from '../fragments'

export const MUTATION_CREATE_SECTOR = gql`
  mutation createSector($input: SectorCreateInput!) {
    createSector(input: $input) {
      ...ZoneSectorFragment
    }
  }
  ${ZoneSectorFragment}
`

export const MUTATION_UPDATE_SECTOR = gql`
  mutation updateSector($input: SectorUpdateInput!) {
    updateSector(input: $input) {
      ...ZoneSectorFragment
    }
  }
  ${ZoneSectorFragment}
`

export const MUTATION_DELETE_SECTOR = gql`
  mutation deleteSector($id: ID!) {
    deleteSector(id: $id)
  }
`
