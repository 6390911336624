import { gql } from '@apollo/client'

import { LandFragment } from '../fragments'

export const MUTATION_CREATE_LAND = gql`
  mutation createLand($input: LandCreateInput!) {
    createLand(input: $input) {
      ...LandFragment
    }
  }
  ${LandFragment}
`

export const MUTATION_UPDATE_LAND = gql`
  mutation updateLand($input: LandUpdateInput!) {
    updateLand(input: $input) {
      ...LandFragment
    }
  }
  ${LandFragment}
`

export const MUTATION_DELETE_LAND = gql`
  mutation deleteLand($id: ID!) {
    deleteLand(id: $id)
  }
`
