import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import InputRounded from '../../components/InputRounded'
import { passwordValidationRegex, passwordValidationString } from '../../utils/auth/pwd-regex'
import cadenas from '../../assets/cadenas.png'
import { BadgeContainer } from '../../layouts/Main/UserProfile/UserProfile.styled'
import UserBadge from '../../components/UserBadge'
import ErrorIcon from '@material-ui/icons/Error'
import {
  ButtonSend,
  ButtonBack,
  ContainerPatternInfos,
  ContainerError,
  PatternInfos,
  Form,
  Label,
} from './EditPassword.styled'
import { User } from '../../types'
import { useUpdateMePassword } from '../../hooks/useUpdateUserPassword.hook.ts'
// import { updatePassword } from './helper'
// import { useUpdateUser } from '../../hooks/useUpdateUser.hook'

interface Props {
  user?: User
  handleEditPasswordScreen: (value: boolean) => void
  resetPassword: boolean
}

interface FormValues {
  oldPassword: string
  newPassword: string
  passwordConfirm: string
}

const EditPassword: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { user } = props
  const { updateMePassword } = useUpdateMePassword()

  const [userPasswordInfo, setUserPasswordInfo] = useState<FormValues>({
    oldPassword: '',
    newPassword: '',
    passwordConfirm: '',
  })
  const [isOldPasswordValid, setIsOldPasswordValid] = useState(true)
  const [isPasswordUpdated, setIsPasswordUpdated] = useState(false)

  const isPasswordScreenShow = () => {
    props.handleEditPasswordScreen(!props.resetPassword)
  }
  const isFormValid = (): boolean => {
    return (
      userPasswordInfo.newPassword === userPasswordInfo.passwordConfirm &&
      userPasswordInfo.newPassword.length !== 0 &&
      userPasswordInfo.oldPassword.length !== 0 &&
      userPasswordInfo.passwordConfirm.length !== 0 &&
      !!userPasswordInfo.newPassword.match(passwordValidationRegex)
    )
  }

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (isFormValid()) {
      const result = await updateMePassword({
        oldPassword: userPasswordInfo.oldPassword,
        newPassword: userPasswordInfo.newPassword,
      })
      if (result.data.updateMePassword) {
        setIsPasswordUpdated(true)
      } else {
        setIsOldPasswordValid(false)
      }
    }
  }

  return (
    <React.Fragment>
      {user && !isPasswordUpdated && (
        <Form onSubmit={onSubmit}>
          <BadgeContainer>
            <UserBadge user={user} />
          </BadgeContainer>
          <InputRounded
            src={cadenas}
            required
            placeholder={t('modules_EditPassword_old_password')}
            type="password"
            pattern={passwordValidationString}
            onChange={(oldPassword) => {
              setIsOldPasswordValid(true)
              setUserPasswordInfo({ ...userPasswordInfo, oldPassword: oldPassword })
            }}
            value={userPasswordInfo.oldPassword}
          />
          <InputRounded
            src={cadenas}
            required
            placeholder={t('modules_EditPassword_new_password')}
            type="password"
            pattern={passwordValidationString}
            onChange={(newPassword) => {
              setUserPasswordInfo({ ...userPasswordInfo, newPassword: newPassword })
            }}
            value={userPasswordInfo.newPassword}
          />
          <InputRounded
            src={cadenas}
            required
            placeholder={t('modules_EditPassword_confirm_new_password')}
            type="password"
            pattern={passwordValidationString}
            onChange={(confirmNewPassword) => {
              setUserPasswordInfo({ ...userPasswordInfo, passwordConfirm: confirmNewPassword })
            }}
            value={userPasswordInfo.passwordConfirm}
          />
          <ContainerPatternInfos>
            <ErrorIcon />
            <PatternInfos>{t('modules_ResetPassword_password_error')}</PatternInfos>
          </ContainerPatternInfos>
          {!isOldPasswordValid && (
            <ContainerError>
              <ErrorIcon />
              <PatternInfos>{t('modules_EditPassword_error')}</PatternInfos>
            </ContainerError>
          )}
          <ButtonSend type="submit" disabled={!isFormValid()}>
            {t('layout_Main_UserProfile_save')}
          </ButtonSend>
          <ButtonBack type="button" onClick={isPasswordScreenShow}>
            {t('components_Dialog_cancel')}
          </ButtonBack>
        </Form>
      )}
      {user && isPasswordUpdated && (
        <Form>
          <BadgeContainer>
            <UserBadge user={user} />
          </BadgeContainer>
          <Label>{t('modules_ResetPassword_infos')}</Label>
        </Form>
      )}
    </React.Fragment>
  )
}

export default EditPassword
