import { gql } from '@apollo/client'

import { LabelFragment } from '../fragments'

export const LABELS = gql`
  query labels($input: LabelFindInput!) {
    labels(input: $input) {
      ...LabelFragment
    }
  }
  ${LabelFragment}
`
