import { gql } from '@apollo/client'

import { IrrigationFragment, IrrigationCalendarFragment } from '../fragments'

export const IRRIGATIONS_FOR_RANGE = gql`
  query irrigationSystemIrrigationsRange(
    $irrigationSystemId: ID!
    $sectorId: String
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    irrigationSystem(id: $irrigationSystemId) {
      id
      irrigationsForRange(filters: { sectorId: $sectorId }, range: { startDate: $startDate, endDate: $endDate }) {
        ...IrrigationFragment
      }
    }
  }
  ${IrrigationFragment}
`

export const IRRIGATIONS_FOR_RANGE_CALENDAR = gql`
  query irrigationSystemIrrigationsRange(
    $irrigationSystemId: ID!
    $sectorId: String
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    irrigationSystem(id: $irrigationSystemId) {
      id
      irrigationsForRange(filters: { sectorId: $sectorId }, range: { startDate: $startDate, endDate: $endDate }) {
        ...IrrigationFragment
      }
    }
  }
  ${IrrigationCalendarFragment}
`
