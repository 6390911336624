import { gql } from '@apollo/client'

import { EquipmentTensiometerMeasureFragment } from './equipment_tensiometer_measure.fragment'
import { PageInfoFragment } from './page_info.fragment'
import { EquipmentTensiometerAlertFragment } from './equipment_tensiometer_alert.fragment'

export const EquipmentTensiometerFragment = gql`
  fragment EquipmentTensiometerFragment on Tensiometer {
    id
    name
    unit
    thresholdMin
    thresholdMax
    thresholdBased
    physicalTensiometer {
      id
      installDate
      brand
      model
      depthUnit
      depth
    }
    lastMeasure {
      ...EquipmentTensiometerMeasureFragment
    }
    alerts(pagination: { first: 20 }) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...EquipmentTensiometerAlertFragment
        }
      }
    }
  }
  ${EquipmentTensiometerMeasureFragment}
  ${PageInfoFragment}
  ${EquipmentTensiometerAlertFragment}
`
