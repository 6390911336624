import { SignOutAction } from '../user/actionTypes'

export const SEARCH_SET_USERS_SEARCH_QUERY = '@@search/SET_USERS_SEARCH_QUERY'

export interface SetUsersSearchQuery {
  type: typeof SEARCH_SET_USERS_SEARCH_QUERY
  query: string
}

export const SEARCH_SET_LANDS_SEARCH_QUERY = '@@search/SET_LANDS_SEARCH_QUERY'

export interface SetLandsSearchQuery {
  type: typeof SEARCH_SET_LANDS_SEARCH_QUERY
  query: string
}

export const SEARCH_SET_IRRIGATION_SYTEMS_SEARCH_QUERY = '@@search/SET_IRRIGATION_SYTEMS_SEARCH_QUERY'

export interface SetIrrigationSystemsSearchQuery {
  type: typeof SEARCH_SET_IRRIGATION_SYTEMS_SEARCH_QUERY
  query: string
}

export type AppActionTypes = SignOutAction | SetUsersSearchQuery | SetLandsSearchQuery | SetIrrigationSystemsSearchQuery
