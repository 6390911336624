import React from 'react'
import { Route, RouteComponentProps, Switch, Redirect, withRouter } from 'react-router-dom'
import loadable from '@loadable/component'

const AsyncHome = loadable(() => import('../../modules/Home'))
const AsyncDashboard = loadable(() => import('../../modules/Dashboard'))
const AsyncMeasurement = loadable(() => import('../../modules/Measurement'))
const AsyncPlanning = loadable(() => import('../../modules/PlanningV1'))
const AsyncPlanningv2 = loadable(() => import('../../modules/Planningv2'))
const AsyncAlert = loadable(() => import('../../modules/Alert'))
const AsyncAdmin = loadable(() => import('../../modules/Admin'))
const AsyncInstallation = loadable(() => import('../../modules/Installation'))

const Routing = (props: RouteComponentProps): JSX.Element => {
  const { match } = props

  return (
    <Switch>
      <Route path={`${match.path}/home`} component={AsyncHome} />
      <Route path={`${match.path}/dashboard`} component={AsyncDashboard} />
      <Route path={`${match.path}/measurement`} component={AsyncMeasurement} />
      <Route path={`${match.path}/beta/planning`} component={AsyncPlanningv2} />
      <Route path={`${match.path}/planning`} component={AsyncPlanning} />
      <Route path={`${match.path}/alert`} component={AsyncAlert} />
      <Route path={`${match.path}/admin`} component={AsyncAdmin} />
      <Route path={`${match.path}/installation`} component={AsyncInstallation} />
      <Redirect to={`${match.path}/home`} />
    </Switch>
  )
}

export default withRouter(Routing)
