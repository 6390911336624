import { dispatchToast, ToastArgs } from '../../components/Toast'

export const dispatchToastUpdatingConfiguration = (
  type: 'update' | 'success' | 'error',
  toastIdentifier: string,
): void => {
  const detail: ToastArgs = {
    message:
      type === 'update'
        ? 'modules_Installation_Toast_saving'
        : type === 'success'
        ? 'modules_Installation_Toast_saved'
        : 'modules_Installation_Toast_error',
    fixed: type === 'update' ? true : false,
    type: type === 'update' ? 'info' : type === 'success' ? 'success' : 'warning',
    identifier: toastIdentifier,
  }
  return dispatchToast(detail)
}
