/* eslint-disable */
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any
}

export type Country = {
  __typename?: 'Country'
  alpha2Code: Scalars['ID']
  alpha3Code: Scalars['ID']
  name: Scalars['String']
}

export type Address = {
  __typename?: 'Address'
  id: Scalars['ID']
  street: Scalars['String']
  city: Scalars['String']
  zipCode: Scalars['String']
  country: Country
}

export type PageInfo = {
  __typename?: 'PageInfo'
  startCursor?: Maybe<Scalars['ID']>
  endCursor?: Maybe<Scalars['ID']>
  hasPreviousPage: Scalars['Boolean']
  hasNextPage: Scalars['Boolean']
}

export type Label = {
  __typename?: 'Label'
  id: Scalars['ID']
  name: Scalars['String']
  createdDate: Scalars['DateTime']
  updatedDate: Scalars['DateTime']
  deletedDate?: Maybe<Scalars['DateTime']>
  companyId: Scalars['ID']
  company: Company
  type: LabelType
  parentLabelId?: Maybe<Scalars['ID']>
  parent?: Maybe<Label>
  children?: Maybe<Array<Label>>
}

export enum LabelType {
  Folder = 'FOLDER',
  Tag = 'TAG',
}

export type EdgeLabel = {
  __typename?: 'EdgeLabel'
  cursor?: Maybe<Scalars['ID']>
  node: Label
}

export type Company = {
  __typename?: 'Company'
  id: Scalars['ID']
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  contact?: Maybe<User>
  address?: Maybe<Address>
  allowInstantIrrigation: Scalars['Boolean']
  allowWaterConsumptionReport: Scalars['Boolean']
  isDemo: Scalars['Boolean']
  isDemoValid: Scalars['Boolean']
  labels?: Maybe<Array<Label>>
  allowExperimentalPlanning: Scalars['Boolean']
}

export type EdgeCompany = {
  __typename?: 'EdgeCompany'
  cursor?: Maybe<Scalars['ID']>
  node: Company
}

export type PaginatedCompany = {
  __typename?: 'PaginatedCompany'
  edges: Array<EdgeCompany>
  pageInfo: PageInfo
}

export type UserSettings = {
  __typename?: 'UserSettings'
  id: Scalars['ID']
  locale: Locale
  beNotifiedByEmail: Scalars['Boolean']
  beNotifiedBySMS: Scalars['Boolean']
  beNotifiedByPush: Scalars['Boolean']
  notificationSubscriptions: Array<Scalars['String']>
}

export enum Locale {
  En = 'en',
  Fr = 'fr',
  Es = 'es',
  Pt = 'pt',
  It = 'it',
}

export type User = {
  __typename?: 'User'
  id: Scalars['ID']
  createdDate: Scalars['DateTime']
  updatedDate: Scalars['DateTime']
  firstName: Scalars['String']
  lastName: Scalars['String']
  email: Scalars['String']
  phoneNumber?: Maybe<Scalars['String']>
  roles: Array<UserRole>
  company?: Maybe<Company>
  settings?: Maybe<UserSettings>
}

export enum UserRole {
  RoleUser = 'ROLE_USER',
  RoleTelaquaAdmin = 'ROLE_TELAQUA_ADMIN',
  RoleClientAdmin = 'ROLE_CLIENT_ADMIN',
  RoleClientEmployee = 'ROLE_CLIENT_EMPLOYEE',
}

export type AuthPayload = {
  __typename?: 'AuthPayload'
  accessToken: Scalars['String']
  refreshToken: Scalars['String']
  user: User
}

export type Device = {
  __typename?: 'Device'
  id: Scalars['ID']
  name: Scalars['String']
  devEui: Scalars['String']
  serialNumber?: Maybe<Scalars['String']>
  ttnId?: Maybe<Scalars['String']>
  appEui: Scalars['String']
  type: DeviceType
  hardwareVersion: Scalars['String']
  firmwareVersion?: Maybe<Scalars['String']>
  properties?: Maybe<DeviceProperties>
  company?: Maybe<Company>
  lastUplinkDate?: Maybe<Scalars['DateTime']>
  hasProtectingCase?: Maybe<Scalars['Boolean']>
  isHibernating?: Maybe<Scalars['Boolean']>
  saleDistributionChannel?: Maybe<Scalars['String']>
  isConnected: Scalars['Boolean']
  inputs: Array<DeviceInput>
}

export enum DeviceType {
  Agromote = 'Agromote',
  Mano = 'Mano',
  SensorData = 'SensorData',
  AdeunisPulse = 'AdeunisPulse',
  AtimPulse = 'AtimPulse',
}

export type DeviceProperties = ManoProperties

export type ManoProperties = {
  __typename?: 'ManoProperties'
  barType: Scalars['Float']
  transducerId?: Maybe<Scalars['String']>
}

export type DeviceModel = {
  __typename?: 'DeviceModel'
  type: DeviceType
  hardwareVersions: Array<Scalars['String']>
}

export type DeviceInput = {
  __typename?: 'DeviceInput'
  name: Scalars['String']
  displayName: Scalars['String']
  type: DeviceInputType
}

export enum DeviceInputType {
  Pressure = 'PRESSURE',
  Latch = 'LATCH',
  Imp = 'IMP',
  Sdi12 = 'SDI12',
}

export type Installation = {
  __typename?: 'Installation'
  installDate: Scalars['DateTime']
  device: Device
  input: DeviceInput
}

export type Location = {
  __typename?: 'Location'
  lon: Scalars['Float']
  lat: Scalars['Float']
}

export type PhysicalManometer = {
  __typename?: 'PhysicalManometer'
  id: Scalars['ID']
  installDate: Scalars['DateTime']
  uninstallDate?: Maybe<Scalars['DateTime']>
  brand: Scalars['String']
  model: Scalars['String']
}

export type Manometer = {
  __typename?: 'Manometer'
  id: Scalars['ID']
  name: Scalars['String']
  unit: ManometerUnitMeasure
  thresholdMin: Scalars['Float']
  thresholdMax: Scalars['Float']
  thresholdBased: Scalars['Float']
  physicalManometer?: Maybe<PhysicalManometer>
  location?: Maybe<Location>
  lastMeasure?: Maybe<ManometerMeasure>
  measures?: Maybe<Array<ManometerMeasure>>
  installation?: Maybe<Installation>
  irrigations: PaginatedManometerIrrigation
  onGoingAlert?: Maybe<ManometerAlert>
  alerts: PaginatedManometerAlert
  valve?: Maybe<Valve>
  stitchingPoint?: Maybe<StitchingPoint>
  pump?: Maybe<Pump>
  waterInlet?: Maybe<WaterInlet>
}

export type ManometerMeasuresArgs = {
  groupByTimeForRange?: Maybe<Scalars['Boolean']>
  endDate: Scalars['DateTime']
  startDate: Scalars['DateTime']
}

export type ManometerIrrigationsArgs = {
  pagination: PaginationInput
}

export type ManometerAlertsArgs = {
  pagination: PaginationInput
}

export enum ManometerUnitMeasure {
  Bar = 'BAR',
  Psi = 'PSI',
}

export type PaginationInput = {
  after?: Maybe<Scalars['ID']>
  first: Scalars['Float']
}

export type ManometerMeasure = {
  __typename?: 'ManometerMeasure'
  timestamp: Scalars['DateTime']
  value: Scalars['Float']
}

export type ManometerIrrigation = {
  __typename?: 'ManometerIrrigation'
  id: Scalars['ID']
  startAt: Scalars['DateTime']
  valueAtStart: Scalars['Float']
  endAt?: Maybe<Scalars['DateTime']>
  valueAtEnd?: Maybe<Scalars['Float']>
  status: ManometerIrrigationStatus
}

export enum ManometerIrrigationStatus {
  Start = 'START',
  InProgress = 'IN_PROGRESS',
  Stop = 'STOP',
}

export type EdgeManometerIrrigation = {
  __typename?: 'EdgeManometerIrrigation'
  cursor?: Maybe<Scalars['ID']>
  node: ManometerIrrigation
}

export type PaginatedManometerIrrigation = {
  __typename?: 'PaginatedManometerIrrigation'
  edges: Array<EdgeManometerIrrigation>
  pageInfo: PageInfo
}

export type ManometerAlert = {
  __typename?: 'ManometerAlert'
  id: Scalars['ID']
  startAt: Scalars['DateTime']
  endAt?: Maybe<Scalars['DateTime']>
  status: AlertStatus
  archivedAt?: Maybe<Scalars['DateTime']>
  archivedBy?: Maybe<User>
  note?: Maybe<Scalars['String']>
  valueAtStart: Scalars['Float']
  averageAtStart: Scalars['Float']
  valueAtEnd?: Maybe<Scalars['Float']>
  averageAtEnd?: Maybe<Scalars['Float']>
  type: ManometerAlertType
}

export enum AlertStatus {
  Start = 'START',
  InProgress = 'IN_PROGRESS',
  Stop = 'STOP',
}

export enum ManometerAlertType {
  ThresholdMaxCrossed = 'THRESHOLD_MAX_CROSSED',
  ThresholdMinCrossed = 'THRESHOLD_MIN_CROSSED',
}

export type EdgeManometerAlert = {
  __typename?: 'EdgeManometerAlert'
  cursor?: Maybe<Scalars['ID']>
  node: ManometerAlert
}

export type PaginatedManometerAlert = {
  __typename?: 'PaginatedManometerAlert'
  edges: Array<EdgeManometerAlert>
  pageInfo: PageInfo
}

export type DeviceInstallation = {
  __typename?: 'DeviceInstallation'
  installDate: Scalars['DateTime']
}

export type DeviceManometerInstallation = {
  __typename?: 'DeviceManometerInstallation'
  installDate: Scalars['DateTime']
  maxPressure: Scalars['Float']
}

export type DeviceTensiometerInstallation = {
  __typename?: 'DeviceTensiometerInstallation'
  installDate: Scalars['DateTime']
  maxPressure: Scalars['Float']
}

export type DeviceFilterInstallation = {
  __typename?: 'DeviceFilterInstallation'
  installDate: Scalars['DateTime']
  filterEntry: Scalars['String']
  maxPressure: Scalars['Float']
}

export type DeviceWaterMeterInstallation = {
  __typename?: 'DeviceWaterMeterInstallation'
  installDate: Scalars['DateTime']
  pulsePerLiter: Scalars['Float']
  defaultTotalLiter?: Maybe<Scalars['Float']>
}

export type DeviceManometer = {
  __typename?: 'DeviceManometer'
  object: Manometer
  installation: DeviceManometerInstallation
}

export type PhysicalTensiometer = {
  __typename?: 'PhysicalTensiometer'
  id: Scalars['ID']
  installDate: Scalars['DateTime']
  uninstallDate?: Maybe<Scalars['DateTime']>
  brand: Scalars['String']
  model: Scalars['String']
  depthUnit: Scalars['String']
  depth: Scalars['Float']
}

export type Tensiometer = {
  __typename?: 'Tensiometer'
  id: Scalars['ID']
  name: Scalars['String']
  unit: TensiometerUnitMeasure
  thresholdMin: Scalars['Float']
  thresholdMax: Scalars['Float']
  /** @deprecated Never been used */
  thresholdBased: Scalars['Float']
  physicalTensiometer?: Maybe<PhysicalTensiometer>
  location?: Maybe<Location>
  lastMeasure?: Maybe<TensiometerMeasure>
  measures?: Maybe<Array<TensiometerMeasure>>
  installation?: Maybe<Installation>
  onGoingAlert?: Maybe<TensiometerAlert>
  alerts: PaginatedTensiometerAlert
  valve?: Maybe<Valve>
  stitchingPoint?: Maybe<StitchingPoint>
  pump?: Maybe<Pump>
  waterInlet?: Maybe<WaterInlet>
}

export type TensiometerMeasuresArgs = {
  endDate: Scalars['DateTime']
  startDate: Scalars['DateTime']
}

export type TensiometerAlertsArgs = {
  pagination: PaginationInput
}

export enum TensiometerUnitMeasure {
  Cbar = 'CBAR',
  Bar = 'BAR',
  Psi = 'PSI',
}

export type TensiometerMeasure = {
  __typename?: 'TensiometerMeasure'
  timestamp: Scalars['DateTime']
  value: Scalars['Float']
}

export type TensiometerAlert = {
  __typename?: 'TensiometerAlert'
  id: Scalars['ID']
  startAt: Scalars['DateTime']
  endAt?: Maybe<Scalars['DateTime']>
  status: AlertStatus
  archivedAt?: Maybe<Scalars['DateTime']>
  archivedBy?: Maybe<User>
  note?: Maybe<Scalars['String']>
  valueAtStart: Scalars['Float']
  averageAtStart: Scalars['Float']
  valueAtEnd?: Maybe<Scalars['Float']>
  averageAtEnd?: Maybe<Scalars['Float']>
  type: TensiometerAlertType
}

export enum TensiometerAlertType {
  ThresholdMaxCrossed = 'THRESHOLD_MAX_CROSSED',
  ThresholdMinCrossed = 'THRESHOLD_MIN_CROSSED',
}

export type EdgeTensiometerAlert = {
  __typename?: 'EdgeTensiometerAlert'
  cursor?: Maybe<Scalars['ID']>
  node: TensiometerAlert
}

export type PaginatedTensiometerAlert = {
  __typename?: 'PaginatedTensiometerAlert'
  edges: Array<EdgeTensiometerAlert>
  pageInfo: PageInfo
}

export type DeviceTensiometer = {
  __typename?: 'DeviceTensiometer'
  object: Tensiometer
  installation: DeviceTensiometerInstallation
}

export type PhysicalFilter = {
  __typename?: 'PhysicalFilter'
  id: Scalars['ID']
  installDate: Scalars['DateTime']
  uninstallDate?: Maybe<Scalars['DateTime']>
  brand: Scalars['String']
  model: Scalars['String']
  retroAutomaticWashing?: Maybe<Scalars['Boolean']>
}

export type Filter = {
  __typename?: 'Filter'
  id: Scalars['ID']
  name: Scalars['String']
  unit: FilterUnitMeasure
  thresholdPressureDrop: Scalars['Float']
  physicalFilter?: Maybe<PhysicalFilter>
  location?: Maybe<Location>
  lastMeasure?: Maybe<FilterMeasure>
  measures?: Maybe<Array<FilterMeasure>>
  installationIn?: Maybe<Installation>
  installationOut?: Maybe<Installation>
  onGoingAlert?: Maybe<FilterAlert>
  alerts: PaginatedFilterAlert
  valve?: Maybe<Valve>
  stitchingPoint?: Maybe<StitchingPoint>
  pump?: Maybe<Pump>
  waterInlet?: Maybe<WaterInlet>
}

export type FilterMeasuresArgs = {
  endDate: Scalars['DateTime']
  startDate: Scalars['DateTime']
}

export type FilterAlertsArgs = {
  pagination: PaginationInput
}

export enum FilterUnitMeasure {
  Bar = 'BAR',
  Psi = 'PSI',
}

export type FilterMeasure = {
  __typename?: 'FilterMeasure'
  timestamp: Scalars['DateTime']
  pressureIn: Scalars['Float']
  pressureOut: Scalars['Float']
  pressureDrop: Scalars['Float']
}

export type FilterAlert = {
  __typename?: 'FilterAlert'
  id: Scalars['ID']
  startAt: Scalars['DateTime']
  endAt?: Maybe<Scalars['DateTime']>
  status: AlertStatus
  archivedAt?: Maybe<Scalars['DateTime']>
  archivedBy?: Maybe<User>
  note?: Maybe<Scalars['String']>
  valueAtStart: Scalars['Float']
  averageAtStart: Scalars['Float']
  valueAtEnd?: Maybe<Scalars['Float']>
  averageAtEnd?: Maybe<Scalars['Float']>
  type: FilterAlertType
}

export enum FilterAlertType {
  ThresholdPressureDropCrossed = 'THRESHOLD_PRESSURE_DROP_CROSSED',
}

export type EdgeFilterAlert = {
  __typename?: 'EdgeFilterAlert'
  cursor?: Maybe<Scalars['ID']>
  node: FilterAlert
}

export type PaginatedFilterAlert = {
  __typename?: 'PaginatedFilterAlert'
  edges: Array<EdgeFilterAlert>
  pageInfo: PageInfo
}

export type DeviceFilter = {
  __typename?: 'DeviceFilter'
  object: Filter
  installation: DeviceFilterInstallation
}

export type PhysicalWaterMeter = {
  __typename?: 'PhysicalWaterMeter'
  id: Scalars['ID']
  installDate: Scalars['DateTime']
  uninstallDate?: Maybe<Scalars['DateTime']>
  brand: Scalars['String']
  model: Scalars['String']
  pulsePerLiter: Scalars['Int']
  defaultTotalLiter: Scalars['Float']
  pulseOutput?: Maybe<Scalars['Boolean']>
  connected?: Maybe<Scalars['Boolean']>
}

export type WaterMeter = {
  __typename?: 'WaterMeter'
  id: Scalars['ID']
  name: Scalars['String']
  unit: Scalars['String']
  physicalWaterMeter?: Maybe<PhysicalWaterMeter>
  location?: Maybe<Location>
  lastMeasure?: Maybe<WaterMeterMeasure>
  measures?: Maybe<Array<WaterMeterMeasure>>
  volumeMeasures?: Maybe<Array<WaterMeterVolumeMeasure>>
  flowMeasures?: Maybe<Array<WaterMeterFlowMeasure>>
  installation?: Maybe<Installation>
  sectors?: Maybe<Array<Sector>>
  valve?: Maybe<Valve>
  stitchingPoint?: Maybe<StitchingPoint>
  pump?: Maybe<Pump>
  waterInlet?: Maybe<WaterInlet>
}

export type WaterMeterMeasuresArgs = {
  endDate: Scalars['DateTime']
  startDate: Scalars['DateTime']
}

export type WaterMeterVolumeMeasuresArgs = {
  endDate: Scalars['DateTime']
  startDate: Scalars['DateTime']
}

export type WaterMeterFlowMeasuresArgs = {
  endDate: Scalars['DateTime']
  startDate: Scalars['DateTime']
}

export type WaterMeterMeasure = {
  __typename?: 'WaterMeterMeasure'
  timestamp: Scalars['DateTime']
  totalLiter: Scalars['Float']
  flow: Scalars['Float']
}

export type WaterMeterVolumeMeasure = {
  __typename?: 'WaterMeterVolumeMeasure'
  timestamp: Scalars['DateTime']
  totalLiter: Scalars['Float']
}

export type WaterMeterFlowMeasure = {
  __typename?: 'WaterMeterFlowMeasure'
  timestamp: Scalars['DateTime']
  flow: Scalars['Float']
}

export type DeviceWaterMeter = {
  __typename?: 'DeviceWaterMeter'
  object: WaterMeter
  installation: DeviceWaterMeterInstallation
}

export type CapacitiveProbeThreshold = {
  __typename?: 'CapacitiveProbeThreshold'
  depth: Scalars['Float']
  thresholdMin?: Maybe<Scalars['Float']>
  thresholdMax?: Maybe<Scalars['Float']>
}

export type PhysicalCapacitiveProbe = {
  __typename?: 'PhysicalCapacitiveProbe'
  id: Scalars['ID']
  installDate: Scalars['DateTime']
  uninstallDate?: Maybe<Scalars['DateTime']>
  brand: Scalars['String']
  model: Scalars['String']
  depths: Array<Scalars['Int']>
  aiAutomaticIrrigationDepths?: Maybe<Array<Scalars['Float']>>
}

export type CapacitiveProbe = {
  __typename?: 'CapacitiveProbe'
  id: Scalars['ID']
  name: Scalars['String']
  rZone: Scalars['Float']
  humidityUnit: Scalars['String']
  physicalCapacitiveProbe?: Maybe<PhysicalCapacitiveProbe>
  location?: Maybe<Location>
  thresholds?: Maybe<Array<CapacitiveProbeThreshold>>
  lastMeasure?: Maybe<CapacitiveProbeMeasure>
  measures?: Maybe<Array<CapacitiveProbeMeasure>>
  valve?: Maybe<Valve>
  stitchingPoint?: Maybe<StitchingPoint>
  pump?: Maybe<Pump>
  waterInlet?: Maybe<WaterInlet>
}

export type CapacitiveProbeMeasuresArgs = {
  endDate: Scalars['DateTime']
  startDate: Scalars['DateTime']
}

export type CapacitiveProbeDepthValue = {
  __typename?: 'CapacitiveProbeDepthValue'
  depth: Scalars['String']
  humidity: Scalars['Float']
  temperature: Scalars['Float']
}

export type CapacitiveProbeMeasure = {
  __typename?: 'CapacitiveProbeMeasure'
  timestamp: Scalars['DateTime']
  /** @deprecated Use `averageHumidity` instead */
  average: Scalars['Float']
  averageHumidity: Scalars['Float']
  averageTemperature: Scalars['Float']
  values: Array<CapacitiveProbeDepthValue>
}

export type DeviceCapacitiveProbe = {
  __typename?: 'DeviceCapacitiveProbe'
  object: CapacitiveProbe
  installation: DeviceInstallation
}

export type DeviceEquipment = {
  __typename?: 'DeviceEquipment'
  manometer?: Maybe<DeviceManometer>
  tensiometer?: Maybe<DeviceTensiometer>
  filter?: Maybe<DeviceFilter>
  waterMeter?: Maybe<DeviceWaterMeter>
  capacitiveProbe?: Maybe<DeviceCapacitiveProbe>
}

export type PhysicalPump = {
  __typename?: 'PhysicalPump'
  id: Scalars['ID']
  installDate: Scalars['DateTime']
  uninstallDate?: Maybe<Scalars['DateTime']>
  brand: Scalars['String']
  model: Scalars['String']
  energyType: Scalars['String']
  unit: Scalars['String']
  maxFlow: Scalars['Float']
  frequencyVariation?: Maybe<Scalars['Boolean']>
  connected?: Maybe<Scalars['Boolean']>
}

export type Pump = {
  __typename?: 'Pump'
  id: Scalars['ID']
  name: Scalars['String']
  physicalPump?: Maybe<PhysicalPump>
  location?: Maybe<Location>
  manometers: Array<Manometer>
  waterMeters: Array<WaterMeter>
  filters: Array<Filter>
  tensiometers: Array<Tensiometer>
  capacitiveProbes: Array<CapacitiveProbe>
  installation?: Maybe<Installation>
  waterSupply: WaterSupply
}

export type DevicePump = {
  __typename?: 'DevicePump'
  object: Pump
  installation: DeviceInstallation
}

export type PhysicalWaterInlet = {
  __typename?: 'PhysicalWaterInlet'
  id: Scalars['ID']
  installDate: Scalars['DateTime']
  uninstallDate?: Maybe<Scalars['DateTime']>
  brand: Scalars['String']
  model: Scalars['String']
  waterSupplier: Scalars['String']
  connected?: Maybe<Scalars['Boolean']>
}

export type WaterInlet = {
  __typename?: 'WaterInlet'
  id: Scalars['ID']
  name: Scalars['String']
  physicalWaterInlet?: Maybe<PhysicalWaterInlet>
  location?: Maybe<Location>
  manometers: Array<Manometer>
  waterMeters: Array<WaterMeter>
  filters: Array<Filter>
  tensiometers: Array<Tensiometer>
  capacitiveProbes: Array<CapacitiveProbe>
  installation?: Maybe<Installation>
  waterSupply: WaterSupply
}

export type DeviceWaterInlet = {
  __typename?: 'DeviceWaterInlet'
  object: WaterInlet
  installation: DeviceInstallation
}

export type PhysicalValve = {
  __typename?: 'PhysicalValve'
  id: Scalars['ID']
  installDate: Scalars['DateTime']
  uninstallDate?: Maybe<Scalars['DateTime']>
  brand: Scalars['String']
  model: Scalars['String']
  voltage: Scalars['Float']
  solenoid?: Maybe<Scalars['Boolean']>
  connected?: Maybe<Scalars['Boolean']>
}

export type Valve = {
  __typename?: 'Valve'
  id: Scalars['ID']
  name: Scalars['String']
  physicalValve?: Maybe<PhysicalValve>
  location?: Maybe<Location>
  manometers: Array<Manometer>
  waterMeters: Array<WaterMeter>
  filters: Array<Filter>
  tensiometers: Array<Tensiometer>
  capacitiveProbes: Array<CapacitiveProbe>
  installation?: Maybe<Installation>
  sector: Sector
}

export type DeviceValve = {
  __typename?: 'DeviceValve'
  object: Valve
  installation: DeviceInstallation
}

export type DeviceGroup = {
  __typename?: 'DeviceGroup'
  pump?: Maybe<DevicePump>
  waterInlet?: Maybe<DeviceWaterInlet>
  valve?: Maybe<DeviceValve>
}

export type DeviceInstallationInput = {
  __typename?: 'DeviceInstallationInput'
  deviceInput: DeviceInput
  group?: Maybe<DeviceGroup>
  equipment?: Maybe<DeviceEquipment>
  device: Device
}

export type StitchingPoint = {
  __typename?: 'StitchingPoint'
  id: Scalars['ID']
  name: Scalars['String']
  location?: Maybe<Location>
  manometers: Array<Manometer>
  waterMeters: Array<WaterMeter>
  filters: Array<Filter>
  tensiometers: Array<Tensiometer>
  capacitiveProbes: Array<CapacitiveProbe>
  secondaryNetwork: SecondaryNetwork
}

export type Sector = {
  __typename?: 'Sector'
  id: Scalars['ID']
  name: Scalars['String']
  color: Scalars['String']
  boundary?: Maybe<Scalars['JSONObject']>
  humidityBounds?: Maybe<Array<Scalars['Int']>>
  numberOfHectares?: Maybe<Scalars['Float']>
  cultureType?: Maybe<Scalars['String']>
  valves: Array<Valve>
  currentIrrigation?: Maybe<Irrigation>
  nextIrrigation?: Maybe<Irrigation>
  averageHumidity?: Maybe<Scalars['Int']>
  waterMeter?: Maybe<WaterMeter>
  irrigationSystem: IrrigationSystem
}

export type WaterSupply = {
  __typename?: 'WaterSupply'
  id: Scalars['ID']
  name: Scalars['String']
  pumps: Array<Pump>
  waterInlets: Array<WaterInlet>
  irrigationSystem: IrrigationSystem
}

export type SecondaryNetwork = {
  __typename?: 'SecondaryNetwork'
  id: Scalars['ID']
  name: Scalars['String']
  stitchingPoints: Array<StitchingPoint>
  irrigationSystem: IrrigationSystem
}

export type IrrigationRecurrence = {
  __typename?: 'IrrigationRecurrence'
  type: IrrigationReccurenceType
  endAt: Scalars['DateTime']
  frequency: Scalars['Int']
  days: Array<IrrigationReccurenceDay>
}

export enum IrrigationReccurenceType {
  Day = 'DAY',
  Week = 'WEEK',
}

export enum IrrigationReccurenceDay {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
}

/** Irrigation event. In V2, this will rename "IrrigationEvent" to avoid confusion. */
export type Irrigation = {
  __typename?: 'Irrigation'
  createdDate: Scalars['DateTime']
  updatedDate: Scalars['DateTime']
  id: Scalars['ID']
  startDate: Scalars['DateTime']
  endDate: Scalars['DateTime']
  safetyEndDate?: Maybe<Scalars['DateTime']>
  originalEndDate?: Maybe<Scalars['DateTime']>
  sector: Sector
  assigned?: Maybe<User>
  irrigationCycle?: Maybe<IrrigationCycle>
  deletedDate?: Maybe<Scalars['DateTime']>
}

export type IrrigationTemplate = {
  __typename?: 'IrrigationTemplate'
  id: Scalars['ID']
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  companyId: Scalars['ID']
  folderId: Scalars['ID']
  folder: Label
  irrigationCycles: Array<IrrigationCycle>
  simulatedIrrigationEvents: Array<Irrigation>
  createdDate: Scalars['DateTime']
  updatedDate: Scalars['DateTime']
  deletedDate: Scalars['DateTime']
}

export type IrrigationCycle = {
  __typename?: 'IrrigationCycle'
  id: Scalars['ID']
  sectorId: Scalars['ID']
  sector: Sector
  startAt: Scalars['DateTime']
  startHour: Scalars['String']
  numberOfHours: Scalars['Int']
  numberOfMinutes: Scalars['Int']
  recurrence?: Maybe<IrrigationRecurrence>
  assignedId?: Maybe<Scalars['ID']>
  isIrrigationTemplateOrigin: Scalars['Boolean']
  irrigationTemplateId?: Maybe<Scalars['ID']>
  irrigationEvents: Array<Irrigation>
  irrigationTemplate?: Maybe<IrrigationTemplate>
  /** This field is useful when working with Irrigation Templates. It will return simulated events which IDs starts with 12345678. */
  simulatedIrrigationEvents: Array<Irrigation>
}

export type Land = {
  __typename?: 'Land'
  id: Scalars['ID']
  name: Scalars['String']
  numberOfHectares: Scalars['Float']
  country: Country
  company: Company
  networkProvider?: Maybe<NetworkProvider>
  address?: Maybe<Address>
  irrigationSystems: Array<IrrigationSystem>
  timeZone: Scalars['String']
  hasGateway: Scalars['Boolean']
  dailyCSVEmail?: Maybe<Scalars['String']>
}

export enum NetworkProvider {
  Objenious = 'OBJENIOUS',
  TtnPublic = 'TTN_PUBLIC',
  TtnTelaqua = 'TTN_TELAQUA',
}

export type IrrigationSystem = {
  __typename?: 'IrrigationSystem'
  id: Scalars['ID']
  name: Scalars['String']
  company: Company
  land: Land
  sectors: Array<Sector>
  waterSupplies: Array<WaterSupply>
  secondaryNetworks: Array<SecondaryNetwork>
  irrigations: Array<Irrigation>
  irrigationsForRange: Array<Irrigation>
}

export type IrrigationSystemIrrigationsArgs = {
  pagination: PaginationInput
  range?: Maybe<DateRangeInput>
  filters?: Maybe<IrrigationFilterInput>
}

export type IrrigationSystemIrrigationsForRangeArgs = {
  range: DateRangeInput
  filters?: Maybe<IrrigationFilterInput>
}

export type DateRangeInput = {
  startDate: Scalars['DateTime']
  endDate: Scalars['DateTime']
}

export type IrrigationFilterInput = {
  sectorId?: Maybe<Scalars['String']>
}

export type Gateway = {
  __typename?: 'Gateway'
  id: Scalars['ID']
  name: Scalars['String']
  createdDate: Scalars['DateTime']
  updatedDate: Scalars['DateTime']
  deletedDate?: Maybe<Scalars['DateTime']>
  companyId: Scalars['ID']
  company: Company
  ttnId: Scalars['String']
  eui: Scalars['String']
  isConnected: Scalars['Boolean']
  status?: Maybe<GatewayStatus>
  lastStatusDate?: Maybe<Scalars['DateTime']>
  statusHistory?: Maybe<Array<GatewayStatusHistory>>
}

export enum GatewayStatus {
  Disconnected = 'DISCONNECTED',
  Connected = 'CONNECTED',
}

export type EdgeGateway = {
  __typename?: 'EdgeGateway'
  cursor?: Maybe<Scalars['ID']>
  node: Gateway
}

export type GatewayStatusHistory = {
  __typename?: 'GatewayStatusHistory'
  id: Scalars['ID']
  createdDate: Scalars['DateTime']
  updatedDate: Scalars['DateTime']
  deletedDate?: Maybe<Scalars['DateTime']>
  gatewayId: Scalars['ID']
  gateway: Gateway
  status: GatewayStatus
  errorDetails?: Maybe<Scalars['String']>
}

export type EdgeGatewayStatusHistory = {
  __typename?: 'EdgeGatewayStatusHistory'
  cursor?: Maybe<Scalars['ID']>
  node: GatewayStatusHistory
}

export type GeoEvent = {
  __typename?: 'GeoEvent'
  id: Scalars['ID']
  lat: Scalars['Float']
  lng: Scalars['Float']
  date: Scalars['DateTime']
  type: Scalars['String']
}

export type Query = {
  __typename?: 'Query'
  me: User
  users: Array<User>
  listRolesToCreateUser: Array<UserRole>
  listRolesToUpdateUser: Array<UserRole>
  companies: PaginatedCompany
  countries: Array<Country>
  land: Land
  lands: Array<Land>
  irrigationSystem: IrrigationSystem
  irrigationSystems: Array<IrrigationSystem>
  waterSupply: WaterSupply
  sector: Sector
  secondaryNetwork: SecondaryNetwork
  pump: Pump
  waterInlet: WaterInlet
  valve: Valve
  stitchingPoint: StitchingPoint
  manometer: Manometer
  tensiometer: Tensiometer
  waterMeter: WaterMeter
  filter: Filter
  capacitiveProbe: CapacitiveProbe
  irrigationTemplates: Array<IrrigationTemplate>
  irrigationTemplateDetails: IrrigationTemplate
  /** Returns all irrigation cycles for a specific irrigation template or company */
  irrigationCycles: Array<IrrigationCycle>
  devices: Array<Device>
  deviceInputInstallations: Array<DeviceInstallationInput>
  deviceModels: Array<DeviceModel>
  labels: Array<Label>
  gateways: Array<Gateway>
  gatewayStatusHistory: Array<GatewayStatusHistory>
}

export type QueryUsersArgs = {
  filters?: Maybe<UserFindInput>
}

export type QueryListRolesToUpdateUserArgs = {
  input: UserIdInput
}

export type QueryCompaniesArgs = {
  input?: Maybe<CompanyFindInput>
  pagination: PaginationInput
}

export type QueryCountriesArgs = {
  input?: Maybe<CountryFindInput>
}

export type QueryLandArgs = {
  id: Scalars['ID']
}

export type QueryLandsArgs = {
  filters?: Maybe<LandFindInput>
}

export type QueryIrrigationSystemArgs = {
  id: Scalars['ID']
}

export type QueryIrrigationSystemsArgs = {
  filters?: Maybe<IrrigationSystemFindInput>
}

export type QueryWaterSupplyArgs = {
  id: Scalars['ID']
}

export type QuerySectorArgs = {
  id: Scalars['ID']
}

export type QuerySecondaryNetworkArgs = {
  id: Scalars['ID']
}

export type QueryPumpArgs = {
  id: Scalars['ID']
}

export type QueryWaterInletArgs = {
  id: Scalars['ID']
}

export type QueryValveArgs = {
  id: Scalars['ID']
}

export type QueryStitchingPointArgs = {
  id: Scalars['ID']
}

export type QueryManometerArgs = {
  id: Scalars['ID']
}

export type QueryTensiometerArgs = {
  id: Scalars['ID']
}

export type QueryWaterMeterArgs = {
  id: Scalars['ID']
}

export type QueryFilterArgs = {
  id: Scalars['ID']
}

export type QueryCapacitiveProbeArgs = {
  id: Scalars['ID']
}

export type QueryIrrigationTemplatesArgs = {
  companyId: Scalars['ID']
}

export type QueryIrrigationTemplateDetailsArgs = {
  templateId: Scalars['ID']
}

export type QueryIrrigationCyclesArgs = {
  onlyOriginCycles?: Maybe<Scalars['Boolean']>
  irrigationTemplateId?: Maybe<Scalars['ID']>
}

export type QueryDevicesArgs = {
  filters?: Maybe<DeviceFindInput>
}

export type QueryDeviceInputInstallationsArgs = {
  connectedPortsOnly?: Maybe<Scalars['Boolean']>
  devEui: Scalars['ID']
}

export type QueryLabelsArgs = {
  input: LabelFindInput
}

export type QueryGatewayStatusHistoryArgs = {
  input: GatewayStatusHistoryFindInput
}

export type UserFindInput = {
  query_string?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
}

export type UserIdInput = {
  userId: Scalars['ID']
}

export type CompanyFindInput = {
  query_string?: Maybe<Scalars['String']>
}

export type CountryFindInput = {
  query_string?: Maybe<Scalars['String']>
}

export type LandFindInput = {
  query_string?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
}

export type IrrigationSystemFindInput = {
  query_string?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  landId?: Maybe<Scalars['String']>
}

export type DeviceFindInput = {
  query_string?: Maybe<Scalars['String']>
  type?: Maybe<DeviceType>
  companyId?: Maybe<Scalars['String']>
}

export type LabelFindInput = {
  companyId: Scalars['String']
}

export type GatewayStatusHistoryFindInput = {
  gatewayId: Scalars['String']
}

export type Mutation = {
  __typename?: 'Mutation'
  signUp: AuthPayload
  signIn: AuthPayload
  signOut: Scalars['Boolean']
  updateMe: User
  updateMeEmail: User
  createUser: User
  updateUser: User
  deleteUser: Scalars['Boolean']
  updatePassword: Scalars['Boolean']
  forgotPassword: Scalars['Boolean']
  resetPassword: Scalars['Boolean']
  createCompany: Company
  updateCompany: Company
  deleteCompany: Scalars['Boolean']
  createLand: Land
  updateLand: Land
  deleteLand: Scalars['Boolean']
  createIrrigationSystem: IrrigationSystem
  updateIrrigationSystem: IrrigationSystem
  deleteIrrigationSystem: Scalars['Boolean']
  /** This will be deprecated in V2. Prefer ui.irrigation-cycle.createIrrigationCycleV2. */
  createIrrigationCycle: Array<Irrigation>
  updateIrrigationEvent: Irrigation
  deleteIrrigationEvent: Scalars['ID']
  /** Simulates the events which would be created for an Irrigation Cycle. This does not create events, but is useful for validating your Cycle input. */
  validateIrrigationCycle: Array<Irrigation>
  createWaterSupply: WaterSupply
  updateWaterSupply: WaterSupply
  deleteWaterSupply: Scalars['Boolean']
  createSector: Sector
  updateSector: Sector
  updateSectorBoundary: Sector
  updateSectorHumidityBounds: Sector
  deleteSector: Scalars['Boolean']
  createSecondaryNetwork: SecondaryNetwork
  updateSecondaryNetwork: SecondaryNetwork
  deleteSecondaryNetwork: Scalars['Boolean']
  createPump: Pump
  updatePump: Pump
  deletePump: Scalars['Boolean']
  createWaterInlet: WaterInlet
  updateWaterInlet: WaterInlet
  deleteWaterInlet: Scalars['Boolean']
  createValve: Valve
  updateValve: Valve
  deleteValve: Scalars['Boolean']
  createStitchingPoint: StitchingPoint
  updateStitchingPoint: StitchingPoint
  deleteStitchingPoint: Scalars['Boolean']
  createManometer: Manometer
  updateManometer: Manometer
  deleteManometer: Scalars['Boolean']
  createTensiometer: Tensiometer
  updateTensiometer: Tensiometer
  deleteTensiometer: Scalars['Boolean']
  createWaterMeter: WaterMeter
  updateWaterMeter: WaterMeter
  deleteWaterMeter: Scalars['Boolean']
  createFilter: Filter
  updateFilter: Filter
  deleteFilter: Scalars['Boolean']
  createCapacitiveProbe: CapacitiveProbe
  updateCapacitiveProbe: CapacitiveProbe
  deleteCapacitiveProbe: Scalars['Boolean']
  updateGroupLocation: Scalars['Boolean']
  updateEquipmentLocation: Scalars['Boolean']
  createIrrigationTemplate: IrrigationTemplate
  updateIrrigationTemplate: IrrigationTemplate
  startIrrigationTemplate: IrrigationTemplate
  stopIrrigationTemplate: IrrigationTemplate
  deleteIrrigationTemplate: Scalars['ID']
  /** Create an irrigation cycle. This is V2 function. As this is a breaking change, it will be renamed once ui.irrigation-event.createIrrigationCycle will not be in use anymore. */
  createIrrigationCycleV2: IrrigationCycle
  updateIrrigationCycle: IrrigationCycle
  deleteIrrigationCycle: Scalars['ID']
  createDevice: Device
  createDevices: Scalars['Boolean']
  updateDevice: Device
  configureDevice: Array<DeviceInstallationInput>
  registerDevice: Device
  uninstallDevice: Array<DeviceInstallationInput>
  testDeviceRegistration: Scalars['Boolean']
  createOrUpdateUserExpoPushToken: Scalars['Boolean']
  createLabel: Label
  updateLabel: Label
  createGateway: Gateway
  updateGateway: Gateway
}

export type MutationSignUpArgs = {
  input: UserRegisterInput
}

export type MutationSignInArgs = {
  input: UserSignInInput
}

export type MutationSignOutArgs = {
  input: UserSignOutInput
}

export type MutationUpdateMeArgs = {
  input: MeUpdateInput
}

export type MutationUpdateMeEmailArgs = {
  input: MeEmailUpdateInput
}

export type MutationCreateUserArgs = {
  input: UserCreateInput
}

export type MutationUpdateUserArgs = {
  input: UserUpdateInput
}

export type MutationDeleteUserArgs = {
  id: Scalars['ID']
}

export type MutationUpdatePasswordArgs = {
  input: UpdatePasswordInput
}

export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput
}

export type MutationResetPasswordArgs = {
  input: ResetPasswordInput
}

export type MutationCreateCompanyArgs = {
  input: CompanyCreateInput
}

export type MutationUpdateCompanyArgs = {
  input: CompanyUpdateInput
}

export type MutationDeleteCompanyArgs = {
  id: Scalars['ID']
}

export type MutationCreateLandArgs = {
  input: LandCreateInput
}

export type MutationUpdateLandArgs = {
  input: LandUpdateInput
}

export type MutationDeleteLandArgs = {
  id: Scalars['ID']
}

export type MutationCreateIrrigationSystemArgs = {
  input: IrrigationSystemCreateInput
}

export type MutationUpdateIrrigationSystemArgs = {
  input: IrrigationSystemUpdateInput
}

export type MutationDeleteIrrigationSystemArgs = {
  id: Scalars['ID']
}

export type MutationCreateIrrigationCycleArgs = {
  input: IrrigationCycleCreateInput
}

export type MutationUpdateIrrigationEventArgs = {
  input: IrrigationEventUpdateInput
}

export type MutationDeleteIrrigationEventArgs = {
  id: Scalars['ID']
}

export type MutationValidateIrrigationCycleArgs = {
  input: IrrigationCycleSimulateInput
}

export type MutationCreateWaterSupplyArgs = {
  input: WaterSupplyCreateInput
}

export type MutationUpdateWaterSupplyArgs = {
  input: WaterSupplyUpdateInput
}

export type MutationDeleteWaterSupplyArgs = {
  id: Scalars['ID']
}

export type MutationCreateSectorArgs = {
  input: SectorCreateInput
}

export type MutationUpdateSectorArgs = {
  input: SectorUpdateInput
}

export type MutationUpdateSectorBoundaryArgs = {
  input: SectorUpdateBoundaryInput
}

export type MutationUpdateSectorHumidityBoundsArgs = {
  input: SectorUpdateHumidityBoundsInput
}

export type MutationDeleteSectorArgs = {
  id: Scalars['ID']
}

export type MutationCreateSecondaryNetworkArgs = {
  input: SecondaryNetworkCreateInput
}

export type MutationUpdateSecondaryNetworkArgs = {
  input: SecondaryNetworkUpdateInput
}

export type MutationDeleteSecondaryNetworkArgs = {
  id: Scalars['ID']
}

export type MutationCreatePumpArgs = {
  input: PumpCreateInput
}

export type MutationUpdatePumpArgs = {
  input: PumpUpdateInput
}

export type MutationDeletePumpArgs = {
  id: Scalars['ID']
}

export type MutationCreateWaterInletArgs = {
  input: WaterInletCreateInput
}

export type MutationUpdateWaterInletArgs = {
  input: WaterInletUpdateInput
}

export type MutationDeleteWaterInletArgs = {
  id: Scalars['ID']
}

export type MutationCreateValveArgs = {
  input: ValveCreateInput
}

export type MutationUpdateValveArgs = {
  input: ValveUpdateInput
}

export type MutationDeleteValveArgs = {
  id: Scalars['ID']
}

export type MutationCreateStitchingPointArgs = {
  input: StitchingPointCreateInput
}

export type MutationUpdateStitchingPointArgs = {
  input: StitchingPointUpdateInput
}

export type MutationDeleteStitchingPointArgs = {
  id: Scalars['ID']
}

export type MutationCreateManometerArgs = {
  input: ManometerCreateInput
}

export type MutationUpdateManometerArgs = {
  input: ManometerUpdateInput
}

export type MutationDeleteManometerArgs = {
  id: Scalars['ID']
}

export type MutationCreateTensiometerArgs = {
  input: TensiometerCreateInput
}

export type MutationUpdateTensiometerArgs = {
  input: TensiometerUpdateInput
}

export type MutationDeleteTensiometerArgs = {
  id: Scalars['ID']
}

export type MutationCreateWaterMeterArgs = {
  input: WaterMeterCreateInput
}

export type MutationUpdateWaterMeterArgs = {
  input: WaterMeterUpdateInput
}

export type MutationDeleteWaterMeterArgs = {
  id: Scalars['ID']
}

export type MutationCreateFilterArgs = {
  input: FilterCreateInput
}

export type MutationUpdateFilterArgs = {
  input: FilterUpdateInput
}

export type MutationDeleteFilterArgs = {
  id: Scalars['ID']
}

export type MutationCreateCapacitiveProbeArgs = {
  input: CapacitiveProbeCreateInput
}

export type MutationUpdateCapacitiveProbeArgs = {
  input: CapacitiveProbeUpdateInput
}

export type MutationDeleteCapacitiveProbeArgs = {
  id: Scalars['ID']
}

export type MutationUpdateGroupLocationArgs = {
  input: GroupLocationInput
}

export type MutationUpdateEquipmentLocationArgs = {
  input: EquipmentLocationInput
}

export type MutationCreateIrrigationTemplateArgs = {
  input: IrrigationTemplateCreateInput
}

export type MutationUpdateIrrigationTemplateArgs = {
  input: IrrigationTemplateUpdateInput
}

export type MutationStartIrrigationTemplateArgs = {
  input: IrrigationTemplateStartInput
}

export type MutationStopIrrigationTemplateArgs = {
  input: IrrigationTemplateStopInput
}

export type MutationDeleteIrrigationTemplateArgs = {
  id: Scalars['ID']
}

export type MutationCreateIrrigationCycleV2Args = {
  input: IrrigationCycleCreateInput
}

export type MutationUpdateIrrigationCycleArgs = {
  input: IrrigationCycleUpdateInput
}

export type MutationDeleteIrrigationCycleArgs = {
  id: Scalars['ID']
}

export type MutationCreateDeviceArgs = {
  input: DeviceCreateInput
}

export type MutationCreateDevicesArgs = {
  input: Array<DeviceCreateInput>
}

export type MutationUpdateDeviceArgs = {
  input: DeviceUpdateInput
}

export type MutationConfigureDeviceArgs = {
  input: DeviceConfigureInput
}

export type MutationRegisterDeviceArgs = {
  input: DeviceRegisterInput
}

export type MutationUninstallDeviceArgs = {
  input: DeviceUninstallInput
}

export type MutationTestDeviceRegistrationArgs = {
  input: DeviceRegisterInput
}

export type MutationCreateOrUpdateUserExpoPushTokenArgs = {
  input: CreateOrUpdateUserExpoPushTokenInput
}

export type MutationCreateLabelArgs = {
  input: LabelCreateInput
}

export type MutationUpdateLabelArgs = {
  input: LabelUpdateInput
}

export type MutationCreateGatewayArgs = {
  input: GatewayCreateInput
}

export type MutationUpdateGatewayArgs = {
  input: GatewayUpdateInput
}

export type UserRegisterInput = {
  firstName: Scalars['String']
  lastName: Scalars['String']
  email: Scalars['String']
  password: Scalars['String']
  company?: Maybe<UserRegisterCompanyInput>
}

export type UserRegisterCompanyInput = {
  name: Scalars['String']
}

export type UserSignInInput = {
  grantType: GrantType
  email?: Maybe<Scalars['String']>
  password?: Maybe<Scalars['String']>
  refreshToken?: Maybe<Scalars['String']>
}

export enum GrantType {
  Password = 'Password',
  RefreshToken = 'RefreshToken',
}

export type UserSignOutInput = {
  refreshToken: Scalars['String']
}

export type MeUpdateInput = {
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['String']>
  beNotifiedByEmail?: Maybe<Scalars['Boolean']>
  beNotifiedBySMS?: Maybe<Scalars['Boolean']>
  beNotifiedByPush?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  notificationSubscriptions?: Maybe<Array<Scalars['String']>>
}

export type MeEmailUpdateInput = {
  email: Scalars['String']
}

export type UserCreateInput = {
  firstName: Scalars['String']
  lastName: Scalars['String']
  email: Scalars['String']
  roles: Array<UserRole>
  companyId?: Maybe<Scalars['ID']>
}

export type UserUpdateInput = {
  id: Scalars['ID']
  firstName: Scalars['String']
  lastName: Scalars['String']
  email: Scalars['String']
  roles: Array<UserRole>
  companyId?: Maybe<Scalars['ID']>
}

export type UpdatePasswordInput = {
  oldPassword: Scalars['String']
  newPassword: Scalars['String']
}

export type ForgotPasswordInput = {
  email: Scalars['String']
}

export type ResetPasswordInput = {
  resetPasswordToken: Scalars['String']
  password: Scalars['String']
}

export type CompanyCreateInput = {
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  contactId?: Maybe<Scalars['ID']>
  address?: Maybe<AddressInput>
}

export type AddressInput = {
  street: Scalars['String']
  city: Scalars['String']
  zipCode: Scalars['String']
  countryAlpha3Code: Scalars['String']
}

export type CompanyUpdateInput = {
  id: Scalars['ID']
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  contactId?: Maybe<Scalars['ID']>
  address?: Maybe<AddressInput>
}

export type LandCreateInput = {
  name: Scalars['String']
  numberOfHectares: Scalars['Float']
  companyId: Scalars['ID']
  networkProvider?: Maybe<NetworkProvider>
  address: AddressInput
  timeZone: Scalars['String']
  hasGateway?: Maybe<Scalars['Boolean']>
  dailyCSVEmail?: Maybe<Scalars['String']>
}

export type LandUpdateInput = {
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  numberOfHectares?: Maybe<Scalars['Float']>
  networkProvider?: Maybe<NetworkProvider>
  address?: Maybe<AddressInput>
  timeZone?: Maybe<Scalars['String']>
  hasGateway?: Maybe<Scalars['Boolean']>
  dailyCSVEmail?: Maybe<Scalars['String']>
}

export type IrrigationSystemCreateInput = {
  name: Scalars['String']
  landId: Scalars['ID']
}

export type IrrigationSystemUpdateInput = {
  id: Scalars['ID']
  name: Scalars['String']
  landId: Scalars['ID']
}

export type IrrigationCycleCreateInput = {
  sectorId: Scalars['ID']
  startAt: Scalars['DateTime']
  startHour: Scalars['String']
  numberOfHours: Scalars['Int']
  numberOfMinutes: Scalars['Int']
  recurrence?: Maybe<IrrigationRecurrenceInput>
  assignedId?: Maybe<Scalars['ID']>
  isIrrigationTemplateOrigin?: Maybe<Scalars['Boolean']>
  irrigationTemplateId?: Maybe<Scalars['ID']>
}

export type IrrigationRecurrenceInput = {
  type: IrrigationReccurenceType
  endAt: Scalars['DateTime']
  frequency: Scalars['Int']
  days: Array<IrrigationReccurenceDay>
}

export type IrrigationEventUpdateInput = {
  id: Scalars['ID']
  sectorId?: Maybe<Scalars['ID']>
  startDate?: Maybe<Scalars['DateTime']>
  endDate?: Maybe<Scalars['DateTime']>
  assignedId?: Maybe<Scalars['ID']>
}

export type IrrigationCycleSimulateInput = {
  sectorId: Scalars['ID']
  startAt: Scalars['DateTime']
  startHour: Scalars['String']
  numberOfHours: Scalars['Int']
  numberOfMinutes: Scalars['Int']
  recurrence?: Maybe<IrrigationRecurrenceInput>
  assignedId?: Maybe<Scalars['ID']>
  isIrrigationTemplateOrigin?: Maybe<Scalars['Boolean']>
  irrigationTemplateId?: Maybe<Scalars['ID']>
  id?: Maybe<Scalars['ID']>
}

export type WaterSupplyCreateInput = {
  name: Scalars['String']
  irrigationSystemId: Scalars['ID']
}

export type WaterSupplyUpdateInput = {
  id: Scalars['ID']
  name: Scalars['String']
}

export type SectorCreateInput = {
  name: Scalars['String']
  irrigationSystemId: Scalars['ID']
  color?: Maybe<Scalars['String']>
  numberOfHectares?: Maybe<Scalars['Float']>
  cultureType?: Maybe<Scalars['String']>
  waterMeterId?: Maybe<Scalars['ID']>
}

export type SectorUpdateInput = {
  id: Scalars['ID']
  name: Scalars['String']
  color: Scalars['String']
  numberOfHectares?: Maybe<Scalars['Float']>
  cultureType?: Maybe<Scalars['String']>
  waterMeterId?: Maybe<Scalars['ID']>
}

export type SectorUpdateBoundaryInput = {
  id: Scalars['ID']
  boundary?: Maybe<Scalars['JSONObject']>
}

export type SectorUpdateHumidityBoundsInput = {
  id: Scalars['ID']
  humidityBounds: Array<Scalars['Int']>
}

export type SecondaryNetworkCreateInput = {
  name: Scalars['String']
  irrigationSystemId: Scalars['ID']
}

export type SecondaryNetworkUpdateInput = {
  id: Scalars['ID']
  name: Scalars['String']
}

export type PumpCreateInput = {
  name: Scalars['String']
  waterSupplyId: Scalars['ID']
  physicalPump?: Maybe<PhysicalPumpInput>
}

export type PhysicalPumpInput = {
  brand: Scalars['String']
  model: Scalars['String']
  energyType: Scalars['String']
  unit: Scalars['String']
  maxFlow: Scalars['Float']
  frequencyVariation?: Maybe<Scalars['Boolean']>
  connected?: Maybe<Scalars['Boolean']>
}

export type PumpUpdateInput = {
  id: Scalars['ID']
  name: Scalars['String']
  physicalPump?: Maybe<PhysicalPumpInput>
}

export type WaterInletCreateInput = {
  name: Scalars['String']
  waterSupplyId: Scalars['ID']
  physicalWaterInlet?: Maybe<PhysicalWaterInletInput>
}

export type PhysicalWaterInletInput = {
  brand: Scalars['String']
  model: Scalars['String']
  waterSupplier: Scalars['String']
  connected?: Maybe<Scalars['Boolean']>
}

export type WaterInletUpdateInput = {
  id: Scalars['ID']
  name: Scalars['String']
  physicalWaterInlet?: Maybe<PhysicalWaterInletInput>
}

export type ValveCreateInput = {
  name: Scalars['String']
  sectorId: Scalars['ID']
  irrigationSystemId: Scalars['ID']
  physicalValve?: Maybe<PhysicalValveInput>
}

export type PhysicalValveInput = {
  brand: Scalars['String']
  model: Scalars['String']
  voltage: Scalars['Float']
  solenoid?: Maybe<Scalars['Boolean']>
  connected?: Maybe<Scalars['Boolean']>
}

export type ValveUpdateInput = {
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  sectorId?: Maybe<Scalars['ID']>
  physicalValve?: Maybe<PhysicalValveInput>
}

export type StitchingPointCreateInput = {
  name: Scalars['String']
  secondaryNetworkId: Scalars['ID']
}

export type StitchingPointUpdateInput = {
  id: Scalars['ID']
  name: Scalars['String']
}

export type ManometerCreateInput = {
  name: Scalars['String']
  unit: ManometerUnitMeasure
  thresholdMin: Scalars['Float']
  thresholdMax: Scalars['Float']
  thresholdBased: Scalars['Float']
  group: GroupInput
  physicalManometer?: Maybe<PhysicalManometerInput>
}

export type GroupInput = {
  id: Scalars['ID']
  type: GroupType
}

export enum GroupType {
  Pump = 'PUMP',
  WaterInlet = 'WATER_INLET',
  Valve = 'VALVE',
  StitchingPoint = 'STITCHING_POINT',
}

export type PhysicalManometerInput = {
  brand: Scalars['String']
  model: Scalars['String']
}

export type ManometerUpdateInput = {
  id: Scalars['ID']
  name: Scalars['String']
  thresholdMin: Scalars['Float']
  thresholdMax: Scalars['Float']
  thresholdBased: Scalars['Float']
  physicalManometer?: Maybe<PhysicalManometerInput>
}

export type TensiometerCreateInput = {
  name: Scalars['String']
  unit: TensiometerUnitMeasure
  thresholdMin: Scalars['Float']
  thresholdMax: Scalars['Float']
  thresholdBased?: Maybe<Scalars['Float']>
  group: GroupInput
  physicalTensiometer?: Maybe<PhysicalTensiometerInput>
}

export type PhysicalTensiometerInput = {
  brand: Scalars['String']
  model: Scalars['String']
  depthUnit: Scalars['String']
  depth: Scalars['Float']
}

export type TensiometerUpdateInput = {
  id: Scalars['ID']
  name: Scalars['String']
  thresholdMin: Scalars['Float']
  thresholdMax: Scalars['Float']
  thresholdBased?: Maybe<Scalars['Float']>
  physicalTensiometer?: Maybe<PhysicalTensiometerInput>
}

export type WaterMeterCreateInput = {
  name: Scalars['String']
  unit: Scalars['String']
  group: GroupInput
  physicalWaterMeter?: Maybe<PhysicalWaterMeterInput>
}

export type PhysicalWaterMeterInput = {
  brand: Scalars['String']
  model: Scalars['String']
  pulsePerLiter: Scalars['Int']
  defaultTotalLiter?: Maybe<Scalars['Float']>
  pulseOutput?: Maybe<Scalars['Boolean']>
  connected?: Maybe<Scalars['Boolean']>
}

export type WaterMeterUpdateInput = {
  id: Scalars['ID']
  name: Scalars['String']
  physicalWaterMeter?: Maybe<PhysicalWaterMeterInput>
}

export type FilterCreateInput = {
  name: Scalars['String']
  unit: FilterUnitMeasure
  thresholdPressureDrop: Scalars['Float']
  group: GroupInput
  physicalFilter?: Maybe<PhysicalFilterInput>
}

export type PhysicalFilterInput = {
  brand: Scalars['String']
  model: Scalars['String']
  retroAutomaticWashing?: Maybe<Scalars['Boolean']>
}

export type FilterUpdateInput = {
  id: Scalars['ID']
  name: Scalars['String']
  thresholdPressureDrop: Scalars['Float']
  physicalFilter?: Maybe<PhysicalFilterInput>
}

export type CapacitiveProbeCreateInput = {
  name: Scalars['String']
  rZone: Scalars['Float']
  humidityUnit: Scalars['String']
  group: GroupInput
  physicalCapacitiveProbe: PhysicalCapacitiveProbeInput
  thresholds?: Maybe<Scalars['JSON']>
}

export type PhysicalCapacitiveProbeInput = {
  brand: Scalars['String']
  model: Scalars['String']
  depths?: Maybe<Array<Scalars['Int']>>
}

export type CapacitiveProbeUpdateInput = {
  id: Scalars['ID']
  name: Scalars['String']
  rZone: Scalars['Float']
  thresholds?: Maybe<Scalars['JSON']>
}

export type GroupLocationInput = {
  group: GroupInput
  location?: Maybe<LocationInput>
}

export type LocationInput = {
  lon: Scalars['Float']
  lat: Scalars['Float']
}

export type EquipmentLocationInput = {
  equipment: EquipmentInput
  location?: Maybe<LocationInput>
}

export type EquipmentInput = {
  id: Scalars['ID']
  type: EquipmentType
}

export enum EquipmentType {
  Manometer = 'MANOMETER',
  Tensiometer = 'TENSIOMETER',
  CapacitiveProbe = 'CAPACITIVE_PROBE',
  WaterMeter = 'WATER_METER',
  Filter = 'FILTER',
}

export type IrrigationTemplateCreateInput = {
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  companyId: Scalars['ID']
  folderId: Scalars['ID']
  irrigationCycles?: Maybe<Array<IrrigationCycleCreateInput>>
}

export type IrrigationTemplateUpdateInput = {
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  folderId?: Maybe<Scalars['ID']>
}

export type IrrigationTemplateStartInput = {
  irrigationTemplateId: Scalars['ID']
  startDate: Scalars['DateTime']
  endDate: Scalars['DateTime']
}

export type IrrigationTemplateStopInput = {
  irrigationTemplateId: Scalars['ID']
  /** End date of the started irrigation template. By default, now(). */
  endFrom?: Maybe<Scalars['DateTime']>
}

export type IrrigationCycleUpdateInput = {
  id: Scalars['ID']
  sectorId?: Maybe<Scalars['ID']>
  startAt?: Maybe<Scalars['DateTime']>
  startHour?: Maybe<Scalars['String']>
  numberOfHours?: Maybe<Scalars['Int']>
  numberOfMinutes?: Maybe<Scalars['Int']>
  recurrence?: Maybe<IrrigationRecurrenceInput>
  assignedId?: Maybe<Scalars['ID']>
}

export type DeviceCreateInput = {
  name: Scalars['String']
  devEui: Scalars['String']
  serialNumber?: Maybe<Scalars['String']>
  appEui: Scalars['String']
  type: DeviceType
  hardwareVersion: Scalars['String']
  firmwareVersion?: Maybe<Scalars['String']>
  properties?: Maybe<Scalars['JSONObject']>
  companyId?: Maybe<Scalars['String']>
  hasProtectingCase?: Maybe<Scalars['Boolean']>
  isHibernating?: Maybe<Scalars['Boolean']>
  saleDistributionChannel?: Maybe<Scalars['String']>
  ttnId?: Maybe<Scalars['String']>
}

export type DeviceUpdateInput = {
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['ID']>
  hasProtectingCase?: Maybe<Scalars['Boolean']>
  isHibernating?: Maybe<Scalars['Boolean']>
  saleDistributionChannel?: Maybe<Scalars['String']>
  properties?: Maybe<Scalars['JSON']>
  barTypeProperties?: Maybe<Scalars['JSON']>
  ttnId?: Maybe<Scalars['String']>
}

export type DeviceConfigureInput = {
  devEui: Scalars['ID']
  inputs: Array<Input>
}

export type Input = {
  name: Scalars['String']
  type: DeviceInputType
  group?: Maybe<Group>
  equipment?: Maybe<Equipment>
}

export type Group = {
  pump?: Maybe<PumpInputConfigInput>
  waterInlet?: Maybe<WaterInletInputConfigInput>
  valve?: Maybe<ValveInputConfigInput>
}

export type PumpInputConfigInput = {
  id: Scalars['ID']
}

export type WaterInletInputConfigInput = {
  id: Scalars['ID']
}

export type ValveInputConfigInput = {
  id: Scalars['ID']
}

export type Equipment = {
  manometer?: Maybe<ManometerInputConfigInput>
  tensiometer?: Maybe<TensiometerInputConfigInput>
  filter?: Maybe<FilterInputConfigInput>
  waterMeter?: Maybe<WaterMeterInputConfigInput>
  capacitiveProbe?: Maybe<CapacitiveProbeInputConfigInput>
}

export type ManometerInputConfigInput = {
  id: Scalars['ID']
  configuration: ManometerConfiguration
}

export type ManometerConfiguration = {
  bar?: Maybe<Scalars['Float']>
  maxPressure?: Maybe<Scalars['Float']>
  unit?: Maybe<Scalars['String']>
}

export type TensiometerInputConfigInput = {
  id: Scalars['ID']
  configuration: TensiometerConfiguration
}

export type TensiometerConfiguration = {
  bar?: Maybe<Scalars['Float']>
  maxPressure?: Maybe<Scalars['Float']>
  unit?: Maybe<Scalars['String']>
}

export type FilterInputConfigInput = {
  id: Scalars['ID']
  configuration: FilterConfiguration
}

export type FilterConfiguration = {
  bar?: Maybe<Scalars['Float']>
  maxPressure?: Maybe<Scalars['Float']>
  unit?: Maybe<Scalars['String']>
  filterEntry: FilterEntry
}

export enum FilterEntry {
  In = 'IN',
  Out = 'OUT',
}

export type WaterMeterInputConfigInput = {
  id: Scalars['ID']
  configuration: WaterMeterConfiguration
}

export type WaterMeterConfiguration = {
  pulsePerLiter: Scalars['Float']
  defaultTotalLiter?: Maybe<Scalars['Float']>
}

export type CapacitiveProbeInputConfigInput = {
  id: Scalars['ID']
}

export type DeviceRegisterInput = {
  companyId: Scalars['ID']
  deviceIdentifier: DeviceIdentifierInput
}

export type DeviceIdentifierInput = {
  id: Scalars['String']
  name: DeviceIdentifierName
  fromQRCodeScan?: Maybe<Scalars['Boolean']>
}

export enum DeviceIdentifierName {
  DevEui = 'DEV_EUI',
  SerialNumber = 'SERIAL_NUMBER',
}

export type DeviceUninstallInput = {
  devEui: Scalars['String']
  unregister?: Maybe<Scalars['Boolean']>
}

export type CreateOrUpdateUserExpoPushTokenInput = {
  deviceName: Scalars['String']
  token: Scalars['String']
}

export type LabelCreateInput = {
  name: Scalars['String']
  companyId: Scalars['ID']
  type?: Maybe<LabelType>
  parentLabelId?: Maybe<Scalars['ID']>
}

export type LabelUpdateInput = {
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  type?: Maybe<LabelType>
  parentLabelId?: Maybe<Scalars['ID']>
}

export type GatewayCreateInput = {
  name: Scalars['String']
  companyId: Scalars['ID']
  eui: Scalars['String']
  ttnId: Scalars['String']
}

export type GatewayUpdateInput = {
  id: Scalars['ID']
  companyId?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  eui?: Maybe<Scalars['String']>
  ttnId?: Maybe<Scalars['String']>
}

export type Subscription = {
  __typename?: 'Subscription'
  metricsReceived: IrrigationSystem
  geoEvent: GeoEvent
}

export type SubscriptionMetricsReceivedArgs = {
  id: Scalars['ID']
}
