import { useMutation, ApolloError } from '@apollo/client'

import { MUTATION_UPDATE_ME_PASSWORD } from '../apollo/mutations'

import { UpdatePasswordInput, MutationUpdatePasswordArgs } from '../types'
import { dispatchToastDemoRestriction } from '../utils/toasts'
import { useUserState } from './useUserState.hook'

interface Data {
  updateMePassword: boolean
}

export const useUpdateMePassword = (): {
  updateMePassword: (input: UpdatePasswordInput) => Promise<any>
  loading: boolean
  error: ApolloError | undefined
  data?: Data | null
} => {
  const { user } = useUserState()

  const [updateMePassword, { loading, error, data }] = useMutation<Data, MutationUpdatePasswordArgs>(
    MUTATION_UPDATE_ME_PASSWORD,
    {
      notifyOnNetworkStatusChange: true,
    },
  )

  const update = async (input: UpdatePasswordInput) => {
    if (!user?.company?.isDemo) {
      return await updateMePassword({ variables: { input } })
    } else {
      dispatchToastDemoRestriction()
    }
  }

  return { updateMePassword: update, loading, error, data }
}
