import { useQuery, ApolloError } from '@apollo/client'

import { LANDS } from '../apollo/queries'

import { Land, QueryLandsArgs } from '../types'

interface Data {
  lands?: Land[]
}

export const useLands = (
  companyId?: string,
  query?: string,
): {
  loading: boolean
  error: ApolloError | undefined
  refetch: (companyId?: string, query?: string) => void
  data?: Data
} => {
  const { loading, error, data, refetch } = useQuery<Data, QueryLandsArgs>(LANDS, {
    variables: {
      filters: {
        companyId,
        query_string: query,
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    skip: !companyId,
  })

  const refetchData = (companyId?: string, query?: string) => {
    const variables: QueryLandsArgs = {
      filters: {
        companyId,
        query_string: query,
      },
    }
    refetch(variables)
  }

  return { loading, error, data, refetch: refetchData }
}
