import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunk from 'redux-thunk'

import app from './app/reducers'
import user from './user/reducers'
import calendar from './calendar/reducers'
import search from './search/reducers'
import measurement from './measurement/reducers'

const defaultReducer = {
  app,
  user,
  calendar,
  search,
  measurement,
}

const rootReducer = combineReducers(defaultReducer)

const REDUX_DEVTOOLS = typeof window !== 'undefined' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__

const composeEnhancers = REDUX_DEVTOOLS || compose

const configureStore = () => createStore(rootReducer, {}, composeEnhancers(applyMiddleware(thunk)))

export type AppState = ReturnType<typeof rootReducer>

export default configureStore()
