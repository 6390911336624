import { ThunkAction } from 'redux-thunk'

import { Actions, AppState } from '../'
import {
  LoginLoadedErrorAction,
  USER_LOGIN_LOADED_ERROR,
  USER_LOGIN_LOADING,
  SignUpLoadedErrorAction,
  USER_SIGNUP_LOADED_ERROR,
  USER_SIGNUP_LOADING,
  USER_SIGN_OUT,
  DetailLoadedSuccessAction,
  DetailLoadedErrorAction,
  USER_DETAIL_LOADED_SUCCESS,
  USER_DETAIL_LOADED_ERROR,
  DetailUpdatedSuccessAction,
  DetailUpdatedErrorAction,
  USER_DETAIL_UPDATED_SUCCESS,
  USER_DETAIL_UPDATED_ERROR,
  UserActionTypes,
  USER_DETAIL_UPDATING,
} from './actionTypes'

import { User, Locale } from '../../types'
import { URL_POST_LOGIN, URL_GET_ME, URL_UPDATE_ME, URL_POST_SIGNUP } from './urls'
import { rest, restWithToken, RestParams } from '../../utils/rest'

import { AuthCookieService } from '../../utils/auth'

import {
  removeCompanyFromLocalStorage,
  removeLandFromLocalStorage,
  removeIrrigationSystemFromLocalStorage,
} from '../app/helpers'
import { AppActionTypes } from '../app/actionTypes'

type Thunk<R> = ThunkAction<R, AppState, null, UserActionTypes | AppActionTypes>

const loginError = (): LoginLoadedErrorAction => ({
  type: USER_LOGIN_LOADED_ERROR,
})

const signUpError = (): SignUpLoadedErrorAction => ({
  type: USER_SIGNUP_LOADED_ERROR,
})

const detailSuccess = (user: User): DetailLoadedSuccessAction => ({
  type: USER_DETAIL_LOADED_SUCCESS,
  user,
})

const detailError = (): DetailLoadedErrorAction => ({
  type: USER_DETAIL_LOADED_ERROR,
})

export const getMe = (): Thunk<void> => async (dispatch) => {
  try {
    const params: RestParams = {
      url: URL_GET_ME,
    }

    const res = await restWithToken(params)
    if (res.status === 200) {
      const json = await res.json()
      if (json.company) {
        dispatch(Actions.app.setCompany(json.company))
      }
      dispatch(detailSuccess(json))
      return
    }
  } catch (error) {
    console.error(error)
  }

  dispatch(detailError())
}

const updateDetailSuccess = (user: User): DetailUpdatedSuccessAction => ({
  type: USER_DETAIL_UPDATED_SUCCESS,
  user,
})

const updateDetailError = (): DetailUpdatedErrorAction => ({
  type: USER_DETAIL_UPDATED_ERROR,
})

interface UpdateMeInterface {
  firstName: string
  lastName: string
  phoneNumber: string
  beNotifiedByEmail: boolean
  beNotifiedBySMS: boolean
  beNotifiedByPush: boolean
  locale: Locale
  notificationSubscriptions: string[]
}
export const updateMe = (input: UpdateMeInterface): Thunk<void> => async (dispatch, state) => {
  dispatch({
    type: USER_DETAIL_UPDATING,
  })

  try {
    const body = JSON.stringify({
      ...input,
    })

    const params: RestParams = {
      url: URL_UPDATE_ME,
      method: 'POST',
      body,
    }

    const res = await restWithToken(params)
    if (res.status === 204) {
      const user = state().user.user
      if (user) {
        const newUser: User = {
          ...user,
          firstName: input.firstName,
          lastName: input.lastName,
          phoneNumber: input.phoneNumber,
          settings: {
            ...user.settings,
            id: user.settings?.id || '1',
            beNotifiedByEmail: input.beNotifiedByEmail,
            beNotifiedBySMS: input.beNotifiedBySMS,
            beNotifiedByPush: input.beNotifiedByPush,
            notificationSubscriptions: input.notificationSubscriptions,
            locale: input.locale,
          },
        }

        dispatch(updateDetailSuccess(newUser))
        return
      }
    }
  } catch (error) {
    console.error(error)
  }

  dispatch(updateDetailError())
}

export const login = (username: string, password: string): Thunk<void> => async (dispatch) => {
  dispatch({
    type: USER_LOGIN_LOADING,
  })

  try {
    const body = JSON.stringify({
      grantType: 'password',
      email: username,
      password,
    })

    const params: RestParams = {
      url: URL_POST_LOGIN,
      method: 'POST',
      body,
    }

    const res = await rest(params)
    if (res.status === 200) {
      const { accessToken } = await res.json()
      AuthCookieService.storeAuthInfo(accessToken, true)
      dispatch(getMe())
      return
    }
  } catch (error) {
    console.error(error)
  }

  dispatch(loginError())
}

export const signUp = (
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  companyName: string,
): Thunk<void> => async (dispatch) => {
  dispatch({
    type: USER_SIGNUP_LOADING,
  })

  try {
    const body = JSON.stringify({
      firstName,
      lastName,
      email,
      password,
      company: { name: companyName },
    })

    const params: RestParams = {
      url: URL_POST_SIGNUP,
      method: 'POST',
      body,
    }

    const res = await rest(params)
    if (res.status === 200) {
      const { accessToken } = await res.json()
      AuthCookieService.storeAuthInfo(accessToken, true)
      dispatch(getMe())
      return
    }
  } catch (error) {
    console.error(error)
  }

  dispatch(signUpError())
}

export const loginWithSession = (): Thunk<void> => async (dispatch) => {
  dispatch({
    type: USER_LOGIN_LOADING,
  })

  const authInfos = AuthCookieService.getAuthInfo()
  if (authInfos && authInfos.token) {
    dispatch(getMe())
  } else {
    dispatch(detailError())
  }
}

export const signOut = (): Thunk<void> => async (dispatch) => {
  AuthCookieService.clearAuthInfo()
  removeCompanyFromLocalStorage()
  removeLandFromLocalStorage()
  removeIrrigationSystemFromLocalStorage()

  dispatch({
    type: USER_SIGN_OUT,
  })
}
