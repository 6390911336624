import React from 'react'
import { barToPsi, celsiusToFahrenheit, psiToBar, roundNumber } from '../conversion'

const MEASURE_SYSTEM_KEY = 'MEASURE_SYSTEM_IMPERIAL'

const getStoredValue = (): boolean => {
  try {
    // Try to speed things up by reading directly from window object
    const windowValue = (window as any)[MEASURE_SYSTEM_KEY]
    if (windowValue !== undefined) {
      return windowValue as boolean
    }

    // If no avail, try to read from localStorage and ultimately falls back to default value
    const item = window.localStorage.getItem(MEASURE_SYSTEM_KEY)
    return item ? (JSON.parse(item) as boolean) : false
  } catch (error) {
    console.error('Error reading from window or local storage:', error)
    return false
  }
}

const setStoredValue = (value: boolean): void => {
  try {
    ;(window as any)[MEASURE_SYSTEM_KEY] = value
    window.localStorage.setItem(MEASURE_SYSTEM_KEY, JSON.stringify(value))
  } catch (error) {
    console.error('Error writing to window or local storage:', error)
  }
}

type State = {
  isImperial: boolean
  toggleImperial: (value: boolean) => void
  pressureUnit: 'BAR' | 'PSI'
  temperatureUnit: '°C' | '°F'
  formatPressure: (value?: number) => string
  formatTemperature: (value?: number) => string
  normalizePressure: (value: number) => number
}

const useMeasureSystemPreference = (): State => {
  const initialValue = React.useMemo(() => getStoredValue(), [])
  const [isImperial, toggleImperialState] = React.useState<boolean>(initialValue)

  const toggleImperial = React.useCallback((value: boolean) => {
    setStoredValue(value)
    toggleImperialState(value)
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const formatPressure = React.useCallback(
    isImperial ? (value?: number) => roundNumber(barToPsi(value || 0)) : (value?: number) => roundNumber(value || 0),
    [isImperial],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const formatTemperature = React.useCallback(
    isImperial
      ? (value?: number) => (value ? roundNumber(celsiusToFahrenheit(value)) : '-')
      : (value?: number) => (value ? roundNumber(value) : '-'),
    [isImperial],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const normalizePressure = React.useCallback((value: number) => (isImperial ? psiToBar(value) : value), [isImperial])

  return {
    isImperial,
    toggleImperial,
    pressureUnit: isImperial ? 'PSI' : 'BAR',
    temperatureUnit: isImperial ? '°F' : '°C',
    formatPressure,
    formatTemperature,
    normalizePressure,
  }
}

export default useMeasureSystemPreference
