import { Company, Land, IrrigationSystem } from '../../types'
import { SignOutAction } from '../user/actionTypes'

export const APP_TOGGLE_MENU = '@@app/TOGGLE_MENU'

export interface ToggleMenu {
  type: typeof APP_TOGGLE_MENU
}

export const APP_TOGGLE_USER_PROFILE = '@@app/TOGGLE_USER_PROFILE'

export interface ToggleUserProfile {
  type: typeof APP_TOGGLE_USER_PROFILE
}

export const APP_SET_COMPANY = '@@app/SET_COMPANY'

export interface SetCompany {
  type: typeof APP_SET_COMPANY
  company: Company
}

export const APP_SET_LAND = '@@app/SET_LAND'

export interface SetLand {
  type: typeof APP_SET_LAND
  land: Land
}

export const APP_SET_IRRIGATION_SYSTEM = '@@app/SET_IRRIGATION_SYSTEM'

export interface SetIrrigationSystem {
  type: typeof APP_SET_IRRIGATION_SYSTEM
  irrigationSystem: IrrigationSystem
}

export const APP_SET_LAND_AND_IRRIGATION_SYSTEM = '@@app/SET_LAND_AND_IRRIGATION_SYSTEM'

export interface SetLandAndIrrigationSystem {
  type: typeof APP_SET_LAND_AND_IRRIGATION_SYSTEM
  land: Land
  irrigationSystem: IrrigationSystem
}

export type AppActionTypes =
  | SignOutAction
  | ToggleMenu
  | ToggleUserProfile
  | SetCompany
  | SetLand
  | SetIrrigationSystem
  | SetLandAndIrrigationSystem
