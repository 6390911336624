import { ApolloError, useLazyQuery, QueryLazyOptions } from '@apollo/client'

import { IRRIGATION_SYSTEMS } from '../apollo/queries'

import { IrrigationSystem, QueryIrrigationSystemsArgs } from '../types'

interface Data {
  irrigationSystems?: IrrigationSystem[]
}

export const useIrrigationSystemsLazy = (
  companyId?: string,
  landId?: string,
  query?: string,
): {
  getIrrigationSystems: (options?: QueryLazyOptions<QueryIrrigationSystemsArgs> | undefined) => void
  loading: boolean
  error: ApolloError | undefined
  data?: Data
} => {
  const [getIrrigationSystems, { loading, error, data }] = useLazyQuery<Data, QueryIrrigationSystemsArgs>(
    IRRIGATION_SYSTEMS,
    {
      variables: {
        filters: {
          companyId,
          landId,
          query_string: query,
        },
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    },
  )

  return { getIrrigationSystems, loading, error, data }
}
