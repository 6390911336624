import { dispatchToast, ToastArgs } from '../../components/Toast'

export const dispatchToastDemoRestriction = (): void => {
  const detail: ToastArgs = {
    message: 'modules_Installation_Toast_demo',
    fixed: false,
    type: 'success',
    identifier: `${Date.now()}`,
  }
  return dispatchToast(detail)
}
