import moment, { Moment } from 'moment'

import { toUserLocaleDate } from '../../utils/date'

export interface TimeRange {
  id: string
  label: string
  startDateUTC: Moment
  endDateUTC: Moment
}

export const mockTimeRangeItems = (): TimeRange[] => [
  {
    id: '1',
    label: 'modules_Measurement_time-range_last_3_days',
    startDateUTC: moment().add(-3, 'day').startOf('day'),
    endDateUTC: moment(),
  },
  {
    id: '2',
    label: 'modules_Measurement_time-range_last_7_days',
    startDateUTC: moment().add(-7, 'day').startOf('day'),
    endDateUTC: moment(),
  },
  {
    id: '3',
    label: 'modules_Measurement_time-range_last_30_days',
    startDateUTC: moment().add(-30, 'day').startOf('day'),
    endDateUTC: moment(),
  },
  {
    id: '4',
    label: 'modules_Measurement_time-range_last_60_days',
    startDateUTC: moment().add(-60, 'day').startOf('day'),
    endDateUTC: moment(),
  },
  {
    id: '5',
    label: 'modules_Measurement_time-range_last_90_days',
    startDateUTC: moment().add(-90, 'day').startOf('day'),
    endDateUTC: moment(),
  },
  {
    id: '6',
    label: 'modules_Measurement_time-range_last_6_months',
    startDateUTC: moment().add(-6, 'month').startOf('month'),
    endDateUTC: moment(),
  },
  {
    id: '7',
    label: 'modules_Measurement_time-range_today',
    startDateUTC: moment().startOf('day'),
    endDateUTC: moment(),
  },
  {
    id: '8',
    label: 'modules_Measurement_time-range_yesterday',
    startDateUTC: moment().add(-1, 'day').startOf('day'),
    endDateUTC: moment().add(-1, 'day').endOf('day'),
  },
  {
    id: '9',
    label: 'modules_Measurement_time-range_before-yesterday',
    startDateUTC: moment().add(-2, 'day').startOf('day'),
    endDateUTC: moment().add(-2, 'day').endOf('day'),
  },
  {
    id: '10',
    label: 'modules_Measurement_time-range_last_week',
    startDateUTC: moment().add(-1, 'week').startOf('week'),
    endDateUTC: moment().add(-1, 'week').endOf('week'),
  },
  {
    id: '11',
    label: 'modules_Measurement_time-range_last_month',
    startDateUTC: moment().add(-1, 'month').startOf('month'),
    endDateUTC: moment().add(-1, 'month').endOf('month'),
  },
  {
    id: '12',
    label: 'modules_Measurement_time-range_last_year',
    startDateUTC: moment().add(-1, 'year').startOf('year'),
    endDateUTC: moment().add(-1, 'year').endOf('year'),
  },
]

interface Ticks {
  tickValues: moment.Moment[]
  tickFormatX: (x: Date) => string
  tickFormatY: ((y: string) => string) | undefined
}

export const getTicks = (timeRange: TimeRange, unit?: string, tickFormat?: (y: string) => void): Ticks => {
  const diffDays = toUserLocaleDate(timeRange.endDateUTC).diff(toUserLocaleDate(timeRange.startDateUTC), 'day')

  const tickValues = []
  let tickFormatX = (x: Date) => toUserLocaleDate(x).format('ll')

  if (diffDays <= 1) {
    const diff = diffDays
    for (let index = 0; index <= diff; index++) {
      tickValues.push(moment(timeRange.startDateUTC).add(index, 'day'))
    }
    tickFormatX = (x: Date) => toUserLocaleDate(x).format('ll \n HH:mm')
  } else if (diffDays <= 9) {
    const diff = diffDays
    for (let index = 0; index <= diff; index++) {
      tickValues.push(moment(timeRange.startDateUTC).add(index, 'day'))
    }
  } else if (diffDays <= 30) {
    const diff = diffDays / 5
    for (let index = 0; index <= diff; index++) {
      tickValues.push(moment(timeRange.startDateUTC).add(index * 5, 'day'))
    }
  } else if (diffDays <= 60) {
    const diff = diffDays / 10
    for (let index = 0; index <= diff; index++) {
      tickValues.push(moment(timeRange.startDateUTC).add(index * 10, 'day'))
    }
  } else if (diffDays <= 90) {
    const diff = diffDays / 15
    for (let index = 0; index <= diff; index++) {
      tickValues.push(moment(timeRange.startDateUTC).add(index * 15, 'day'))
    }
  } else if (diffDays <= 180) {
    const diff = diffDays / 30
    for (let index = 0; index <= diff; index++) {
      tickValues.push(moment(timeRange.startDateUTC).add(index * 30, 'day'))
    }
  } else {
    const diff = diffDays / 60
    for (let index = 0; index <= diff; index++) {
      tickValues.push(moment(timeRange.startDateUTC).add(index * 60, 'day'))
    }
  }

  const tickFormatY = unit ? (y: string) => `${!!tickFormat ? tickFormat(y) : y} ${unit}` : undefined

  return { tickValues, tickFormatX, tickFormatY }
}

export const uuidToColour = (id: string): string => {
  let hash = 0
  for (let i = 0; i < id.length; i++) {
    hash = id.charCodeAt(i) + ((hash << 5) - hash)
  }

  let colour = '#'
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff
    colour += ('00' + value.toString(16)).substr(-2)
  }

  return colour
}

export const downloadCsv = (data: any[][]): void => {
  let csv = ''

  data.forEach((row) => {
    csv += row.join(',') + '\n'
  })

  const csvContent = csv
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' })
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', 'export_irrigeasy.csv')
  link.click()
}
