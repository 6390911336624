import { connect, ConnectedProps } from 'react-redux'

import { AppState, Actions } from '../../../redux'

const mapState = (state: AppState) => ({
  userState: state.user,
})

const mapDispatch = {
  signOut: Actions?.user.signOut,
  toggleMenu: Actions?.app.toggleMenu,
  toggleUserProfile: Actions?.app.toggleUserProfile,
}

export const withHeader = connect(mapState, mapDispatch)

export type WithHeaderProps = ConnectedProps<typeof withHeader>
