import Toast, { ToastProps } from './Toast.component'

export type ToastArgs = ToastProps

export const dispatchToast = (detail: ToastProps): void => {
  const event = new CustomEvent('toast', { detail })
  document.dispatchEvent(event)
}

export default Toast
