import { gql } from '@apollo/client'
import { GroupStitchingPointFragment } from '../fragments'

export const MUTATION_CREATE_STITCHING_POINT = gql`
  mutation createStitchingPoint($input: StitchingPointCreateInput!) {
    createStitchingPoint(input: $input) {
      ...GroupStitchingPointFragment
    }
  }
  ${GroupStitchingPointFragment}
`

export const MUTATION_UPDATE_STITCHING_POINT = gql`
  mutation updateStitchingPoint($input: StitchingPointUpdateInput!) {
    updateStitchingPoint(input: $input) {
      ...GroupStitchingPointFragment
    }
  }
  ${GroupStitchingPointFragment}
`

export const MUTATION_DELETE_STITCHING_POINT = gql`
  mutation deleteStitchingPoint($id: ID!) {
    deleteStitchingPoint(id: $id)
  }
`
