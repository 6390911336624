import { gql } from '@apollo/client'

export const EquipmentManometerAlertFragment = gql`
  fragment EquipmentManometerAlertFragment on ManometerAlert {
    id
    startAt
    valueAtStart
    averageAtStart
    endAt
    valueAtEnd
    averageAtEnd
    status
    type
  }
`
