import styled from 'styled-components'
import checkbox from '../../assets/checkbox.png'

export type Color = 'main' | 'secondary' | 'lightBlue' | 'neonBlue' | 'white' | 'black'

export const Container = styled.div<{ filled?: boolean }>`
  min-width: ${({ filled }) => (filled ? '100%' : '20px')};
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
  user-select: none;
`
export const LeftContainer = styled.div<{ filled?: boolean }>`
  min-width: 20px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  user-select: none;
  cursor: pointer;
`

export const Box = styled.div<{ checked?: boolean; boxColor: Color; image?: boolean }>`
  width: 18px;
  height: 18px;
  background-image: ${({ checked, image }) => checked && image && `url(${checkbox})`};
  background: ${({ theme, checked, boxColor, image }) => !image && (checked ? theme.colors[boxColor] : '#fbfbfb')};
  border: 1px solid ${({ checked }) => (checked ? '#000000' : '#d2d8cf')};
  border-radius: 6px;
  opacity: 1;
  margin-right: 10px;
`

export const Label = styled.div<{ textColor: Color }>`
  color: ${({ theme, textColor }) => theme.colors[textColor]};
  font-size: 16px;
  font-weight: 100;
`

export const LeftBox = styled.div<{ checked?: boolean; boxColor: Color }>`
  width: 18px;
  height: 18px;
  background-image: ${({ checked }) => checked && `url(${checkbox})`};
  border: 1px solid ${({ checked, theme, boxColor }) => (checked ? theme.colors[boxColor] : '#d2d8cf')};
  border-radius: 6px;
  opacity: 1;
  margin-left: 10px;
`
