import { gql } from '@apollo/client'

import { DeviceFragment } from '../fragments'

export const DEVICES = gql`
  query devices($filters: DeviceFindInput) {
    devices(filters: $filters) {
      ...DeviceFragment
    }
  }
  ${DeviceFragment}
`
