import React from 'react'
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom'
import loadable from '@loadable/component'

import Main from '../Main'

const AsyncSignIn = loadable(() => import('../../modules/SignIn'))
const AsyncSignUp = loadable(() => import('../../modules/SignUp'))
const AsyncForgotPassword = loadable(() => import('../../modules/ForgotPassword'))
const AsyncResetPassword = loadable(() => import('../../modules/ResetPassword'))
const AsyncCGU = loadable(() => import('../../modules/CGU'))

const Routing = (props: RouteComponentProps): JSX.Element => {
  const { match } = props

  return (
    <Switch>
      <Route path={`${match.path}/sign-in`} component={AsyncSignIn} />
      <Route path={`${match.path}/sign-up`} component={AsyncSignUp} />
      <Route path={`${match.path}/forgot-password`} component={AsyncForgotPassword} />
      <Route path={`${match.path}/reset-password`} component={AsyncResetPassword} />
      <Route path={`${match.path}/cgu`} component={AsyncCGU} />
      <Route path={match.path} component={Main} />
    </Switch>
  )
}

export default withRouter(Routing)
